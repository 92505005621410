


















// libs
import {Vue, Component, Prop } from 'vue-property-decorator';

// components
import Zoom from '@components/iconSVG/Zoom.vue';
import LazyloadImage from '@components/image/LazyloadImage.vue';

// images
import imageDefault from '@/assets/img/icon-no-image.svg';


@Component({
  components: {
    Zoom,
    LazyloadImage,
  },
})

export default class ImageZoomComponent extends Vue {
  @Prop(String) readonly path!: string;
  @Prop(String) readonly alt!: string;
  @Prop(String) readonly styles!: string;
  @Prop(String) readonly avatarDefault!: string;


  private get imageDefault() {
    return this.avatarDefault || imageDefault;
  }

  private zoomImage() {
    this.$modal.show('preview-image-zoom', { src: this.path });
  }
}


























































































































































































































import 'vue2-datepicker/index.css';
import DatePicker from 'vue2-datepicker';
import MedicineApi from '@/api/MedicineApi';
import BaseInput from '@/components/common/BaseInput.vue';
import BaseTextarea from '@/components/common/BaseTextarea.vue';
import FormAction from '@/components/common/FormAction.vue';
import { Medicine } from '@/models/medicine';
import Toast from '@common/helpers/toast';
import ApiHelper from 'api-helper';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { State } from 'vuex-class';
import moment from 'moment';
import {
  StockDailiesFormInterface,
  StockFormInterface,
} from './StockFormInterface';
import { PLAN_PRODUCT_UNITS } from '@/common/constants/plan';
import { Stock } from '@/models/stock';
import StockApi from '@/api/v2/StockApi';
import _ from 'lodash';

@Component({
  components: {
    FormAction,
    BaseInput,
    BaseTextarea,
    DatePicker,
  },
})
export default class StockForm extends Vue {
  @Prop(Number) stockId?: number;
  @Prop(Object) dataForm: StockFormInterface;
  @Prop(Boolean) disabledBtnCancel!: boolean;
  @Prop(Boolean) disabledBtnSubmit!: boolean;
  @Prop(String) selectedMonth!: string;

  @State((state) => state.auth.currentUser) currentUser!: any;

  medicineList: Medicine[] = [];

  stockDataForSelectedMonth?: Stock;

  isLoading: boolean = false;
  productUnits = PLAN_PRODUCT_UNITS;
  initMedicineOnHand = 0;

  medicineApi = ApiHelper.getApi('MedicineApi') as MedicineApi;
  stockApi = ApiHelper.getApi('StockApi') as StockApi;

  get daysInSelectedMonth(): number[] {
    const lastDayOnMonth = moment(this.selectedMonth).endOf('month').date();
    const daysArray: number[] = [];
    for (let i: number = 1; i <= lastDayOnMonth; i++) {
      daysArray.push(i);
    }

    return daysArray;
  }

  get currentMedicineName() {
    return (
      this.medicineList.find((med) => med.id == this.dataForm.medicine_id)
        ?.name || ''
    );
  }

  async created() {
    try {
      this.isLoading = true;

      this.medicineApi
        .fetchMedicinesNotInStock({
          exceptList: this.stockId ? [this.stockId] : [],
        })
        .then((data) => {
          this.medicineList = data.data;
        });
    } catch (error) {
      Toast.error(error?.response?.data?.message);
    } finally {
      this.isLoading = false;
    }
  }

  @Watch('dataForm')
  onDataFormChanged() {
    const firstStockDaily = this.dataForm.stock_dailies[0];
    this.initMedicineOnHand =
      (firstStockDaily.medicine_on_hand || 0) +
      (firstStockDaily.medicine_shiped || 0) -
      (firstStockDaily.medicine_received || 0);
  }

  getMedicineReceived(stock_dailies, day) {
    const stockDaily = stock_dailies?.find(
      (s) => s.date == this.formatDateByDay(day),
    );
    return stockDaily?.medicine_received || 0;
  }

  getMedicineShiped(stock_dailies, day) {
    const stockDaily = stock_dailies?.find(
      (s) => s.date == this.formatDateByDay(day),
    );
    return stockDaily?.medicine_shiped || 0;
  }

  getMedicineOnHand(stock_dailies, day) {
    const stockDaily = stock_dailies?.find(
      (s) => s.date == this.formatDateByDay(day),
    );
    return stockDaily?.medicine_on_hand || 0;
  }

  handleMedicineOnHand(day: number) {
    const lastDayOnMonth = moment(this.selectedMonth).endOf('month').date();
    for (day++; day <= lastDayOnMonth; day++) {
      const index = _.findIndex(this.dataForm.stock_dailies, {
        date: this.formatDateByDay(day),
      });

      const stockDailyBefore = _.find(this.dataForm.stock_dailies, {
        date: this.formatDateByDay(day - 1),
      });

      if (index !== -1) {
        this.dataForm.stock_dailies.splice(index, 1, {
          ...this.dataForm.stock_dailies[index],
          medicine_on_hand:
            (stockDailyBefore?.medicine_on_hand || 0) +
            (this.dataForm.stock_dailies[index].medicine_received || 0) -
            (this.dataForm.stock_dailies[index].medicine_shiped || 0),
        });
      } else {
        this.dataForm.stock_dailies.push({
          date: this.formatDateByDay(day),
          medicine_received: 0,
          medicine_shiped: 0,
          medicine_on_hand: stockDailyBefore?.medicine_on_hand || 0,
        });
      }
    }
  }

  handleMedicineReceived(index, value, day) {
    let stockDaily: StockDailiesFormInterface | undefined;

    let prevMedicineOnHand = 0;
    if (day == 1) {
      prevMedicineOnHand = this.initMedicineOnHand;
    }
    if (day > 1) {
      const prevDay = day - 1;
      stockDaily = _.find(this.dataForm.stock_dailies, {
        date: this.formatDateByDay(prevDay),
      });
      prevMedicineOnHand = stockDaily?.medicine_on_hand || 0;
    }

    if (index !== -1) {
      this.dataForm.stock_dailies.splice(index, 1, {
        ...this.dataForm.stock_dailies[index],
        medicine_received: parseInt(value, 10),
        medicine_on_hand:
          prevMedicineOnHand +
          parseInt(value, 10) -
          (this.dataForm.stock_dailies[index].medicine_shiped || 0),
      });
    } else {
      this.dataForm.stock_dailies.push({
        date: this.formatDateByDay(day),
        medicine_received: parseInt(value, 10),
        medicine_shiped: 0,
        medicine_on_hand: prevMedicineOnHand + parseInt(value, 10),
      });
    }
  }

  handleMedicineShiped(index, value, day) {
    let stockDaily: StockDailiesFormInterface | undefined;

    let prevMedicineOnHand = 0;
    if (day == 1) {
      prevMedicineOnHand = this.initMedicineOnHand;
    }

    if (day > 1) {
      const prevDay = day - 1;
      stockDaily = _.find(this.dataForm.stock_dailies, {
        date: this.formatDateByDay(prevDay),
      });
      prevMedicineOnHand = stockDaily?.medicine_on_hand || 0;
    }

    if (index !== -1) {
      this.dataForm.stock_dailies.splice(index, 1, {
        ...this.dataForm.stock_dailies[index],
        medicine_shiped: parseInt(value, 10),
        medicine_on_hand:
          prevMedicineOnHand +
          (this.dataForm.stock_dailies[index].medicine_received || 0) -
          parseInt(value, 10),
      });
    } else {
      this.dataForm.stock_dailies.push({
        date: this.formatDateByDay(day),
        medicine_received: 0,
        medicine_shiped: parseInt(value, 10),
        medicine_on_hand: prevMedicineOnHand - parseInt(value, 10),
      });
    }
  }

  handleInput(event, day, type) {
    const index = _.findIndex(this.dataForm.stock_dailies, {
      date: this.formatDateByDay(day),
    });
    const value = Number(event.target.value);
    switch (type) {
      case 'medicine_received':
        this.handleMedicineReceived(index, value, day);
        break;
      case 'medicine_shiped':
        this.handleMedicineShiped(index, value, day);
        break;
    }
    this.handleMedicineOnHand(day);
  }

  formatDateByDay(day) {
    day = day < 10 ? `0${day}` : day;
    return `${this.selectedMonth}-${day}`;
  }
}

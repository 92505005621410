



















































































































































































































import Listicon from '@/assets/img/install-lp-icon.svg';
import { config, Roles } from '@/common/app.config';
import { getFormData } from '@/common/utils';
import DragDropFileUploadLp from '@/components/common/DragDropFileUploadLp.vue';
import PopupConfirmPlan from '@/components/common/PopupConfirmPlan.vue';
import { PlanClinic } from '@/models/plan';
import { ClinicModule } from '@/store';
import { CType } from '@common/app.config';
import Toast from '@common/helpers/toast';
import ClinicsMixin from '@common/mixins/Clinic/ClinicsMixin';
import ContentLayout from '@components/layouts/Content.vue';
import ApiHelper from 'api-helper';
import { get } from 'lodash';
import { extend } from 'vee-validate';
import { Component, Mixins } from 'vue-property-decorator';
import { mapState } from 'vuex';

@Component({
  head: {
    title: {
      inner: 'LP設定',
    },
  },
  components: {
    PopupConfirmPlan,
    ContentLayout,
    DragDropFileUploadLp,
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  data() {
    return {
      clinicId: this.$route.params.clinicId,
      prefectures: [],
      cities: [],
      iconHeader: Listicon,
      appConfig: config,
    };
  },
})
export default class LpSetting extends Mixins(ClinicsMixin) {
  public get listTypeOption() {
    return [
      { title: 'メインカラー：グリーン', id: `main_color_green` },
      { title: 'デフォルト', id: `default` },
      { title: 'LPなし', id: `none` },
    ];
  }

  public get dataClinic() {
    return ClinicModule.dataClinic;
  }

  public get uuidClinic() {
    return get(this.dataClinic, 'uuid', '');
  }

  public get fetchingClinic() {
    return ClinicModule.fetchingClinic;
  }

  public iconHeader: any;
  public appConfig: any;
  public listPrefecture: any[] = [];
  public extend: any = extend;
  public isSubmiting = false;
  public isSubmitDelete: boolean = false;
  public currentUser: any;
  public clinicId!: any;
  public clinicUuid: string = '';
  public isSetPrefectureCity: boolean = false;
  public isChange: boolean = false;
  public CType = CType;
  public isOpenMenu: boolean = false;
  public clinics: any = {};
  public isLoading: boolean = true;
  public errorsImage: string = '';
  public errorsLogo: string = '';
  public checkDisabled: boolean = false;

  public selectedPlans: any[] = [];
  public planList: any[] = [];

  public dataForm = {
    logo_lp: '',
    image_lp: '',
    logo_lp_url: '',
    image_lp_url: '',
    template: '',
    name: '',
    name_kana: '',
    phone: '',
    postal_code: '',
    prefecture_id: '',
    city_id: '',
    address: '',
    building_name: '',
    type: '',
    gtm_id: '',
    title_lp: '',
    description_lp: '',
  };

  async mounted() {
    this.isLoading = true;
    try {
      this.clinics = await ClinicModule.getClinicDetail(this.clinicId);
      this.isLoading = true;
      this.planList = this.clinics.plans;
      this.selectedPlans = this.planList
        .filter((item) => item.display_on_lp)
        .sort((a, b) => a.display_priority - b.display_priority);
      this.dataForm = {
        ...this.dataForm,
        name: this.clinics.name,
        name_kana: this.clinics.name_kana,
        phone: this.clinics.phone,
        postal_code: this.clinics.postal_code,
        prefecture_id: this.clinics.prefecture_id,
        city_id: this.clinics.city_id,
        address: this.clinics.address,
        building_name: this.clinics.building_name,
        type: this.clinics.type,
        gtm_id: this.clinics.gtm_id,
        logo_lp_url: this.clinics.logo_lp_url,
        image_lp_url: this.clinics.image_lp_url,
        template: this.clinics.template,
        title_lp: this.clinics.title_lp,
        description_lp: this.clinics.description_lp,
      };
      if (['none', 'default'].includes(this.clinics.template)) {
        this.checkDisabled = true;
      }
    } catch (error) {
      this.toast(error);
    } finally {
      this.isLoading = false;
    }
  }

  handleChangeTemplate(value) {
    this.selectedPlans = value === 'none' ? [] : this.selectedPlans;
    if (value === 'none' || value === 'default') {
      this.checkDisabled = true;
      this.dataForm.image_lp = '';
      this.dataForm.image_lp_url = '';
      this.dataForm.logo_lp = '';
      this.dataForm.logo_lp_url = '';
      this.dataForm.title_lp = '';
      this.dataForm.description_lp = '';
    } else {
      this.checkDisabled = false;
    }
  }

  // show popup
  setPlan(planIds) {
    this.selectedPlans = planIds.map((id, index) => {
      const plan = this.planList.find((plan: any) => plan.id === id);
      plan.display_on_lp = true;
      plan.display_priority = index + 1;
      return plan;
    });
    this.hiddenPopup('popup-confirm-plan');
    return;
  }

  deleteFile(name, value) {
    this.$set(this.dataForm, name, value);
    this.$set(this.dataForm, `${name}_url`, value);
  }

  public changeValue(value) {
    this.dataForm.logo_lp = value;
  }

  public changeValueMain(value) {
    this.dataForm.image_lp = value;
  }

  public changeAvatarValueAvatar(event, key) {
    const eventData = Object.assign(event);
    this.handleFile(event, key);
    const field = 'avatar';
    return {
      field,
      value1: eventData,
    };
  }

  public changeAvatarValueMainImage(event, key) {
    const eventData = Object.assign(event);
    this.handleFile(event, key);
    const field = 'avatar_main';
    return {
      field,
      value2: eventData,
    };
  }

  get isPreviewEnabled() {
    if (this.dataForm.template === 'main_color_green') {
      if (
        this.dataForm.logo_lp_url &&
        this.dataForm.image_lp_url &&
        this.selectedPlans.length > 0
      ) {
        return true;
      }
    } else if (this.dataForm.template !== 'none') {
      return true;
    }
    return false;
  }

  public handleFile(val, key) {
    if (typeof val === 'object') {
      const file = val;
      const reader = new FileReader();
      if (key === 'avatar') {
        this.$set(this.dataForm, 'logo_lp', val);
        if (val) {
          this.errorsLogo = '';
        }
      } else if (key === 'avatar_main') {
        this.$set(this.dataForm, 'image_lp', val);
        if (val) {
          this.errorsImage = '';
        }
      }
      reader.onload = (event: any) => {
        if (key === 'avatar') {
          this.$set(this.dataForm, 'logo_lp_url', event.target.result);
        } else if (key === 'avatar_main') {
          this.$set(this.dataForm, 'image_lp_url', event.target.result);
        }
      };
      reader.readAsDataURL(file);
    }
  }

  public openCancelCreateLpDetail() {
    this.$router.go(-1);
  }

  public async openPreview() {
    try {
      const formData = new FormData();

      formData.append('clinic_id', this.clinicId);
      formData.append('template', this.dataForm.template);
      formData.append('logo_lp', this.dataForm.logo_lp);
      formData.append('image_lp', this.dataForm.image_lp);
      formData.append('title_lp', this.dataForm.title_lp);
      formData.append('description_lp', this.dataForm.description_lp);
      formData.append(
        'plans',
        Object.values(JSON.parse(JSON.stringify(this.selectedPlans)))
          .map((x: any) => x.id)
          .join(','),
      );

      const response = await ApiHelper.getApi('ClinicV2Api').generatePreviewUrl(
        formData,
      );

      const apiUrl = encodeURIComponent(response.data);
      const lpUrl = `${config.lpUrl}/${this.uuidClinic}/preview?url=${apiUrl}`;

      window.open(lpUrl);
    } catch (error) {
      Toast.error(
        '画像のファイル形式が違っていますので、他の画像を変えてください。',
      );
    }
  }

  public updateIsSubmiting(isSubmiting: boolean) {
    this.isSubmiting = isSubmiting;
  }

  public updateIsChange(isChange: boolean) {
    if (this.isChange && isChange) {
      return;
    }
    this.isChange = isChange;
  }

  public async submitCreateLpDetail() {
    try {
      if (
        !this.dataForm.image_lp &&
        !this.dataForm.image_lp_url &&
        this.dataForm.template !== 'none' &&
        this.dataForm.template !== 'default'
      ) {
        this.errorsImage = '※必須項目が入力されていません';
      }
      if (
        !this.dataForm.logo_lp &&
        !this.dataForm.logo_lp_url &&
        this.dataForm.template !== 'none' &&
        this.dataForm.template !== 'default'
      ) {
        this.errorsLogo = '※必須項目が入力されていません';
      }
      if (this.errorsImage.length === 0 && this.errorsLogo.length === 0) {
        const cpForm = getFormData(this.dataForm);
        cpForm.append('_method', 'patch');
        cpForm.append('title_lp', this.dataForm.title_lp || '');
        cpForm.append('description_lp', this.dataForm.description_lp || '');

        for (let i = 0; i < this.selectedPlans.length; i++) {
          if (this.selectedPlans[i].display_on_lp) {
            cpForm.append(`plans[${i}][id]`, String(this.selectedPlans[i].id));
            cpForm.append(
              `plans[${i}][order]`,
              String(this.selectedPlans[i].display_priority),
            );
            cpForm.append(
              `plans[${i}][display]`,
              String(this.selectedPlans[i].display_on_lp ? 1 : 0),
            );
          }
        }
        this.updateIsSubmiting(true);
        await ApiHelper.getApi('ClinicApi').update(this.clinicId, cpForm);
        this.updateIsSubmiting(false);
        this.updateIsChange(false);
        Toast.success('更新しました');
        const data = await ClinicModule.getClinicDetail(this.clinicId);
        if (this.currentUser.role === Roles.clinic) {
          const updateData = { ...this.currentUser, clinics: [data] };
          await this.$store.dispatch('auth/updateUser', updateData);
        }
      }
    } catch (error) {
      this.updateIsSubmiting(false);
      this.updateIsChange(false);
      const data = error.response.data;
      Toast.error(data.message);
      if (error.response.status === 422) {
        // this.computedForm.setErrors(error.response.data.errors);
        return;
      }
    }
  }

  public showPopup(event, name) {
    this.isOpenMenu = false;
    event.preventDefault();
    event.stopPropagation();

    this.$modal.show(name);
  }

  public hiddenPopup(name) {
    this.$modal.hide(name);
  }

  private toast(error: any) {
    const {
      errors: { id: idError },
    } = error.response.data;
    if (idError) {
      return Toast.error('英数字で入力してください。');
    }
    Toast.error(error.response.data.message);
  }
}

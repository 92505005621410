import { Module, VuexModule } from 'vuex-module-decorators';

const DEFAULT_PAGE = 1;
const DEFAULT_PER_PAGE = 20;
const DEFAULT_LAST_PAGE = 1;

@Module({ name: 'stock', namespaced: true })
export default class Stock extends VuexModule<any> {
  data: Stock[] | [] = [];

  isLoading: boolean = false;
  perPage: number = DEFAULT_PER_PAGE;
  page: number = DEFAULT_PAGE;
  lastPage: number = DEFAULT_LAST_PAGE;

  // ********* Mutations ********* //

  // ********* Actions ********* //
}















































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

// Utils
import Toast from '@common/helpers/toast';
import ApiHelper from 'api-helper';
// Components
import BaseDatePicker from '@/components/common/BaseDatePicker.vue';
// Constants
import {
  formatDate,
  formatDate2,
  formatTime,
} from '@common/app.config';
// Icons
import iconCalendar from '@/assets/img/icon-calendar.svg';
import moment from 'moment-timezone';

@Component({
  components: {
    BaseDatePicker,
  },
})

export default class ModalReservationDate extends Vue {
  @Prop(Object) readonly reservation!: any;

  iconCalendar: any = iconCalendar;
  formatDate2: string = formatDate2;
  formatTime: string = formatTime;
  isChanged: boolean = false;
  isSubmiting: boolean = false;
  dataForm = {
    date: null,
    time: null,
  };

  get computedForm() {
    return this.$refs.modalReservationDate as any;
  }

  checkTime() {
    const today = moment.tz('Asia/Tokyo');
    const givenDate = moment.tz(this.dataForm?.date, 'Asia/Tokyo');
    const givenTime = moment.tz(`${this.dataForm?.date} ${this.dataForm?.time}`, 'Asia/Tokyo');
    if ( givenDate.isSame(today, 'day') && givenTime.hour() <= today.hour() ) {
      return true;
    } else {
      return false;
    }
  }

  formatDateTime(value: string, format: string) {
    return this?.$options?.filters?.format_time(value, format);
  }

  closeModalReservationDate() {
    this.$modal.hide('modalReservationDate');
  }

  resetForm() {
    this.dataForm = {
      date: null,
      time: null,
    };
  }

  beforeOpen() {
    document.documentElement.style.overflow = 'hidden';
  }

  beforeClose() {
    document.documentElement.style.overflow = 'auto';
    this.resetForm();
    this.$emit('onClose');
  }

  async updateReservationDate() {
    const isValid = await this.computedForm.validate();
    if (isValid) {
      try {
        this.isSubmiting = true;
        const data = {
          reservation_date: `${this.dataForm.date} ${this.dataForm.time}:00`,
        };
        const result = await ApiHelper.getApi('ManagerApi').updateReservationDate(this.reservation.id, data);
        this.isSubmiting = false;
        Toast.success('更新しました');
        this.$emit('onUpdateSuccess', result);
        this.closeModalReservationDate();
      } catch (error) {
        this.isSubmiting = false;
        Toast.error(error?.response?.data?.message);
      }
    }
  }

  @Watch('reservation')
  private watchReservationDate() {
    const { reservation_date } = this.reservation;
    this.dataForm = {
      date: this.formatDateTime(reservation_date, formatDate),
      time: this.formatDateTime(reservation_date, formatTime),
    };
  }

  @Watch('dataForm')
  private watchDataForm() {
    this.checkTime();
    this.isChanged = true;
  }

}













































// libs
import { Vue, Component, Prop } from 'vue-property-decorator';
import { State } from 'vuex-class';

// constants
import { USER_STATUS } from '@/common/constants/auth';

// interfaces
import { User } from '@/models/user';
import { Patient } from '@/models/patient';

// components
import PatientControlAvatar from '@components/v2/patient/about/patient_info/PatientControlAvatar.vue';
import PatientControlReservation from '@components/v2/patient/about/patient_info/PatientControlReservation.vue';
import PatientControlIdentification from '@components/v2/patient/about/patient_info/PatientControlIdentification.vue';
import DeletePatient from '@components/v2/patient/about/patient_info/DeletePatient.vue';
import UpdateNotePatient from '@components/v2/patient/about/patient_info/UpdateNotePatient.vue';
import EditPatient from '@components/v2/patient/about/patient_info/EditPatient.vue';

@Component({
  components: {
    PatientControlAvatar,
    PatientControlReservation,
    PatientControlIdentification,
    DeletePatient,
    UpdateNotePatient,
    EditPatient,
  },
})
export default class PatientInfoChannel extends Vue {
  @State((state) => state.patient_detail.data) patient!: Patient;
  @State((state) => state.auth.currentUser) currentUser!: User;
  @Prop(Function) readonly redirectPageWhenDeleteUser!: () => void;

  private get isShowReservationNotify() {
    return (
      this.currentUser.isDoctorRole &&
      this.patient.state === USER_STATUS.CONFIRMED_RESERVATION
    );
  }
}

import { Deserializable } from '@/common/interfaces/deserializable';
import { StockDailyRecords } from './stock_daily_records';

export class Stock implements Deserializable<Stock> {
  id: number;
  medicine_id: number;
  unit: string;
  memo: string;
  medicine_name: string;
  stock_dailies: StockDailyRecords[];

  deserialize(input: any): Stock {
    this.id = input.id;
    this.medicine_id = input.medicine_id;
    this.unit = input.unit;
    this.memo = input.memo;
    this.medicine_name = input.medicine_name;
    this.stock_dailies = input.stock_dailies.map((stockDaily) => new StockDailyRecords().deserialize(stockDaily));
    return this;
  }

  formJSONData() {
    return {
      medicine_id: this.medicine_id,
      unit: this.unit,
      memo: this.memo,
      stock_dailies: this.stock_dailies,
      _method: 'post',
    };
  }
}

export interface StockQueryParam {
  with?: string;
  id?: number;
  medicine_name?: string;
  date?: string;
}

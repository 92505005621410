



































































































































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import {
  faCameraRetro,
  faDownload,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';

// Components
import FormAction from '@/components/common/FormAction.vue';
import BasicSelect from '@/components/common/BasicSelect.vue';
import BaseInput from '@/components/common/BaseInput.vue';
import BaseInputRadio from '@/components/common/BaseInputRadio.vue';
import BaseTextarea from '@/components/common/BaseTextarea.vue';
import PopupAddClinic from '@/components/common/PopupAddClinic.vue';
import Toast from '@common/helpers/toast';

import { Roles } from '@/common/app.config';
import ApiHelper from 'api-helper';
import { cloneDeep, isArray } from 'lodash';
import { Menu } from '@/models/menu';

@Component({
  components: {
    FormAction,
    BasicSelect,
    BaseInput,
    BaseInputRadio,
    BaseTextarea,
    PopupAddClinic,
  },
})
export default class DiagnosisForm extends Vue {
  @Prop([String, Number]) diagnosisId: string | number;
  @Prop(Object) dataForm!: Menu;
  @Prop({ default: () => [], type: Array }) hiddenFields!: string[];
  @Prop(Boolean) disabledBtnCancel!: boolean;
  @Prop(Boolean) disabledBtnSubmit!: boolean;
  @Prop(Boolean) isEdit!: boolean;

  @State((state) => state.auth.currentUser) currentUser!: any;

  isSubmiting = false;
  isLoading: boolean = false;
  questions: any[] = [];
  role: string = '';
  listDoctors = [];
  selectedDoctors = [];

  get clinicSelected() {
    return this.dataForm.clinics && this.dataForm.clinics.length
      ? cloneDeep(this.dataForm.clinics)
      : [];
  }

  async created() {
    this.getQuestions();
    await this.listDoctorName();
    library.add(faCameraRetro);
    library.add(faDownload);
    library.add(faTrashAlt);
    dom.watch();
  }

  async getQuestions() {
    if (this.diagnosisId) {
      const params = {
        menu_id: this.diagnosisId,
      };
      const { data } = await ApiHelper.getApi('QuestionApi').getList(params);
      this.questions = data.sort((a, b) => a.order - b.order);
    }
  }

  get isAdmin() {
    return this.currentUser && this.currentUser.role === Roles.superAdmin;
  }

  get timeFrameOptions() {
    const data = [
      {
        name: '15分',
        value: 15,
      },
      {
        name: '30分',
        value: 30,
      },
      {
        name: '45分',
        value: 45,
      },
      {
        name: '60分',
        value: 60,
      },
    ];
    return data;
  }

  mounted() {
    this.listDoctorName();
  }

  listDoctorName() {
    this.isLoading = true;
    ApiHelper.getApi('DoctorApi')
      .getList()
      .then((res: any) => {
        this.listDoctors = res.data?.map((doctor: any) => ({
          id: doctor.id,
          name: doctor.name,
        }));
      })
      .catch((error: any) => {
        Toast.error(error.response.data.message);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  handleDoctorIdsChange(selectedDoctors) {
    if (!Array.isArray(selectedDoctors)) {
      selectedDoctors = [selectedDoctors];
    }

    this.$emit('onChange', 'doctors', selectedDoctors);
  }

  get displayFeeErrorMessages() {
    return {
      numeric: '正の数字を入力してください。',
    };
  }

  goToQuestionListEdit() {
    if (this.diagnosisId) {
      const id = String(this.diagnosisId);
      this.$router.push({
        name: 'question.list-edit',
        params: { id },
      });
    }
  }
  goToPrecautionEdit() {
    if (this.diagnosisId) {
      const id = String(this.diagnosisId);
      this.$router.push({
        name: 'diagnosis.precaution',
        params: { id },
      });
    }
  }

  showPopup(event, name) {
    event.preventDefault();
    event.stopPropagation();

    this.$modal.show(name);
  }

  hiddenPopup(name) {
    this.$modal.hide(name);
  }

  setClinic(data) {
    this.dataForm.clinics = isArray(data) ? data : [data];
  }

  @Watch('dataForm.is_free')
  private watchInputFee(_, oldValue) {
    if (![true, false].includes(oldValue)) {
      return;
    }
    if (!this.dataForm.is_free) {
      (this.$refs.inputFee as any).reset();
      return;
    }
    this.$emit('onChange', 'fee', '');
  }
}


























import { Vue, Component } from 'vue-property-decorator';
import ApiHelper from 'api-helper';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faCameraRetro } from '@fortawesome/free-solid-svg-icons';

// Utils
import Toast from '@common/helpers/toast';
import { getFormData } from '@/common/utils';
// Components
import ContentLayout from '@components/layouts/Content.vue';
import DragDropFileUpload from '@/components/common/DragDropFileUpload.vue';
import MedicineForm from './components/MedicineForm.vue';
// Icons
import Listicon from '@/assets/img/icon-medicine.svg';

@Component({
  components: {
    ContentLayout,
    DragDropFileUpload,
    MedicineForm,
  },
})
export default class Create extends Vue {
  iconHeader: any = Listicon;
  isSubmiting = false;
  isLoading: boolean = false;

  dataForm = {
    name: '',
    usage: '',
    about_medicine: '',
    detail: '',
    precautions_for_use: '',
    side_effects: '',
    prohibition_of_concomitant_use: '',
    image: '',
    image_url: '',
    logo_image: '',
    logo_image_url: '',
    display_order: '',
    wakeup_dosage: '',
    morning_dosage: '',
    afternoon_dosage: '',
    evening_dosage : '',
    sleep_dosage: '',
  };

  get computedForm() {
    return this.$refs.dataForm as any;
  }

  async created() {
    library.add(faCameraRetro);
    dom.watch();
  }

  updateIsSubmiting(isSubmiting: boolean) {
    this.isSubmiting = isSubmiting;
  }

  uploadFile(name, val) {
    const file = val;
    const reader = new FileReader();
    this.$set(this.dataForm, name, val);
    reader.onload = (event: any) => {
      this.$set(this.dataForm, `${name}_url`, event.target.result);
    };
    reader.readAsDataURL(file);
  }

  deleteFile(name, value) {
    this.$set(this.dataForm, name, value);
    this.$set(this.dataForm, `${name}_url`, value);
  }

  handleChange(name, value) {
    this.$set(this.dataForm, name, value);
  }

  handleCancel() {
    return this.$router.push({ name: 'medicine' });
  }

  async handleSubmit() {
    const valid = await this.computedForm.validate();
    if (valid) {
      try {
        this.updateIsSubmiting(true);
        const formData = getFormData(this.dataForm);
        await ApiHelper.getApi('MedicineApi').create(formData);
        this.updateIsSubmiting(false);
        Toast.success('登録しました');
        this.handleCancel();
      } catch (error) {
        this.updateIsSubmiting(false);
        const data = error.response.data;
        Toast.error(data.message);
        if (error.response.status === 422) {
          this.computedForm.setErrors(error.response.data.errors);
          return;
        }
      }
    }
  }
}

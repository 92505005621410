import { Vue, Component } from 'vue-property-decorator';

@Component
export default class ClinicsMixin extends Vue {
  public updateForm(form, field, value) {
    this.$set(form, field, value);
  }

  public setData(form, data: any) {
    // const re = /^(84|81)/gi;
    // data.phone = data.phone.replace(re, '0');
    // data.fax = (data.fax || '').replace(re, '0');
    // data.managers[0].phone = data.managers[0].phone.replace(re, '0');

    // this.updateForm(form, 'medical_institution_code', data.medical_institution_code);
    // this.updateForm(form, 'medical_department', data.medical_department);
    this.updateForm(form, 'name', data.name);
    this.updateForm(form, 'name_kana', data.name_kana);
    // this.updateForm(form, 'email', data.email);
    this.updateForm(form, 'phone', data.phone);
    // this.updateForm(form, 'fax', data.fax);
    this.updateForm(form, 'postal_code', data.postal_code);
    this.updateForm(form, 'prefecture_id', data.prefecture_id);
    this.updateForm(form, 'city_id', data.city_id);
    this.updateForm(form, 'address', data.address);
    this.updateForm(form, 'building_name', data.building_name);
    // this.updateForm(form, 'introduction', data.introduction);
    // this.updateForm(form, 'image', data.image_url);
    this.updateForm(form, 'type', data.type);
    // this.updateForm(form, 'manager_name', data.managers[0].name);
    // this.updateForm(form, 'manager_name_kana', data.managers[0].name_kana);
    // this.updateForm(form, 'position', data.managers[0].position);
    // this.updateForm(form, 'manager_phone', data.managers[0].phone);
    // this.updateForm(form, 'manager_email', data.managers[0].email);
    this.updateForm(form, '_method', 'patch');
    this.updateForm(form, 'gtm_id', data.gtm_id);
    this.updateForm(form, 'template', data.template);
    this.updateForm(form, 'title_lp', data.title_lp);
    this.updateForm(form, 'description_lp', data.description_lp);
  }

  public get medicalInstitutionCodeErrorMessages() {
    return {
      max: '医療機関コードは30文字以内で入力してください',
    };
  }

  public get medicalDepartmentErrorMessages() {
    return {
      max: '診療科は100文字以内で入力してください',
    };
  }

  public get clinicNameErrorMessages() {
    return {
      max: 'クリニック名は30文字以内で入力してください',
    };
  }

  public get clinicNameKanaErrorMessages() {
    return {
      max: 'クリニック名(カナ)は100文字以内で入力してください',
    };
  }

  public get emailErrorMessages() {
    return {
      email: 'メールアドレスを正しく入力してください',
      max: 'メールアドレスは255文字以内で入力してください',
    };
  }

  public get phoneErrorMessages() {
    return {
      max: '電話番号は10~11桁の半角数字で入力してください',
      min: '電話番号は10~11桁の半角数字で入力してください',
      numeric: '電話番号は半角数字で入力してください',
      phone: '電話番号は半角数字10〜11桁で入力してください',
    };
  }

  public get faxErrorMessages() {
    return {
      max: 'FAX番号は10~11桁の半角数字で入力してください',
      min: 'FAX番号は10~11桁の半角数字で入力してください',
      numeric: 'FAX番号は10~11桁の半角数字で入力してください',
      phone: 'FAX番号は電話番号を正しく入力してください',
    };
  }

  public get postalCodeErrorMessages() {
    return {
      regex: '郵便番号は7桁の半角数字で入力してください',
    };
  }

  public get prefectureErrorMessages() {
    return {
      max: '都道府県は30文字以内で入力してください',
    };
  }

  public get cityErrorMessages() {
    return {
      max: '市区町村は30文字以内で入力してください',
    };
  }

  public get addressErrorMessages() {
    return {
      max: '番地は30文字以内で入力してください',
    };
  }

  public get buildingNameErrorMessages() {
    return {
      max: '建物名は30文字以内で入力してください',
    };
  }

  public get introductionErrorMessages() {
    return {
      max: '紹介文は200文字以内で入力してください',
    };
  }

  public get managerNameErrorMessages() {
    return {
      max: '担当者名は30文字以内で入力してください',
    };
  }

  public get managerNameKanaNameErrorMessages() {
    return {
      max: '担当者名(カナ)は100文字以内で入力してください',
    };
  }

  public get positionErrorMessages() {
    return {
      max: '担当者役職は30文字以内で入力してください',
    };
  }

  public get managerPhoneErrorMessages() {
    return {
      max: '担当者電話番号は10~11桁の半角数字で入力してください',
      min: '担当者電話番号は10~11桁の半角数字で入力してください',
      phone: '担当者電話番号は正しく入力してください',
      numeric: '担当者電話番号は10~11桁の半角数字で入力してください',
    };
  }

  public get managerEmailErrorMessages() {
    return {
      email: '担当者メールアドレスを正しく入力してください',
      max: '担当者メールアドレスは255文字以内で入力してください',
    };
  }

  public get passwordErrorMessages() {
    return {
      min: 'パスワードは8桁以上半角英数字で入力してください',
    };
  }

  public get confirmationErrorMessages() {
    return {
      confirmed: 'パスワードが一致しません。正しいパスワードを入力してください',
      min: 'パスワード(確認)は8桁以上半角英数字で入力してください',
    };
  }

  public get imageErrorMessages() {
    return {
      mimes: '画像はjpg, pngのファイルでアップロードしてください',
    };
  }

  public get bankNameErrorMessages() {
    return {
      max: '銀行名は30文字以内で入力してください',
    };
  }

  public get bankBranchNameErrorMessages() {
    return {
      max: '支店名は30文字以内で入力してください',
    };
  }

  public get bankAccountTypeErrorMessages() {
    return;
  }

  public get bankAccountNumErrorMessages() {
    return {
      min: '口座番号は1桁以内で入力してください',
      max: '口座番号は7桁以内で入力してください',
      numeric: '口座番号は数字で入力してください',
    };
  }

  public get bankExpirationDateErrorMessages() {
    return;
  }

  public get bankAccountHolderErrorMessages() {
    return {
      onlyText: '口座名義は30文字以内で入力してください',
      max: '口座名義は30文字以内で入力してください',
    };
  }

  public get typeErrorMessages() {
    return {
      max: '都道府県は30文字以内で入力してください',
    };
  }

  public get gtmIdErrorMessages() {
    return {
      max: 'タグは{length}文字以内にしてください',
    };
  }
}

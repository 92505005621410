

























































import { Vue, Component, Prop } from 'vue-property-decorator';
import PageLoader from '@components/common/PageLoader.vue';
import BasicSelect from '@/components/common/BasicSelect.vue';
import ApiHelper from '@/api/ApiHelper';
import Toast from '@common/helpers/toast';
import { Menu } from '@/models/menu';

@Component({
  components: {
    PageLoader,
    BasicSelect,
  },
})
export default class PopupConfirmDoctor extends Vue {
  @Prop({
    default() {
      return [];
    },
  })
  @Prop(Object)
  dataForm!: Menu;

  isLoading: boolean = false;
  listDoctors: [];

  created() {
    this.listDoctorName();
  }

  listDoctorName() {
    this.isLoading = true;
    const params = {
      current_page: 'patients',
      };
    ApiHelper.getApi('DoctorApi')
      .getList(params)
      .then((res: any) => {
        this.listDoctors = res.data.map((doctor) => ({id: doctor.id, name: doctor.name}));
      })
      .catch((error: any) => {
        Toast.error(error.response.data.message);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  cancelSetDoctor() {
    this.$emit('cancel');
  }

  handleSubmit() {
    this.$emit('submit', this.dataForm);
  }
}

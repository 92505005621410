










































import { Vue, Component, Prop } from 'vue-property-decorator';
import Avatar from '@components/v2/Avatar.vue';
import { State } from 'vuex-class';
import ImagePrivate from '@/components/common/ImagePrivate.vue';
import { getUserStateName, getNewUserStateName, NEW_USER_STATE } from '@/common/constants/auth';
import lodashGet from 'lodash/get';

@Component({
  components: {
    ImagePrivate,
    Avatar,
  },
})
export default class PatientItem extends Vue {
  @State((state) => state.auth.currentUser) currentUser!: any;
  @Prop({ required: true }) patient!: any;
  @Prop({ required: true }) showCheckbox!: boolean;

  handleCheckboxClick(event: Event): void {
    const checkbox = event.target as HTMLInputElement;
    const isChecked = checkbox.checked;
    event.stopPropagation();
    this.$emit('checkbox', isChecked, this.patient );
  }

  isCheckbox() {
    return this.showCheckbox && this.patient.doctor_chat_state === NEW_USER_STATE.DOCTOR_CHAT_UNREPLY;
  }

  get lastMessage() {
    return lodashGet(this.patient, 'channels.latest_message.message', '');
  }

  get getUnreadCount() {
    const unreadCount = lodashGet(this.patient, 'channels.message_recipients[0].unread_count', 0);

    if (unreadCount > 99) {
       return '99+';
    }

    return unreadCount;
  }

  get isShowUserTreatmentIcon() {
    const userTreatmentsArray = lodashGet(this.patient, 'userTreatments', []);

    const allowableState = ['choice_interview_method', 'chat_interview_scheduled', 'video_interview_date', 'doctor_prescription_check'];

    const foundUserTreatment = userTreatmentsArray.find((userTreatment) => {
      const userTreatmentState = lodashGet(userTreatment, 'state', null);
      return userTreatmentState
        && allowableState.includes(userTreatmentState)
        && this.currentUser?.isDoctorRole
        && userTreatment?.feedback_type !== NEW_USER_STATE.DOCTOR_RESERVE_PRESCRIPTION;
    });
    return !!foundUserTreatment;
  }

  get patientName() {
      return `${this.patient.family_name || ''} ${this.patient.given_name || ''}`.trim() || '-';
  }

  get getStatusName() {
    return getNewUserStateName(this.patient.new_state);
  }
}

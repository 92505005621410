















































































































































import { Vue, Component } from 'vue-property-decorator';
import ApiHelper from 'api-helper';
import LocalStorageUtils from '@common/LocalStorageUtils';
import { AuthenticationModule, DashboardModule } from '@/store';
import Toast from '@common/helpers/toast';
import { State } from 'vuex-class';
import ContentLayout from '@components/layouts/Content.vue';
import memberIcon from '@/assets/img/memberIcon.svg';
import { ROLE_LIST } from '@/common/app.config';
import AuthenticationUtils from '@common/AuthenticationUtils';

@Component({
  components: {
    ContentLayout,
  },
  filters: {},
  data() {
    return {
      iconHeader: memberIcon,
    };
  },
})
export default class MemberCreate extends Vue {
  public get passwordErrorMessages() {
    return {
      min: '半角英数字を含める8文字以上で設定してください',
    };
  }

  public get confirmationErrorMessages() {
    return {
      min: '半角英数字を含める8文字以上で設定してください',
      confirmed: '確認用パスワードは一致していません。',
    };
  }
  @State((state) => state.auth.currentUser) currentUser!: any;
  public submitting = false;
  public clinic = '';
  public formData = {
    email: '',
    role: '',
    name: '',
    password: '',
    password_confirmed: '',
  };
  created() {
    const isAuthenticated = AuthenticationUtils.isAuthenticated();

    if (isAuthenticated) {
      this.$router.push('/404');
    } else {
      this.getInfo();
    }
  }

  async gotoLogin() {
    if ((window as any).UA) {
      (window as any).UA.then(async (sdk: any) => {
        if (sdk.channel && !sdk.channel.namedUser) {
          return;
        }
        if (sdk.channel) {
          await sdk.channel.namedUser.remove(`managers_${this.currentUser.id}`);
        }
        await sdk.register();
      });
    }

    await AuthenticationModule.logout();
    await DashboardModule.setClinics([]);
    await LocalStorageUtils.removeItem('dashboardID');
    await LocalStorageUtils.removeItem('revenueClinic');
    await LocalStorageUtils.removeItem('monthlyReportClinic');
    await localStorage.removeItem('isLoggedIn');
    this.$router.push('/login');
  }
  public getInfo() {
    if (this.$route.query.token) {
      ApiHelper.getApi('MemberApi')
        .getInviteMemberByToken(this.$route.query.token)
        .then((res) => {
          this.formData.email = res.email;
          this.formData.role = res.role;
          this.clinic = res.clinic.name;
        });
    }
  }
  public submit(invalid) {
    if (invalid) {
      (this.$refs.createMemberForm as any).validate();
      return;
    }
    this.submitting = true;
    ApiHelper.getApi('MemberApi')
      .createMemberByToken(this.$route.query.token, this.formData)
      .then((res) => {
        this.$modal.show('doneCreateMemberModal');
      })
      .catch((error: any) => {
        Toast.error(error.response.data.message);
      })
      .finally(() => {
        this.submitting = false;
      });
  }
  public getRoleText(role: string): string {
    if (role) {
      const roleOpt = ROLE_LIST.find((o) => o.value == role);
      return roleOpt ? roleOpt.name : '';
    }

    return '';
  }
}



















































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { includes } from 'lodash';

import { Roles } from '@common/app.config';
import { USER_TREATMENT_STATE } from '@/common/constants/auth';
import LoaderComponent from '@components/common/LoaderComponent.vue';
import PopupConfirm from '@/components/common/PopupConfirm.vue';

import Chat from '@/views/v2/patient/about/Chat.vue';
import { State } from 'vuex-class';
import PatientInfo from '@/views/v2/patient/about/PatientInfo.vue';
import QuestionInfo from '@/views/v2/patient/about/QuestionInfo.vue';
import DeliveryInfo from '@/views/v2/patient/about/DeliveryInfo.vue';
import KarteInfo from '@/views/v2/patient/about/KarteInfo.vue';
import { Patient } from '@/models/patient';
import SidebarPatient from '@/components/v2/patient/about/side_bar/SideBarPatient.vue';
import { UserTreatment } from '@/models/user-treatment';

@Component({
  components: {
    SidebarPatient,
    PatientInfo,
    QuestionInfo,
    DeliveryInfo,
    Chat,
    KarteInfo,
    LoaderComponent,
    PopupConfirm,
  },
})
export default class Index extends Vue {
  @State((state) => state.patient_detail.data) patient!: Patient;
  @State((state) => state.patient_detail.isLoading)
  IsLoadingCurrentPatient!: boolean;
  @State((state) => state.auth.currentUser) currentUser!: any;
  @State((state) => state.treatment_detail.data)
  activeUserTreatment: UserTreatment;
  @State((state) => state.treatment_detail.isLoading)
  isLoadingTreatmentDetail: boolean;
  @State((state) => state.treatment_detail.isLoadingChild)
  isLoadingTreatmentDetailChild: boolean;

  private tabBar = {
    info: {
      key: 'info',
      name: '患者情報',
      image: require('@/assets/img/patient-user.svg'),
      roles: [
        Roles.doctor,
        Roles.superAdmin,
        Roles.admin,
        Roles.pharmacist,
        Roles.customerService,
      ],
    },
    questions: {
      key: 'questions',
      name: '問診内容',
      image: require('@/assets/img/patient-question.svg'),
      roles: [Roles.doctor],
    },
    medical: {
      key: 'medical',
      name: 'カルテ',
      image: require('@/assets/img/patient-medical.svg'),
      roles: [Roles.doctor],
    },
    delivery: {
      key: 'delivery',
      name: '配送',
      image: require('@/assets/img/patient-truck.svg'),
      roles: [
        Roles.doctor,
        Roles.admin,
        Roles.pharmacist,
        Roles.customerService,
      ],
    },
    chat: {
      key: 'chat',
      name: 'チャット',
      image: require('@/assets/img/patient-chat.svg'),
      roles: [Roles.doctor],
    },
  };
  private activeTab: any = '';
  private isVideoCall: boolean = false;
  private isShowVideoModal = true;
  private nextTab = null;

  // ***************** life cycle ***************** //
  created() {
    // reset treatment detail store.
    this.$store.commit('treatment_detail/setTreatment', {});

    this.loadPatientDetail();

    // hidden sidebar patient.
    this.$emit('hiddenSidebarPatientList');

    if (this.$route.query && this.$route.query.activeTab) {
      this.setActiveTab(this.$route.query.activeTab);
      return;
    }

    if (!this.activeUserTreatment.id) {
      this.activeTab = this.tabBar.info;
    }

  }

  beforeDestroy() {
    this.$emit('hiddenSidebarPatientList', false);
  }

  @Watch('activeUserTreatment', { deep: true })
  setActiveTabDefault(currentTreatment, oldTreatment) {
    if (currentTreatment?.id !== oldTreatment?.id) {
      this.changeActiveTabByState(this.activeUserTreatment.state);
    }
  }

  // ***************** getter ***************** //

  // ***************** setter ***************** //

  private setActiveTab(tabKey) {
    this.activeTab = this.tabBar[tabKey];
  }

  // ***************** method ***************** //
  private async loadPatientDetail() {
    const { patientId } = this.$route.params;
    await this.$store.dispatch('patient_detail/getPatient', { id: patientId });
  }

  private isDisableTab(tabName) {
    return (
      !this.tabBar[tabName].roles.includes(this.currentUser.role) ||
      // disable question tab when activeTreatment is subscription.
      (!this.activeUserTreatment.menu &&
        tabName === this.tabBar.questions.key) ||
      // disable medical record when activeTreatment is subscripiton
      (!this.activeUserTreatment.menu && tabName === this.tabBar.medical.key)
    );
  }

  private changeActiveTabByState(state: string) {
    if (this.$route.query && this.$route.query.activeTab) {
      this.setActiveTab(this.$route.query.activeTab);
      return;
    }

    const role = this.currentUser.role;
    // question
    if (
      state === USER_TREATMENT_STATE.CHOICE_INTERVIEW_METHOD &&
      includes(this.tabBar.questions.roles, role) &&
      this.activeUserTreatment.menu
    ) {
      this.activeTab = this.tabBar.questions;
      return;
    }

    // chat
    if (
      [
        USER_TREATMENT_STATE.CHAT_INTERVIEW_SCHEDULED,
        USER_TREATMENT_STATE.VIDEO_INTERVIEW_DATE,
      ].includes(state) &&
      includes(this.tabBar.chat.roles, role)
    ) {
      this.activeTab = this.tabBar.chat;
      return;
    }

    // medical
    if (
      state === USER_TREATMENT_STATE.DOCTOR_PRESCRIPTION_CHECK &&
      includes(this.tabBar.medical.roles, role) &&
      this.activeUserTreatment.menu
    ) {
      this.activeTab = this.tabBar.medical;
      return;
    }

    // information
    this.activeTab = this.tabBar.info;
  }

  private changeActiveTab(tabKey) {
    if ((this.$refs?.chatComponent as any)?.getVideoComponent) {
      this.nextTab = tabKey;
      return this.showModalLeaveVideo();
    }
    this.setActiveTab(tabKey);
  }

  private showModalLeaveVideo() {
    if (this.isShowVideoModal === false) {
      this.$modal.hide('popupConfirm');
      this.isShowVideoModal = true;
    } else {
      this.$modal.show('popupConfirm');
    }
  }

  private leavePage() {
    this.$modal.hide('popupConfirm');
    this.isShowVideoModal = true;
    // this.setActiveTab(this.nextTab);
    const routeQuery = {
      ...this.$router.currentRoute.query,
      tab: this.nextTab,
    };
    const routeData = this.$router.resolve({
      name: 'AboutPatient',
      query: routeQuery,
    });
    window.open(routeData.href, '_blank');
    this.isShowVideoModal = false;
  }

  private closeModal() {
    this.isShowVideoModal = false;
    this.$modal.hide('popupConfirm');
  }

  private redirectPageWhenDeleteUser() {
    (this.$refs.sidebar_patient_ref as any)?.goToPatientListWithoutData();
  }
}

import { Deserializable } from '@/common/interfaces/deserializable';

export class StockDailyRecords implements Deserializable<StockDailyRecords> {
  id: number;
  stock_id: number;
  date: string;
  medicine_received: number;
  medicine_shiped: number;
  medicine_on_hand: number;

  deserialize(input: any): StockDailyRecords {
    this.id = input.id;
    this.stock_id = input.stock_id;
    this.date = input.date;
    this.medicine_received = input.medicine_received;
    this.medicine_shiped = input.medicine_shiped;
    this.medicine_on_hand = input.medicine_on_hand;
    return this;
  }
}

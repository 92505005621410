




























































import {Component, Prop, Vue} from 'vue-property-decorator';
import moment from 'moment';
import lodash from 'lodash';
import {Plan} from '@/models/plan';
import {DeliveryStateDisplay, DeliveryStateType} from '@common/enums/order';
import BasicModal from '@/components/common/BasicModal.vue';
import EditDelivery from '@components/order/EditDelivery.vue';
import ModalRefund from '@components/order/ModalRefund.vue';
import ModalEditAmount from '@components/order/ModalEditAmount.vue';
import {Tracking} from '@/models/tracking';

@Component({
  components: {
    BasicModal,
    EditDelivery,
    ModalRefund,
    ModalEditAmount,
  },
  data() {
    return {
      lodash,
      moment,
      DeliveryStateDisplay,
    };
  },
  filters: {
    formatNum: (value) => {
      let formatedString = '0';
      if (value) {
        formatedString =  value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
      }

      return `${formatedString}円`;
    },
  },
})
export default class OrderTrackingTable extends Vue {
  @Prop(Array) readonly tracking: Tracking[];
  @Prop(Object) readonly plan: Plan;
  private deliveryItem = {};

  private showIconRefund(value) {
    const refund_amount = lodash.sumBy(value.orders_tracking_history_refunded, (item: any) => {
      return parseInt(item.amount);
    });
    return parseInt(value.amount) - refund_amount;
  }

  private showEditDelivery(item) {
    this.deliveryItem = item;
    this.$modal.show('modalEditDelivery');
  }

  private showRefund(item) {
    this.deliveryItem = item;
    this.$modal.show('modalRefundAmount');
  }

  private showEditAmount(item) {
    this.deliveryItem = item;
    this.$modal.show('modalEditAmount');
  }

  private isShowDeliveryIcon(state) {
    return lodash.includes([
        DeliveryStateType.NotPaid, DeliveryStateType.Paid, DeliveryStateType.ReadyForDelivery, DeliveryStateType.ErrorPaid,
        DeliveryStateType.ScheduledDelivery,
    ], state);
  }

  private isShowEditAmountIcon(state) {
    return lodash.includes([DeliveryStateType.NotPaid, DeliveryStateType.ErrorPaid], state);
  }
}

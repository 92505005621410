var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-reservation-date"},[_c('modal',{attrs:{"name":_vm.modalName,"clickToClose":false,"scrollable":true,"height":"auto"}},[_c('button',{staticClass:"calendar-control-delete",on:{"click":function($event){return _vm.$modal.show('popupDeleteReservation')}}},[_c('img',{attrs:{"src":require("@/assets/img/icon-cancel.svg"),"alt":""}}),_vm._v(" 削除 ")]),_c('ValidationObserver',{ref:_vm.modalName,staticClass:"form-edit-reservation",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.updateReservation($event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var dirty = ref.dirty;
return [_c('div',{staticClass:"form-edit-reservation__patient-name mb-3"},[_c('div',{staticClass:"form-edit-reservation__patient-name__info mb-2"},[_c('label',{staticClass:"form-edit-reservation__label"},[_vm._v("患者名")]),_c('div',{staticClass:"form-edit-reservation__patient-name__text form-edit-reservation__patient-name--font-size"},[_vm._v(" "+_vm._s(_vm.reservation.user.family_name)+" "+_vm._s(_vm.reservation.user.given_name)+" ")]),_c('div',{staticClass:"form-edit-reservation__patient-name__text"},[_vm._v(" "+_vm._s(_vm.reservation.user.family_name_kana)+" "+_vm._s(_vm.reservation.user.given_name_kana)+" ")]),_c('div',{staticClass:"form-edit-reservation__patient-name__text"},[_c('span',{staticClass:"form-group value"},[_vm._v("ID "+_vm._s(_vm.reservation.user.id || '-'))])])]),_c('div',{staticClass:"form-edit-reservation__patient-name__action",on:{"click":function($event){return _vm.gotoDetail(_vm.reservation)}}},[_vm._v(" 患者情報へ ")])]),_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-edit-reservation__label"},[_vm._v("ビデオ診療日時")]),_c('div',{staticClass:"mb-3 d-flex"},[_c('div',{staticClass:"flex-1 pr-3"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseDatePicker',{attrs:{"disabledEqualOrBeforeToday":"","clearable":false,"errorText":errors[0]},model:{value:(_vm.dataForm.date),callback:function ($$v) {_vm.$set(_vm.dataForm, "date", $$v)},expression:"dataForm.date"}})]}}],null,true)})],1),_c('div',{staticClass:"form-edit-reservation__input-time"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"time"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseDatePicker',{attrs:{"type":"time","valueType":"format","disabledBeforeTimeToday":"","date":_vm.dataForm.date,"showSecond":false,"minuteStep":10,"format":_vm.formatTime,"errorText":errors[0]},model:{value:(_vm.dataForm.time),callback:function ($$v) {_vm.$set(_vm.dataForm, "time", $$v)},expression:"dataForm.time"}})]}}],null,true)})],1)])]),_c('FormAction',{staticClass:"px-4",attrs:{"disabledSubmit":!dirty || invalid || _vm.isSubmiting || !_vm.isChanged},on:{"onSubmit":_vm.updateReservation,"onCancel":_vm.hiddenUpdateReservation}})]}}])})],1),_c('PopupConfirmDeletion',{attrs:{"name":"popupDeleteReservation","clickToClose":false,"disabledBtnSubmit":_vm.isSubmitDelete},on:{"submit":_vm.deleteReservation,"cancel":function($event){return _vm.$modal.hide('popupDeleteReservation')}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import Home from '@/views/Home.vue';
import Layout from '@/views/Layout.vue';
import Vue from 'vue';
import Router from 'vue-router';

import AnswerRouter from '@/routers/answer';
import AuthRoutes from '@/routers/auth';
import CallingRoutes from '@/routers/calling';
import ClinicPattern from '@/routers/clinic_pattern';
import ClinicRoutes from '@/routers/clinics';
import CourseRouter from '@/routers/courses';
import DeliveriesRouter from '@/routers/deliveries';
import DeliveryRouter from '@/routers/delivery.router';
import DiagnosisRoutes from '@/routers/diagnosis';
import DoctorAdminsRouter from '@/routers/doctor-admins';
import DoctorProfileRoutes from '@/routers/doctor_profile';
import DoctorShiftRoutes from '@/routers/doctor_shift';
import DoctorRoutes from '@/routers/doctors';
import ErrorRoutes from '@/routers/error';
import LineMessageRouter from '@/routers/line_message.router';
import MedicalMenuRoutes from '@/routers/medical_menu';
import MedicineRoutes from '@/routers/medicine';
import UserRouters from '@/routers/member';
import MessageRoutes from '@/routers/message';
import NotificationRouters from '@/routers/notification';
import OrderRouter from '@/routers/order.router';
import PatientRoutes from '@/routers/patient';
import PharmacistRouter from '@/routers/pharmacist';
import PharmacistReservationRouter from '@/routers/pharmacist_reservation';
import PlanRoutes from '@/routers/plans';
import QuestionRouters from '@/routers/question';
import SalesManagement from '@/routers/sales_management';
import SettingsRouter from '@/routers/setting';
import PatientV2 from '@/routers/v2/patient';
import Thankyou from '@/views/Thankyou.vue';
import DeliveryMedicines from './views/deliveries/DeliveryMedicines.vue';
import StockRoute from '@/routers/stock';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...AuthRoutes,
    {
      path: '/',
      component: Layout,
      children: [
        {
          path: '/',
          name: 'home',
          component: Home,
          meta: {
            auth: true,
            authorize: [],
          },
        },
        ...ClinicRoutes,
        ...MessageRoutes,
        ...DoctorRoutes,
        ...DoctorShiftRoutes,
        ...DoctorProfileRoutes,
        ...PatientRoutes,
        ...MedicineRoutes,
        ...DiagnosisRoutes,
        ...MedicalMenuRoutes,
        ...NotificationRouters,
        ...SettingsRouter,
        ...PlanRoutes,
        ...CallingRoutes,
        ...OrderRouter,
        ...DeliveryRouter,
        ...AnswerRouter,
        ...CourseRouter,
        ...DoctorAdminsRouter,
        ...ClinicPattern,
        ...UserRouters,
        ...QuestionRouters,
        ...DeliveriesRouter,
        ...LineMessageRouter,
        ...PharmacistRouter,
        ...PharmacistReservationRouter,
        ...PatientV2,
        ...SalesManagement,
        ...StockRoute,
      ],
    },
    {
      path: '/deliveries/:id/medicines',
      name: 'deliveries.medicines',
      component: DeliveryMedicines,
      meta: { auth: true },
    },
    {
      path: '/thanks',
      name: 'resv.ended',
      component: Thankyou,
      meta: {
        auth: false,
      },
    },
    ...ErrorRoutes,
    {
      path: '*',
      redirect: { name: 'PageNotFound' },
    },
  ],
});

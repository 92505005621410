import { authAdmin } from '@/common/constants/auth';
import Index from '@/views/sales_management/Index.vue';
import Show from '@/views/sales_management/SaleManagementDetail.vue';
import ShowPlan from '@/views/sales_management/SaleManagementPlanDetail.vue';


export default [
  {
    path: 'sales-management',
    name: 'sales-management',
    component: Index,
    meta: authAdmin,
  },
  {
    path: '/sales-management/show/:clinic_id',
    name: 'sales-management.show',
    component: Show,
    meta: authAdmin,
  },
  {
    path: '/sales-management/show/:clinic_id/plans/:plan_id',
    name: 'sales-management.plan',
    component: ShowPlan,
    meta: authAdmin,
  },
];

import BaseApi from '../BaseApi';

class DoctorFeedbackApi extends BaseApi {
  public doctorGenerateLink(params: any) {
    return this.post('doctor-request-call/generate-link', params);
  }

  public doctorSuggestReservationDate(params: any) {
    return this.post('doctor-request-call/suggest-reservation-date', params);
  }

  protected getSpecificApiPrefix(): string {
    return 'v2/managers/';
  }
}

export default DoctorFeedbackApi;





































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import Calendar from '@components/Doctor/Calendar.vue';
import ApiHelper from 'api-helper';
import Toast from '@common/helpers/toast';
import BasicSelect from '@/components/common/BasicSelect.vue';
import moment from 'moment';
import VueTimepicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css';
import 'vue2-datepicker/index.css';
import DatePicker from 'vue2-datepicker';
import {State} from 'vuex-class';
import { Roles, formatTime } from '@common/app.config';
import BaseDatePicker from '@/components/common/BaseDatePicker.vue';

@Component({
  components: {
    Calendar,
    BasicSelect,
    VueTimepicker,
    DatePicker,
    BaseDatePicker,
  },
  computed: {
    ...mapState('auth', [
      'currentUser',
    ]),
  },
})
export default class ShiftSetting extends Vue {
  // private get computedForm() {
  //   return this.$refs.formCreateShift as any;
  // }
  @State((state) => state.auth.currentUser) currentUser!: any;
  private doctorOptions = [];
  private events = [];
  private Roles = Roles;
  private searchOptions =  {search_by: 'week', date: moment().format('YYYY-MM-DD')};
  private formCreate = {
    doctor_id: null,
    date: null,
    start_time: '00:00',
    end_time: '00:00',
  };
  private formatTime: string = formatTime;
  private hourRange: any = this.range(0, 24);
  private minutesRangeStartTime: any = [0, 20, 40];
  private minutesRangeEndTime: any = [0, 20, 40];
  private isCheckStartEndTIme: boolean = false;

  mounted() {
    if ([Roles.superAdmin, Roles.admin].includes(this.currentUser.role)) {
      this.fetchData();
    }
    if (this.currentUser.role === Roles.doctor) {
      this.formCreate.doctor_id = this.currentUser.id;
    }

    this.fetchDataShifts();
  }

  private setSearchOptions(search) {
    this.searchOptions = search;
  }

  private checkStartEndTime() {
    const timeStart = moment(this.formCreate.start_time, 'HH:mm').diff(moment().startOf('day'), 'seconds');
    const timeEnd = moment(this.formCreate.end_time, 'HH:mm').diff(moment().startOf('day'), 'seconds');
    return timeEnd > timeStart;
  }

  private checkSubmit() {
    return !(this.formCreate.doctor_id && this.formCreate.start_time && this.formCreate.end_time && this.formCreate.date && this.checkStartEndTime());
  }

  @Watch('formCreate.end_time')
  @Watch('formCreate.start_time')
  private updateisCheckStartEndTIme(value) {
    if (this.isCheckStartEndTIme || value === '00:00') {
      return;
    }
    this.isCheckStartEndTIme = true;
  }

  @Watch('formCreate.start_time')
  private changeStartTime(value) {
    const hour = parseInt(moment(value, 'HH:mm').format('HH'));
    if (moment().format('YYYY-MM-DD') == this.formCreate.date && hour == parseInt(moment().format('HH'))) {
      if (hour == 0) {
        this.minutesRangeStartTime = [0, 20, 40];
      } else if (hour <= 30) {
        this.minutesRangeStartTime = [30];
      } else {
        this.minutesRangeStartTime = [0, 20, 40];
      }
    } else {
      this.minutesRangeStartTime = [0, 20, 40];
    }
  }

  @Watch('formCreate.end_time')
  private changeEndTime(value) {
    const hour = parseInt(moment(value, 'HH:mm').format('HH'));
    if (moment().format('YYYY-MM-DD') == this.formCreate.date && hour == parseInt(moment().format('HH'))) {
      if (hour == 0) {
        this.minutesRangeEndTime = [0, 20, 40];
      } else if (hour <= 30) {
        this.minutesRangeEndTime = [30];
      } else {
        this.minutesRangeEndTime = [0, 20, 40];
      }
    } else {
      this.minutesRangeEndTime = [0, 20, 40];
    }
  }

  @Watch('formCreate.date')
  private changeDate(value) {
    if (moment().format('YYYY-MM-DD') === moment(value).format('YYYY-MM-DD')) {
      const minutes = parseInt(moment().format('mm'));
      const hour = parseInt(moment().format('HH'));

      if (minutes == 0) {
        this.hourRange = this.range(hour, 23);
        this.minutesRangeStartTime = [0, 20, 40];
        this.minutesRangeStartTime = [0, 20, 40];
        this.formCreate.start_time = `${(`0${hour}`).slice(-2)}:00`;
        this.formCreate.end_time = `${(`0${hour}`).slice(-2)}:20`;
      } else if (minutes <= 30) {
        this.hourRange = this.range(hour, 23);
        this.minutesRangeStartTime = [40];
        this.minutesRangeEndTime = [40];
        this.formCreate.start_time = `${(`0${hour}`).slice(-2)}:40`;
        this.formCreate.end_time = `${(`0${hour + 1}`).slice(-2)}:00`;
      } else {
        this.hourRange = this.range(hour + 1, 23);
        this.minutesRangeStartTime = [0, 20, 40];
        this.minutesRangeEndTime = [0, 20, 40];
        this.formCreate.start_time = `${(`0${hour + 1}`).slice(-2)}:00`;
        this.formCreate.end_time = `${(`0${hour + 1}`).slice(-2)}:20`;
      }
    } else {
      this.hourRange = this.range(0, 23);
      this.minutesRangeStartTime = [0, 20, 40];
      this.minutesRangeEndTime = [0, 20, 40];
      this.formCreate.start_time = '00:00';
      this.formCreate.end_time = '00:00';
    }
  }

  private range(start, end) {
    const results: any = [];
    for (let i = start; i <= end; i++) {
      results.push(i);
    }
    return results;
  }

  private showCreateShift() {
    this.$modal.show('formCreateShift');
  }

  private hiddenCreateShift() {
    this.resetFormCreate();
    this.$modal.hide('formCreateShift');
  }

  private async fetchData() {
    try {
      const result = await ApiHelper.getApi('DoctorApi').list();

      this.doctorOptions = result.data.map((item) => ({
        name: item.name,
        value: item.id,
      }));
    } catch (e) {
      Toast.error(e.response.data.message);
    }
  }

  private get currentDoctor() {
    return this.$route.params.id;
  }

  private async createShift() {
    const isValid = await (this.$refs.formCreateShift as any).validate();
    if (!isValid) {
      return;
    }

    await ApiHelper.getApi('DoctorShiftApi')
        .create(this.formCreate).then(() => {
          this.hiddenCreateShift();
          this.fetchDataShifts();
        }).catch((error) => {
          if (error.response.status === 422) {
            (this.$refs.formCreateShift as any).setErrors(error.response.data.errors);
          }
          if (error.response.status === 400) {
            this.hiddenCreateShift();
            Toast.error(error.response.data.message);
          }
        });
  }

  private resetFormCreate() {
    this.formCreate = {
      doctor_id: this.currentUser.role === Roles.doctor ? this.currentUser.id : null,
      date: null,
      start_time: '00:00',
      end_time: '00:00',
    };
    this.isCheckStartEndTIme = false;
  }

  private async fetchDataShifts() {
    try {
      this.events = [];
      const shifts = (await ApiHelper.getApi('DoctorShiftApi').list({...this.searchOptions, with: 'doctor'})).data;
      this.events = shifts.map((item) => {
        return {
          id: item.id,
          doctor_id: item.doctor_id,
          current_date: item.date,
          start_time: moment(item.start_time, 'HH:mm:ss').format('HH:mm'),
          end_time: moment(item.end_time, 'HH:mm:ss').format('HH:mm'),
          title: item.doctor.name,
          start: `${item.date} ${moment(item.start_time, 'HH:mm:ss').format('HH:mm')}`,
          end: `${item.date} ${moment(item.end_time, 'HH:mm:ss').format('HH:mm')}`,
          textColor: '#fff',
          backgroundColor: `#${item.doctor.color}`,
          borderColor: `#${item.doctor.color}`,
        };
      });
    } catch (e) {
      Toast.error(e.response.data.message);
    }
  }
  private notAfterToday(date) {
    return date < moment().clone().startOf('date');
  }
  private handleRedirect() {
    let routeParams = {};
    if (this.currentUser.isDoctorRole) {
      routeParams = {
        name: 'doctors-detail',
        id: this.currentDoctor,
      };
    } else {
      routeParams = {
        name: 'doctors',
      };
    }
    return routeParams;
  }
}

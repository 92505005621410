






















import { mapState } from 'vuex';
import { Roles } from '@/common/app.config';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  computed: {
    ...mapState('auth', ['currentUser']),
  },
})
export default class BasicModal extends Vue {
  @Prop({ default: 0 }) readonly pageCount!: number;
  @Prop({ default: 1 }) readonly page!: number;
  @Prop({ default: false }) readonly isDisabled!: boolean;

  currentUser: any;

  pageChanged(pageNum) {
    this.$emit('pageChanged', pageNum);
  }

  isNewUI() {
    if (!this.currentUser) {
      return true;
    }
    return (
      this.currentUser.role === Roles.pharmacist ||
      this.currentUser.role === Roles.pharmacy
    );
  }

  get containerClass() {
    return this.isDisabled ? 'pagination disabled' : 'pagination';
  }
}

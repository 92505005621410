import { Deserializable } from '@common/interfaces/deserializable';
import moment from 'moment';
import { pickBy, identity } from 'lodash';

export interface OrderSearchInput {
  orderId: string;
  orderNumber: string;
  planName: string;
  userName: string;
  totalBasePrice: string | null;
  date_lte: string;
  date_gte: string;
  status: string | null;
}

export interface OrderSearchParseInput {
  id: string | null;
  payment_method_id: string | null;
  delivery_state: string;
  plan_id: string;
  paid_at_from: string;
  paid_at_to: string;
  order_number: string;
  status: string;
  plan_name: string;
  total_base_price: string;
  user_name: string;
  created_at_from: string;
  created_at_to: string;
  clinic_name: string;
}

export class OrderSearch
  implements Deserializable<OrderSearch>, OrderSearchInput {
  orderId: string;
  orderNumber: string;
  planName: string;
  userName: string;
  totalBasePrice: string | null = null;
  paymentMethodId: string | null = null;
  deliveryState: string | null = null;
  paidAtFrom: string;
  paidAtTo: string;
  planId: string | null = null;
  date_gte: string;
  date_lte: string;
  status: string | null = null;
  clinicName: string | null = null;

  deserialize(input: Partial<OrderSearchInput>): OrderSearch {
    Object.assign(this, input);

    return this;
  }

  parse(input: Partial<OrderSearchParseInput>): OrderSearch {
    const parseInput = pickBy({
      orderId: input.id,
      orderNumber: input.order_number,
      planName: input.plan_name,
      userName: input.user_name,
      totalBasePrice: input.total_base_price,
      paymentMethodId: parseInt(input.payment_method_id as string) || null,
      deliveryState: input.delivery_state || null,
      paidAtFrom: input.paid_at_from || null,
      paidAtTo: input.paid_at_to || null,
      planId: parseInt(input.plan_id as string) || null,
      date_gte: input.created_at_from || null,
      date_lte: input.created_at_to || null,
      status: input.status || null,
      clinicName: input.clinic_name || null,
    }, identity);
    Object.assign(this, { ...parseInput });

    return this;
  }

  toSearchJSON() {
    return {
      id: this.orderId,
      payment_method_id: this.paymentMethodId,
      delivery_state: this.deliveryState,
      plan_id: this.planId,
      paid_at_from: this.paidAtFrom,
      paid_at_to: this.paidAtTo,
      order_number: this.orderNumber,
      status: this.status,
      plan_name: this.planName,
      total_base_price: this.totalBasePrice,
      user_name: this.userName,
      created_at_from:
        this.date_gte && moment(this.date_gte).format('YYYY-MM-DD HH:mm:00'),
      created_at_to:
        this.date_lte && moment(this.date_lte).format('YYYY-MM-DD HH:mm:59'),
      clinic_name: this.clinicName,
    };
  }
}





































































































































































































































































































































































import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import { getFormData } from '@/common/utils';
import DragDropFileUploadLp from '@/components/common/DragDropFileUploadLp.vue';
import moment from 'moment';
import ApiHelper from 'api-helper';
import { State } from 'vuex-class';
import Toast from '@common/helpers/toast';
import { Delivery } from '@/models/deliveries';
import { DELIVERY_STATUS, DELIVERY_STATUS_NAME } from '@/common/constants/auth';
import { PLAN_PRODUCT_UNITS } from '@/common/constants/plan';
import { Roles, DeliveryState, formatDateJp } from '@common/app.config';
import jaLangDatePicker from 'vuejs-datepicker/dist/locale/translations/ja';
// Components
import BasicSelect from '@/components/common/BasicSelect.vue';
import BaseDatePicker from '@/components/common/BaseDatePicker.vue';
import { User } from '@/models/user';
import PageLoader from '@components/common/PageLoader.vue';
import { pickBy, identity } from 'lodash';

@Component({
  components: {
    BasicSelect,
    BaseDatePicker,
    DragDropFileUploadLp,
    PageLoader,
  },
  filters: {},
})
export default class DetailDelivery extends Vue {
  get queryParams() {
    return {
      with: 'order,user,order.plan,order.plan.medicines,image,medicines',
    };
  }

  get isPermission() {
    return (
      this.currentUser.isSuperAdminRole ||
      this.currentUser.isCSRole ||
      this.currentUser.isPharmacistRole ||
      this.currentUser.isAdminRole ||
      this.currentUser.isDeliveryRole
    );
  }

  get isDeliveryRole() {
    return this.isPermission;
  }

  get isEditDeliveryState() {
    if (this.isDeliveryRole) {
      return this.deliveryStateOptionsDeliveryRole.some(
        (x) => x.value === this.deliveryDetail.state,
      );
    }
    return this.isEditableRole;
  }

  get isNotDelivered() {
    return this.deliveryDetail.state !== DELIVERY_STATUS.DELIVERED;
  }

  get deliveryStateOptionsDeliveryRole() {
    const showState = [
      DELIVERY_STATUS.SCHEDULED_DELIVERY,
      DELIVERY_STATUS.DELIVERED,
      DELIVERY_STATUS.READY_FOR_DELIVERY,
      DELIVERY_STATUS.PREPARING_FOR_DELIVERY,
      DELIVERY_STATUS.PENDING,
    ];
    return DeliveryState.filter((x: any, i) => showState.includes(x.value));
  }

  get deliveryStateOptions() {
    if (this.isDeliveryRole && this.isEditDeliveryState) {
      return this.deliveryStateOptionsDeliveryRole;
    }
    return DeliveryState.filter((x, i) => i !== 0);
  }

  get isMatchDate() {
    if (!this.deliveryDetail.send_at || !this.deliveryDetail.complete_at) {
      return true;
    }
    if (
      moment(this.deliveryDetail.send_at).diff(
        moment(this.deliveryDetail.delivery_date),
        'days',
      ) < 0
    ) {
      return true;
    }
    if (
      moment(this.deliveryDetail.complete_at).diff(
        moment(this.deliveryDetail.send_at),
        'days',
      ) < 0
    ) {
      return true;
    }
    return false;
  }

  get isEmptyDataMedicine(): boolean {
    return this.dataMedicines && !this.dataMedicines.length;
  }

  private get deliveryForm() {
    return this.$refs.deliveryForm as any;
  }

  public get errorMessages() {
    return {
      required: '※入力してください。',
    };
  }

  public get dateErrorMessages() {
    return {
      required: '※選択してください。',
    };
  }

  @State((state) => state.auth.currentUser) currentUser!: User;
  public shipDate = moment().format('YYYY-MM-DD');
  public shipDoneDate = moment().format('YYYY-MM-DD');
  public query = {
    resv_start_at_gte: '',
    resv_end_at_lte: '',
    upload_date: '',
    freeword: null,
  };
  public isLoading: boolean = false;
  public isSubmiting = false;
  public deliveryDetail: any = {};
  public deliveryUser = {};
  public currentQuery: any = {};
  public isStop: boolean = false;
  public isCancel: boolean = false;
  public isChanged: boolean = false;
  public jaLangDatePicker: any = jaLangDatePicker;
  public formatDateJapan = formatDateJp;
  public dataMedicines = [];

  mounted() {
    window.scrollTo(0, 0);
    this.getDeliveryDetail();
  }

  updateIsSubmiting(isSubmiting: boolean) {
    this.isSubmiting = isSubmiting;
  }

  updateIsChanged(status: boolean) {
    this.isChanged = status;
  }

  isEditableRole(withDelivery: false, withMedicine: false) {
    if (withDelivery) {
      return (
        this.currentUser.isSuperAdminRole ||
        this.currentUser.isCSRole ||
        this.currentUser.isAdminRole ||
        this.currentUser.isDeliveryRole ||
        this.currentUser.isPharmacistRole
      );
    }

    if (withMedicine) {
      return this.currentUser.isPharmacistRole;
    }

    return this.currentUser.isSuperAdminRole || this.currentUser.isCSRole || this.currentUser.isAdminRole;
  }
  getDeliveryDetail() {
    if (this.isLoading) {
      return;
    }

    this.deliveryDetail = [];

    this.isLoading = true;

    ApiHelper.getApi('DeliveryApi')
      .getDeliveryDetail(this.$route.params.id, this.queryParams)
      .then((res: any) => {
        this.deliveryDetail = res;
        this.deliveryDetail.image = res?.image?.url;
        this.isStop = res.state === 'cancel';
        this.isCancel = [
          'paid',
          'preparing_for_delivery',
          'delivered',
          'delivery_complete',
          'ready_for_delivery',
        ].includes(res.state);
        this.deliveryUser = res.user;
        this.dataMedicines = res.order.plan.medicines.map((medicine) => {
          const medicineRelation = res.medicines.find(
            (data) => data.medicine_id === medicine.id,
          );
          return {
            id: medicine.id,
            name: medicine.name,
            quantity: medicine.pivot.quantity,
            unit: medicine.pivot.unit,
            lot_no: medicineRelation?.lot_no,
            deadline: medicineRelation?.deadline,
          };
        });
      })
      .catch((error: any) => {
        Toast.error(error.response.data.message);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
  disableUpdateDelivery() {
    return this.isSubmiting || !this.isChanged;
  }
  async updateDelivery() {
    const isValid = await this.deliveryForm.validate();
    if (!isValid) {
      return;
    }
    const queryData = {
      state: this.deliveryDetail.state,
      delivery_date: this.deliveryDetail.delivery_date
        ? moment(this.deliveryDetail.delivery_date).format('YYYY-MM-DD')
        : null,
      delivery_number: this.deliveryDetail.delivery_number,
      send_at: this.deliveryDetail.send_at
        ? moment(this.deliveryDetail.send_at).format('YYYY-MM-DD')
        : null,
      complete_at: this.deliveryDetail.complete_at
        ? moment(this.deliveryDetail.complete_at).format('YYYY-MM-DD')
        : null,
      image: this.deliveryDetail.file,
    };

    const formData = getFormData(queryData);
    this.dataMedicines.map((value: any) => {
      if (value.lot_no) {
        formData.append(`medicines[${value.id}][lot_no]`, value.lot_no);
      }
      if (value.deadline) {
        formData.append(`medicines[${value.id}][deadline]`, value.deadline);
      }
      return value;
    });
    this.updateIsSubmiting(true);
    this.updateIsChanged(true);
    ApiHelper.getApi('DeliveryApi')
      .updateDelivery(this.$route.params.id, formData)
      .then((res: any) => {
        this.currentQuery = queryData;
        this.deliveryDetail = {} as any;
        this.getDeliveryDetail();
        Toast.success('更新しました');
      })
      .catch((error: any) => {
        Toast.error(error.response.data.message);
      })
      .finally(() => {
        this.isLoading = false;
        this.updateIsChanged(false);
        this.updateIsSubmiting(false);
      });
  }
  createNumberRegExp() {
    return new RegExp(`[0-9]{5}-[0-9]{5}`);
  }

  public changeFile(event) {
    const eventData = Object.assign(event);
    this.$set(this.deliveryDetail, 'file', event);
    if (typeof event === 'object') {
      const reader = new FileReader();
      this.$set(this.deliveryDetail, 'image', event);
      reader.onload = (event: any) => {
        this.$set(this.deliveryDetail, 'image', event.target.result);
      };
      reader.readAsDataURL(event);
    }

    return {
      field: 'image',
      value: eventData,
    };
  }

  deleteFile() {
    if (this.currentUser.isPharmacistRole) {
      this.$set(this.deliveryDetail, 'image', null);
    }
  }

  get isFileUploadDisabled() {
    return !this.currentUser.isPharmacistRole;
  }

  public clearDate(name) {
    this.deliveryDetail[name] = '';
  }

  public deliveryStatus(status) {
    return DELIVERY_STATUS_NAME[status];
  }

  public notAfterEndDay(date) {
    if (this.deliveryDetail.delivery_date) {
      return (
        date > moment(this.deliveryDetail.delivery_date).clone().startOf('date')
      );
    }
    return true;
  }

  public notBeforeStartDay(date) {
    if (this.query.resv_end_at_lte) {
      return date > moment(this.query.resv_end_at_lte!).clone().startOf('date');
    }
  }

  public deliveryDate(date) {
    return moment(date).format('YYYY年 MM月 DD日');
  }

  public goBack() {
    const queryParams = pickBy({
      id: this.$route.query.id || '',
      page: this.$route.query.page || '1',
      user_name: this.$route.query.user_name || '',
      clinic_name: this.$route.query.clinic_name || '',
      plan_name: this.$route.query.plan_name || '',
      delivery_date_from: this.$route.query.delivery_date_from || '',
      delivery_date_to: this.$route.query.delivery_date_to || '',
      plan_id: this.$route.query.plan_id || '',
    }, identity);

    this.$router.push({
      path: `/deliveries`,
      query: {
        ...queryParams,
        state: this.$route.query.state || '',
      },
    });
  }

  public goDeliveryMedicines() {
    window.open(`/deliveries/${this.deliveryDetail.id}/medicines`, '_blank');
  }

  public stopDelivery() {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    ApiHelper.getApi('UserApi')
      .updateDeliveryStatus(this.deliveryDetail.id, {
        state: 'cancel',
      })
      .then((res: any) => {
        this.isLoading = false;
        this.isStop = true;
        this.deliveryDetail.state = 'cancel';
        Toast.success('更新しました');
      })
      .catch((error: any) => {
        Toast.error(error.response.data.message);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  public getUnitName(value: string) {
    return PLAN_PRODUCT_UNITS.find((unit) => unit.value === value)?.name;
  }

  @Watch('deliveryDetail', { deep: true })
  public watchDeliveryDetail(_, oldValue) {
    if (this.isChanged || !oldValue.id) {
      return;
    }
    this.updateIsChanged(true);
  }

  @Watch('dataMedicines', { deep: true })
  public watchDataMedicines(_, oldValue) {
    if (this.isChanged || !oldValue.length) {
      return;
    }
    this.updateIsChanged(true);
  }
}














































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import PageLoader from '@components/common/PageLoader.vue';
import LocalStorageUtils from '@common/LocalStorageUtils';
import { State } from 'vuex-class';
import { User } from '@/models/user';

@Component({
  components: {
    PageLoader,
  },
})
export default class PopupConfirmPlan extends Vue {
  @Prop({
    default() {
      return [];
    },
  })
  public planList!: any[];
  @Prop({
    default() {
      return [];
    },
  })
  public selectedPlans!: any[];
  @Prop({ default: '' }) public roleSelected!: string;
  @Prop({ default: false }) public multiple!: boolean;

  @State((state) => state.auth.currentUser) currentUser!: User;

  isLoading: boolean = false;
  listActivePlan: any[] = [];
  listSelectedPlanIds: number[] = [];
  searchPlan: string = '';

  get isEmptyDataClinic(): boolean {
    return this.listActivePlan && !Object.values(this.listActivePlan).length;
  }

  async mounted() {
    this.remoteParamSearch();
  }

  handleChangeSelect(planId, index) {
    // pop
    this.listSelectedPlanIds = this.listSelectedPlanIds.filter(function(item) {
      return item !== planId;
    });

    // push at index
    this.listSelectedPlanIds.splice(index - 1, 0, planId);
  }

  beforeOpen() {
    this.initDataDefault();

    // clear search
    this.searchPlan = '';
    this.listActivePlan = this.planList;

    // order by id
    this.listActivePlan.sort((a, b) => a.id - b.id);

    // populate this.listSelectedPlanIds
    this.selectedPlans.forEach((element) => {
      if (element.display_on_lp) {
        this.listSelectedPlanIds[element.display_priority - 1] = element.id;
      }
    });

    this.remoteParamSearch();
  }

  remoteParamSearch() {
    if (LocalStorageUtils.getItem('doctorDetailSearchClinic')) {
      LocalStorageUtils.removeItem('doctorDetailSearchClinic');
    }
  }

  initDataDefault() {
    // Clear data when close popup
    this.listActivePlan = [];
    this.listSelectedPlanIds = [];
  }
  cancelSetPlan() {
    this.initDataDefault();
    this.$emit('cancel');
  }

  handleCheckboxSelect(event) {
    const planId = Number(event.target.value);
    if (event.target.checked) {
      this.listSelectedPlanIds.push(planId);
    } else {
      this.listSelectedPlanIds = this.listSelectedPlanIds.filter(function(
        item,
      ) {
        return item !== planId;
      });
    }
  }

  searchByName() {
    this.isLoading = true;
    this.listActivePlan = this.planList.filter((plan) =>
      plan.name.toLowerCase().includes(this.searchPlan.toLowerCase()),
    );
    this.isLoading = false;
  }

  handleSubmit() {
    this.$emit('submit', this.listSelectedPlanIds);
    this.$emit('cancel');
  }
}

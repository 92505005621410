import CreateEdit from '@/views/medicine/create-edit/CreateEdit.vue';
import Detail from '@/views/medicine/Detail.vue';
import Confirm from '@/views/medicine/Confirm.vue';
import HomeMedicine from '@/views/medicine/HomeMedicine.vue';
import Create from '@/views/medicine/Create.vue';
import { authAdmin, authSAdmin } from '@/common/constants/auth';

export default [
  {
    path: '/medicine',
    name: 'medicine',
    component: HomeMedicine,
    meta: authSAdmin,
  },
  {
    path: '/medicine/create',
    name: 'medicine.create',
    component: Create,
    meta: authAdmin,
  },
  {
    path: '/medicine/detail/:id',
    name: 'medicine.detail',
    component: Detail,
    meta: authSAdmin,
  },
  {
    path: '/medicine/confirm',
    name: 'medicine.confirm',
    component: Confirm,
    meta: authAdmin,
  },
  {
    path: '/medicine/edit/:id',
    name: 'medicine.edit',
    component: CreateEdit,
    meta: authAdmin,
  },
];

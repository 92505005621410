export const PLAN_TYPE = [
  {
    name: '全て',
    value: null,
  },
  {
    name: '無効',
    value: 'inactive',
  },
  {
    name: '有効',
    value: 'active',
  },
  {
    name: '削除済み',
    value: 'delete',
  },
];

export const PLAN_PAYMENT_TYPE = {
  PAYMENT_TYPE_REGULAR: 1,
  PAYMENT_TYPE_ONE_TIME: 2,
};

export const PLAN_PAYMENT_TYPE_NAME = {
  [PLAN_PAYMENT_TYPE.PAYMENT_TYPE_REGULAR]: '定期',
  [PLAN_PAYMENT_TYPE.PAYMENT_TYPE_ONE_TIME]: '単位',
};

export const PLAN_PRODUCT_UNITS = [
  {
    name: '錠',
    value: 'PILL',
  },
  {
    name: '包',
    value: 'CAP',
  },
  {
    name: '個',
    value: 'PIECES',
  },
  {
    name: '本',
    value: 'TUBE',
  },
];

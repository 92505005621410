var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"patient-control patient-control-content patient-control-margin"},[_c('div',{staticClass:"treatment-info"},[_c('p',{staticClass:"treatment-info__name"},[_vm._v("診療科目："+_vm._s(_vm.treatmentName))]),_c('p',{staticClass:"treatment-info__interview-date"},[_vm._v(" 回答日："+_vm._s(_vm._f("format_time")(_vm.interviewDate,_vm.formatDateJp, '-'))+" ")])]),(_vm.patientAnswer && _vm.patientAnswer.length > 0)?_c('div',_vm._l((_vm.patientAnswer),function(data,index){return _c('div',{key:data.id,staticClass:"patient-control-question"},[_c('div',{staticClass:"d-flex"},[_c('p',{staticClass:"patient-control-question-first"},[_vm._v("Q"+_vm._s(index + 1))]),_c('p',{staticClass:"patient-control-question-second pre-wrap"},[_vm._v(" "+_vm._s(data.question.question)+" ")])]),_c('div',{staticClass:"d-flex"},[_c('p',{staticClass:"patient-control-question-first question-answer"},[_vm._v("回答")]),(
            data.question.type === 'radio' ||
            data.question.type === 'checkbox'
          )?_c('div',{staticClass:"patient-control-question-second pre-wrap"},_vm._l((data.answer),function(ans,index){return _c('span',{key:index},[_c('span',{staticClass:"mr-3"},[_vm._v(_vm._s(ans.value))])])}),0):_vm._e(),(
            data.question.type === 'textarea' ||
            data.question.type === 'input'
          )?_c('div',{staticClass:"patient-control-question-second pre-wrap"},[_c('div',[_c('div',{staticClass:"patient-control-question-second pre-wrap"},_vm._l((data.answer),function(ans){return _c('span',{key:ans.id},[_c('span',{staticClass:"mr-3"},[_vm._v(_vm._s(ans.value))])])}),0)])]):_vm._e()])])}),0):_vm._e(),(_vm.currentUser.isDoctorRole)?_c('div',{staticClass:"patient-control-question-footer"},[_c('p',{staticClass:"text-center patient-control-title mt-2"},[_vm._v(_vm._s(_vm.stateNotice))]),_c('div',{staticClass:"patient-control-button-status"},[_c('button',{staticClass:"btn btn-primary btn-w-166 btn-md",class:{
          'action-disabled': _vm.isDisabledAfterChoose(
            _vm.USER_TREATMENT_STATE.CHAT_INTERVIEW_SCHEDULED
          ),
        },attrs:{"disabled":_vm.isDisableInterview || _vm.isChangeState},on:{"click":function($event){return _vm.onChangeUserStatus(_vm.USER_TREATMENT_STATE.CHAT_INTERVIEW_SCHEDULED)}}},[_vm._v(" チャット診療 ")]),_c('button',{staticClass:"btn btn-primary btn-w-166 btn-md",class:{
          'action-disabled': _vm.isDisabledAfterChoose(
            _vm.USER_TREATMENT_STATE.VIDEO_INTERVIEW_SCHEDULED
          ),
        },attrs:{"disabled":_vm.isDisableInterview || _vm.isChangeState || _vm.isDisableVideoCall},on:{"click":function($event){return _vm.onChangeUserStatus(_vm.USER_TREATMENT_STATE.VIDEO_INTERVIEW_SCHEDULED)}}},[_vm._v(" ビデオ問診 ")]),_c('button',{staticClass:"btn btn-info btn-w-166 btn-md",class:{
          'action-disabled': _vm.isDisabledAfterChoose(
            _vm.USER_TREATMENT_STATE.CONFIRMING_PRESCRIPTION
          ),
        },attrs:{"disabled":_vm.isDisableInterview || _vm.isChangeState},on:{"click":function($event){return _vm.openForm()}}},[_vm._v(" 処方する ")]),_c('button',{staticClass:"btn btn-danger btn-w-166 btn-md",class:{
          'action-disabled': _vm.isDisabledAfterChoose(
            _vm.USER_TREATMENT_STATE.SUBSCRIPTION_SUGGESTIONS
          ),
        },attrs:{"disabled":_vm.isDisableInterview || _vm.isChangeState},on:{"click":function($event){return _vm.onChangeUserStatus(_vm.USER_TREATMENT_STATE.SUBSCRIPTION_SUGGESTIONS)}}},[_vm._v(" 処方NG ")])]),_c('div',{staticClass:"patient-control-view-button"},[_c('div',{staticClass:"patient-control-view-double"},[_c('button',{staticClass:"btn btn-danger  btn-w-166 btn-md",class:{
            'action-disabled' : _vm.isChangeState || _vm.checkDoctorReserve
          },attrs:{"disabled":_vm.isChangeState || _vm.checkDoctorReserve},on:{"click":function($event){return _vm.onChangeUserStatus(_vm.USER_TREATMENT_STATE.DOCTOR_CANCEL_PRESCRIPTION)}}},[_vm._v(" 処方キャンセル ")]),_c('button',{staticClass:"btn btn-info  btn-w-166 btn-md",class:{'action-disabled' : _vm.isChangeState || _vm.checkButtonReserve || _vm.checkDoctorReserve},attrs:{"disabled":_vm.isChangeState || _vm.checkButtonReserve || _vm.checkDoctorReserve},on:{"click":function($event){return _vm.onChangeUserStatus(_vm.USER_TREATMENT_STATE.DOCTOR_RESERVE_PRESCRIPTION)}}},[_vm._v(" 処方保留 ")])])])]):_vm._e(),_c('div',{staticClass:"fixed-button"},[_c('button',{staticClass:"btn btn-primary",class:{
        'action-disabled': _vm.isDateTimePickerDisabled,
      },attrs:{"disabled":_vm.isDateTimePickerDisabled || _vm.isDateTimePickerWhenUserAcceptDisabled},on:{"click":_vm.openDateTimePicker}},[_vm._v(" ビデオ診察の日程を指定する ")])]),_c('div',{staticClass:"fixed-button-url"},[_c('button',{staticClass:"btn btn-primary",class:{
        'action-disabled': _vm.isGenerateLinkDisabled,
      },attrs:{"disabled":_vm.isGenerateLinkDisabled},on:{"click":_vm.generateLink}},[_vm._v(" ビデオ診察の日程調整URLを発行する ")])]),_c('GenerateLinkModal',{attrs:{"isVisible":_vm.isModalVisible,"generatedLink":_vm.generatedLink},on:{"closeModal":_vm.closeModal}}),_c('DateTimePickerPopup',{attrs:{"isVisible":_vm.isDateTimePickerVisible,"suggestReservationDate":_vm.reservationDate},on:{"closeDateTimePicker":_vm.closeDateTimePicker,"confirm":_vm.handleDateTimeSelection}}),_c('PrescriptionPopup',{attrs:{"current-channel-user":_vm.currentPatient,"changeState":_vm.changeState},on:{"submit":_vm.handleWhenCreateFeedback}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
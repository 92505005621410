
























































































import { Vue, Component } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faPlus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { pickBy, identity } from 'lodash';

// Utils
import Toast from '@common/helpers/toast';
import LocalStorageUtils from '@common/LocalStorageUtils';
import ApiHelper from 'api-helper';
// Components
import Pagination from '@components/common/Pagination.vue';
import PageLoader from '@components/common/PageLoader.vue';
import ContentLayout from '@components/layouts/Content.vue';
// Constants
import { Roles } from '@/common/app.config';
// Icons
import Listicon from '@/assets/img/icon-medicine.svg';

@Component({
  components: {
    Pagination,
    PageLoader,
    ContentLayout,
  },
  data() {
    return {
      listMedicine: {},
      iconHeader: Listicon,
    };
  },
})
export default class Index extends Vue {
  @State((state) => state.auth.currentUser) currentUser!: any;
  public iconHeader: any;
  public page = parseInt(this.$route.query.page as string) || 1;
  public perPage = 10;
  public pageCount = 1;
  public listMedicine!: any;
  public isLoading: boolean = false;

  get isSuperAdminRole() {
    return this.currentUser.isSuperAdminRole;
  }

  public searchForm = {
    name: this.$route.query.name || '',
    per_page: this.perPage,
    page: this.page,
  };

  public searchMedicine() {
    LocalStorageUtils.saveItem('paramsSearch', JSON.stringify(this.searchForm));

    this.searchForm.page = 1;
    this.page = 1;
    this.getMedicineList(this.searchForm);
  }

  public pageChanged(page) {
    let paramsSearch: any;

    if (LocalStorageUtils.getItem('paramsSearch')) {
      paramsSearch = JSON.parse(LocalStorageUtils.getItem('paramsSearch'));
    } else {
      paramsSearch = Object.assign({}, this.searchForm);
      paramsSearch.name = '';
    }

    paramsSearch.page = page;
    this.page = page;
    this.getMedicineList(paramsSearch);
  }

  public gotoDetail(id) {
    const queryParams = pickBy(
      { ...this.$route.query },
      identity,
    );
    this.$router.push({ path: `/medicine/detail/${id}`, query: queryParams });
  }

  private async created() {
    if (LocalStorageUtils.getItem('paramsSearch')) {
      LocalStorageUtils.removeItem('paramsSearch');
    }

    this.getMedicineList(this.searchForm);
  }

  private mounted() {
    library.add(faPlus);
    library.add(faPlusCircle);
    dom.watch();
  }

  private async getMedicineList(data) {
    try {
      this.isLoading = true;
      this.setUrlWithQuery(data);
      const res = await ApiHelper.getApi('MedicineApi').fetchMedicineList(data);
      this.listMedicine = res.data;
      this.pageCount = res.last_page;
      this.isLoading = false;
    } catch (error) {
      this.toast(error);
      this.isLoading = false;
    }
  }

  private toast(error: any) {
    const {
      errors: { id: idError },
    } = error.response.data;
    if (idError) {
      return Toast.error('英数字で入力してください。');
    }
    Toast.error(error.response.data.message);
  }

  private setUrlWithQuery(data) {
    const queryParams = pickBy(
      { ...this.$route.query, ...data },
      identity,
    );

    this.$router.replace({
      path: this.$route.path,
      query: queryParams,
    });
  }
}

import { Deserializable } from '@/common/interfaces/deserializable';
import { Medicine } from './medicine';
import { getFormData } from '@/common/utils';
import { Regexs } from '@/common/constants/regex';
import { ModelClinic } from './clinics';
import { Menu } from './menu';

export interface PlanInput {
  id?: number;
  name?: string;
  amount?: any;
  status?: number;
  about_plan?: string;
  image?: string;
  image_url?: string;
  medicines?: Medicine[];
  medicine_ids?: any[];
  created_at?: string;
  price_first?: number;
}

export interface QueryPlan {
  id?: number | null;
  page?: number;
  per_page?: number;
  status?: number;
  name?: string;
  freeword?: string;
  with?: string;
}

export interface PlanClinic {
  id: number;
  order?: number;
  price_first: number;
  name: string;
  display: boolean;
}

export class Plan implements Deserializable<Plan> {
  id?: number;
  name: string;
  amount?: any;
  status?: number = 1;
  about_plan?: string;
  medicines?: Medicine[];
  image?: string = '';
  image_url?: string = '';
  medicine_ids?: any[];
  created_at?: string;
  treatment_policy?: string;
  cycle?: number;
  price_first?: number;
  price_after?: number;
  payment_type?: number;
  display_on_lp: boolean;
  display_priority?: number;
  clinics?: ModelClinic[];
  menus?: Menu[];

  get nameLink() {
    return `${this.name} 薬`;
  }

  get amountCurrencyUnit() {
    return this.amount ? `${this.amountText} 円` : '';
  }

  get amountFormat() {
    return this.amount ? `${this.amountText} 円(税込)` : '';
  }

  get isActived() {
    return this.status;
  }

  get listMedicineName() {
    if (this.medicines) {
      const newArrayMedicineName = this.medicines.map((item) => item.name);
      return newArrayMedicineName.join(' / ');
    }
    return '';
  }

  get amountText() {
    return this.amount ? this.amount.toString().replace(Regexs.numberWithCommas, ',') : '';
  }

  get cycleString() {
    return `${this.cycle}日`;
  }

  get clinicName() {
    return (this.clinics && this.clinics[0] && this.clinics[0].name) ?? '-' ;
  }

  get menuName() {
    return (this.menus && this.menus[0] && this.menus[0].name) ?? '-';
  }

  get priceFirstString() {
    const formatter = new Intl.NumberFormat('ja-JP');
    return this.price_first ? `${formatter.format(this.price_first)}円` : `0円`;
  }

  get isShampooAdditional() {
    return this.id === 4;
  }

  deserialize(input: Partial<PlanInput>): Plan {
    Object.assign(this, input);
    return this;
  }

  formJSONData(isEdit: boolean) {
    const data: any = {
      name: this.name,
      amount: this.amount,
      about_plan: this.about_plan,
      status: this.status,
      medicine_ids: this.medicine_ids,
      _method: isEdit ? 'put' : 'post',
    };
    if (isEdit && !this.image) {
      data.image = null;
    }

    return data;
  }

  formJSONDataHaveImage(isEdit: boolean) {
    const data: any = {
      name: this.name,
      amount: this.amount,
      about_plan: this.about_plan,
      status: this.status,
      medicine_ids: this.medicine_ids,
      _method: isEdit ? 'put' : 'post',
    };

    if (this.image) {
      data.image = this.image;
    }

    const formData = getFormData(data);

    return formData;
  }
}






































import StockApi from '@/api/v2/StockApi';
import Listicon from '@/assets/img/plan-list.svg';
import PopupConfirmDeletion from '@/components/common/PopupConfirmDeletion.vue';
import { Stock } from '@/models/stock';
import Toast from '@common/helpers/toast';
import ContentLayout from '@components/layouts/Content.vue';
import ApiHelper from 'api-helper';
import cloneDeep from 'lodash/cloneDeep';
import { Component, Vue, Watch } from 'vue-property-decorator';
import StockForm from './components/StockForm.vue';
import { StockFormInterface } from './components/StockFormInterface';
import moment from 'moment';
import _ from 'lodash';

@Component({
  components: {
    ContentLayout,
    StockForm,
    PopupConfirmDeletion,
  },
})
export default class StockDetail extends Vue {
  iconHeader: any = Listicon;
  isSubmitting = false;
  stock: Stock = new Stock();
  isLoading: boolean = false;
  isChanged: boolean = false;

  selectedMonth: string =
    (this.$route.query.month as string) || moment().format('YYYY-MM');

  stockApi = ApiHelper.getApi('StockApi') as StockApi;

  dataForm: Partial<StockFormInterface> = {};

  get computedForm() {
    return this.$refs.dataForm as any;
  }

  get stockId() {
    return Number(this.$route.params.id);
  }

  created() {
    this.getStockDetail(true);
  }

  async getStockDetail(isFillAll = false) {
    try {
      this.isLoading = true;

      const res = await this.stockApi.getStockDetail(
        this.stockId,
        this.selectedMonth,
      );

      const stock = new Stock().deserialize(res);
      if (isFillAll) {
        this.stock = stock;
      } else {
        this.stock = {
          ...this.dataForm,
          stock_dailies: _.uniqBy(
            [...(this.dataForm.stock_dailies as []), ...stock.stock_dailies],
            'date',
          ),
        } as Stock;
      }
    } catch (error) {
      if (error.response) {
        Toast.error(error.response.data.message);
      }
    } finally {
      this.isLoading = false;
    }
  }

  handleChangeSelectedMonth(value) {
    this.selectedMonth = value;
    this.$router.replace({
      path: this.$route.path,
      query: { month: this.selectedMonth },
    });
  }

  handleChange(name, value) {
    this.$set(this.dataForm, name, value);
  }

  handleCancel() {
    const queryParams = _.pickBy({
      id: this.$route.query.id || '',
      medicine_name: this.$route.query.medicine_name || '',
      date: this.selectedMonth,
    }, _.identity);

    return this.$router.push({
      name: 'stocks',
      query: queryParams,
    });
  }

  confirmDeleteStock() {
    this.$modal.show('popupConfirmStock');
  }

  cancelPopupConfirmStock() {
    this.$modal.hide('popupConfirmStock');
  }

  async deleteStock() {
    try {
      this.isSubmitting = true;
      await ApiHelper.getApi('StockApi').delete(this.stockId);
      Toast.success('削除しました。');
      this.$router.push({ name: 'stocks' });
    } catch (error) {
      Toast.error('まだ削除できません。');
    } finally {
      this.$modal.hide('popupConfirmStock');
      this.isSubmitting = false;
    }
  }

  async handleSubmit() {
    const valid = await this.computedForm.validate();

    if (valid) {
      try {
        this.isSubmitting = true;
        await this.updateStockData();
        Toast.success('更新しました');
      } catch (error) {
        const data = error.response.data;
        Toast.error(data.message);
        if (error.response.status === 422) {
          this.computedForm.setErrors(error.response.data.errors);
          return;
        }
      } finally {
        this.isSubmitting = false;
        this.isChanged = false;
      }
    }
  }

  async updateStockData() {
    const data = cloneDeep({
      ...this.dataForm,
      _method: 'put',
    });

    await ApiHelper.getApi('StockApi').update(this.stockId, data);
  }

  @Watch('dataForm', { deep: true })
  watchDataForm(_, oldValue) {
    if (this.isChanged || !oldValue.medicine_id) {
      return;
    }
    this.isChanged = true;
  }

  @Watch('selectedMonth')
  async watchSelectedMonth() {
    await this.updateStockData();
    this.getStockDetail(true);
  }

  @Watch('stock')
  watchStock() {
    this.dataForm = {
      medicine_id: this.stock.medicine_id,
      unit: this.stock.unit,
      memo: this.stock.memo,
      stock_dailies: this.stock.stock_dailies,
    };
  }
}

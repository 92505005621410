import ListPatient from '@/views/patient/list/ListPatient.vue';
import HomeDetail from '@/views/patient/HomeDetail.vue';
import { authAdminDoctorClinic } from '@/common/constants/auth';
import Health from '@/views/patient/show/health/Health.vue';
import SubPatient from '@/components/Patient/SubPatient.vue';

export default [
  {
    path: '/patients',
    redirect: '/v2/patients',
    name: 'patients',
    component: ListPatient,
    meta: authAdminDoctorClinic,
  },
  {
    path: '/patients/health/:id',
    redirect: '/v2/patients/:id',
    name: 'patients.health',
    component: Health,
    meta: authAdminDoctorClinic,
  },
  {
    path: '/patients/detail/:id',
    redirect: '/v2/patients/:id',
    name: 'patients.detail',
    meta: authAdminDoctorClinic,
  },
  {
    path: '/patients/sub/:id',
    redirect: '/v2/patients/:id',
    name: 'patients.sub.detail',
    component: SubPatient,
    props: true,
    meta: authAdminDoctorClinic,
  },
];

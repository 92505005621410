import { RESERVATION_TYPE } from '@/common/app.config';

export const getFormData = (data: any, options = { checkEmpty: true }) => {
  const { checkEmpty } = options;
  const formData = new FormData();
  for (const key in data) {
    if (checkEmpty && [null, undefined, ''].includes(data[key])) {
      continue;
    } else if (Array.isArray(data[key])) {
      data[key].forEach((x: any) => {
        formData.append(`${key}[]`, x);
      });
    } else {
      formData.append(key, data[key]);
    }
  }
  return formData;
};

export const autoDeletePropertyEmpty = ( object: any ) => {
  const data = {};
  for (const key in object) {
    if (!['', null, undefined].includes(object[key])) {
      data[key] = object[key];
    }
  }
  return data;
};

export const getReservationType = (type: number | null): { id?: number; text?: string } => {
  return RESERVATION_TYPE.find((x) => x.id === type) || {};
};

import { Deserializable } from '@common/interfaces/deserializable';
import { VerifyTypeBoolean } from '@/common/constants/patient';

export interface ModelCity {
  code: string;
  en_name: string;
  id: number | null;
  ja_name: string;
  name_kana: string;
  prefecture_id: number | null;
  zh_name: string;
}

export interface ModelPrefecture {
  en_name: string;
  id: number | null;
  ja_name: string;
  name_kana: string;
  zh_name: string;
}

export interface Patient {
  id: number | null;
  name: string;
}

export interface ModelPatient {
  id: number | null;
  city_id: number | null;
  prefecture_id: number | null;
  city: ModelCity;
  prefecture: ModelPrefecture;
  address: string | null;
  avatar: string | null;
  avatar_url: string;
  created_at: string;
  date_of_birth: string;
  email: string;
  gender: boolean;
  height: string;
  is_verified: boolean;
  name: string;
  name_kana: string;
  note: string | null;
  phone: string | null;
  postal_number: number | null;
  reservationUser: any[];
}

export class Patient implements Deserializable<Patient>, ModelPatient {
  id: number | null;
  city_id: number | null;
  prefecture_id: number | null;
  city: ModelCity;
  prefecture: ModelPrefecture;
  address: string | null;
  avatar: string | null;
  avatar_url: string;
  created_at: string;
  date_of_birth: string;
  email: string;
  gender: boolean;
  height: string;
  is_verified: boolean;
  name: string;
  family_name: string;
  given_name: string;
  name_kana: string;
  family_name_kana: string;
  given_name_kana: string;
  gender_id: string;
  building_name: string;
  state: string;
  os_type: string;
  otp_verified_at: string;
  noted_at: string;
  note: string | null;
  phone: string | null;
  postal_number: number | null;
  reason: string;
  back_side_card: string;
  back_side_card_url: string;
  front_side_card: string;
  front_side_card_url: string;
  admin_review_at: string;
  is_review_valid: boolean;
  status_review: number;
  latestReservation: any;
  reservationUser: any[];
  order_first: any[];
  clinics: any[];
  new_state: string;
  addresses: any[];

  get isVerifyName() {
    if (!this.back_side_card && !this.front_side_card && !this.admin_review_at) {
      return '未提出';
    }
    if ((this.back_side_card || this.front_side_card) && !this.is_review_valid) {
      return '未審査';
    }
    if (!this.admin_review_at) {
      return '未審査';
    }
    return new Map()
      .set(VerifyTypeBoolean.Verified, '審査完了')
      .set(VerifyTypeBoolean.Unverified, '審査NG')
      .get(this.is_review_valid);
  }

  get firstClinic() {
    return this.clinics[0];
  }

  get fullName() {
    return `${this.family_name} ${this.given_name}`;
  }

  deserialize(input: Partial<ModelPatient>): Patient {
    Object.assign(this, input);

    return this;
  }
}

export interface ModelPatientDetail {
  id: number | null;
  city_id: number | null;
  prefecture_id: number | null;
  city: ModelCity;
  prefecture: ModelPrefecture;
  address: string | null;
  avatar: string | null;
  avatar_url: string;
  created_at: string;
  updated_at: string;
  admin_review_at: string;
  reason: string;
  front_side_card: string;
  front_side_card_url: string;
  back_side_card: string;
  back_side_card_url: string;
  date_of_birth: string;
  email: string;
  gender: boolean;
  is_review_valid: boolean;
  status_review: number;
  height: string;
  is_verified: boolean;
  name: string;
  name_kana: string;
  note: string | null;
  phone: string | null;
  postal_number: number | null;
  reservations: any[];
  user_upload_at: string | null;
  order_first: any[];
}

export const defaultPatientDetail: ModelPatientDetail = {
  id: null,
  city_id: null,
  city: {
    code: '',
    en_name: '',
    id: null,
    ja_name: '',
    name_kana: '',
    prefecture_id: null,
    zh_name: '',
  },
  prefecture: {
    en_name: '',
    id: null,
    ja_name: '',
    name_kana: '',
    zh_name: '',
  },
  address: null,
  avatar: null,
  avatar_url: '',
  created_at: '',
  updated_at: '',
  admin_review_at: '',
  reason: '',
  front_side_card: '',
  front_side_card_url: '',
  back_side_card: '',
  back_side_card_url: '',
  date_of_birth: '',
  email: '',
  gender: false,
  is_review_valid: false,
  status_review: 1,
  height: '',
  is_verified: false,
  name: '',
  name_kana: '',
  note: null,
  phone: null,
  postal_number: null,
  prefecture_id: null,
  reservations: [],
  user_upload_at: null,
  order_first: [],
};

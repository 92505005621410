
























import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import BaseInput from '@/components/common/BaseInput.vue';

@Component({
  components: {
    BaseInput,
  },
})
export default class MedicineFormItem extends Vue {
  @Prop([String, Number]) medicineId: string | number;
  @Prop(Object) dataForm!: any;

  isMounted = false;

  dosageTypes: string[] = ['wakeup', 'morning', 'afternoon', 'evening', 'sleep'];

  get dosageLabels() {
    return {
      wakeup: '起',
      morning: '朝',
      afternoon: '昼',
      evening: '夕',
      sleep: '寝',
    };
  }

  handleKeyPress($event) {
    const charCode = ($event.which) ? $event.which : $event.keyCode;
    if (charCode < 48 || charCode > 57) {
      $event.preventDefault();
    } else {
      return true;
    }
  }

  handleChange(dosageType, event) {
    this.isMounted = true;
    const value = /^0[1-9].*$/.test(event) ? Number(event) : event;
    this.$emit('onChange', `${dosageType}_dosage`, value);
  }

  checkAllDosagesFilled() {
    if (!this.isMounted) { return true; }

    const dosageValid = this.dosageTypes.find((type: string) => {
      const value = this.dataForm[`${type}_dosage`];
      if (value && (value < 1 || value > 10 || !/^([1-9][0-9]{0,1})$/.test(value))) {
        return type;
      }
    });

    if (this.dataForm.wakeup_dosage == '' &&
      this.dataForm.sleep_dosage == '' &&
      this.dataForm.morning_dosage == '' &&
      this.dataForm.evening_dosage == '' &&
      this.dataForm.afternoon_dosage == '') {
        this.$emit('changeIsInvalidDosage', true);
        return false;
      }
    this.$emit('changeIsInvalidDosage', !!dosageValid);
    return !dosageValid;
  }

  get dosageErrorMessage() {
    if (this.checkAllDosagesFilled()) {
      return '';
    } else if (this.dataForm.wakeup_dosage == '' &&
      this.dataForm.sleep_dosage == '' &&
      this.dataForm.morning_dosage == '' &&
      this.dataForm.evening_dosage == '' &&
      this.dataForm.afternoon_dosage == '') {
        return '※必須項目が入力されていません';
    } else {
      return '※1から10の間の値を入力してください。';
    }
  }

  @Watch('dataForm', { deep: true })
  private watchDataForm(_) {
    if (this.dataForm.wakeup_dosage == '' &&
      this.dataForm.sleep_dosage == '' &&
      this.dataForm.morning_dosage == '' &&
      this.dataForm.evening_dosage == '' &&
      this.dataForm.afternoon_dosage == '') {
        this.$emit('changeIsInvalidDosage', true);
      }
  }
}

import PatientV2 from '@/views/v2/patient/index.vue';
import AboutPatient from '@/views/v2/patient/about/index.vue';
import { Roles } from '@common/app.config';

const authMeta = {
  auth: true,
  authorize: [
    Roles.superAdmin,
    Roles.admin,
    Roles.doctor,
    Roles.customerService,
    Roles.pharmacist,
  ],
};

export default [
  {
    path: '/v2/patients',
    name: 'PatientV2',
    component: PatientV2,
    meta: authMeta,
    children: [
      {
        path: ':patientId',
        name: 'AboutPatient',
        component: AboutPatient,
        props: true,
        meta: authMeta,
      },
    ],
  },
];

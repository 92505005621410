




















































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import DatePicker from 'vue2-datepicker';
import moment from 'moment-timezone';

interface OptionTime {
  name: string;
  value: string;
}

@Component({
  components: {
    DatePicker,
  },
})
export default class DateTimePickerPopup extends Vue {
  @Prop({ default: false }) readonly isVisible!: boolean;
  @Prop({ default: '' }) readonly suggestReservationDate: string;

  private selectedDateTime: string = '';
  private selectedTime: string = '';
  private timeOptions: OptionTime[] = this.generateTimeOptions();

  private get availableTimeOptions() {
    return this.hoursAble(this.selectedDateTime);
  }

  private generateTimeOptions(): OptionTime[] {
    const times: OptionTime[] = [];
    let startTime = 0;
    const endTime = 24 * 60;

    while (startTime < endTime) {
      const hours = Math.floor(startTime / 60);
      const minutes = startTime % 60;
      const formattedTime = `${this.pad(hours)}:${this.pad(minutes)}`;
      times.push({ name: formattedTime, value: formattedTime });
      startTime += 10;
    }

    return times;
  }

  private pad(value: number): string {
    return value < 10 ? `0${value}` : value.toString();
  }

  private disabledDatePicker(date) {
    return date < moment().startOf('day');
  }

  private hoursAble(date: string) {
    const today = moment();
    const givenDate = moment(date);

    if (givenDate.isSame(today, 'day') && date) {
      const currentHourInJapan = moment.tz('Asia/Tokyo').hour();
      return this.generateTimeOptions().filter((time) => {
        const hour = parseInt(time.name.split(':')[0], 10);
        return hour > currentHourInJapan;
      });
    }

    return this.timeOptions;
  }

  private confirmSelection() {
    const [selectedDate, selectedTime] = [
      this.selectedDateTime,
      this.selectedTime,
    ];
    this.$emit('confirm', { date: selectedDate, time: selectedTime });
  }

  private handleClose() {
    this.selectedDateTime = '';
    this.selectedTime = '';
    this.$emit('closeDateTimePicker');
  }

  @Watch('suggestReservationDate', { deep: true })
  private watchSuggestReservationDate() {
    if (this.suggestReservationDate) {
      this.selectedDateTime = moment(this.suggestReservationDate).format(
        'YYYY-MM-DD',
      );
      this.selectedTime = moment(this.suggestReservationDate).format('HH:mm');
    }
  }
}

import { Deserializable } from '@common/interfaces/deserializable';

export interface DoctorInput {
  id: number;
  name: string;
  name_kana: string;
}

export class Doctor implements Deserializable<Doctor>, DoctorInput {
  id!: number;
  name: string = '';
  name_kana: string = '';

  deserialize(input: Partial<DoctorInput>): Doctor {
    Object.assign(this, input);
    return this;
  }
}

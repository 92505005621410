import TranslateApi from './TranslateApi';
import UserApi from './UserApi';
import ClinicApi from './ClinicApi';
import DoctorApi from './DoctorApi';
import DoctorDetailApi from './DoctorDetailApi';
import DoctorAdminApi from './DoctorAdminApi';
import PrefectureApi from './PrefectureApi';
import PatientApi from './PatientApi';
import MessageApi from './MessageApi';
import ChannelApi from './ChannelApi';
import ResvApi from './ResvApi';
import ShiftApi from './ShiftApi';
import MedicineApi from './MedicineApi';
import ZipcodeApi from './ZipcodeApi';
import MedicalMenuApi from './MedicalMenuApi';
import BankApi from './BankApi';
import PaymentApi from './PaymentApi';
import MedicalPrescriptionApi from './MedicalPrescriptionApi';
import NotificationApi from './NotificationApi';
import AppVersionApi from './AppVerion';
import DashboardApi from './DashboardApi';
import FAQApi from './FAQApi';
import ImportUserApi from './ImportUser';
import ClinicUserApi from './ClinicUser';
import NewsApi from './NewsApi';
import PharmacyApi from './PharmacyApi';
import PharmacistApi from './PharmacistApi';
import MonthlyReportApi from './MonthlyReportApi';
import RevenueApi from './RevenueApi';
import ShiftPharmacistApi from './ShiftPharmacistApi';
import PharmacyMenuApi from './PharmacyMenuApi';
import PharmacistReservationApi from './PharmacistReservationApi';
import PharmacistPatientApi from './PharmacistPatientApi';
import PharmacyQuestionApi from './PharmacyQuestionApi';
import QuestionApi from './QuestionApi';
import PlanApi from './PlanApi';
import OrderApi from './OrderApi';
import OrderTrackingApi from './OrderTrackingApi';
import AssignDoctorApi from './AssignDoctorApi';
import CourseApi from './CourseApi';
import DeliveryApi from '@/api/DeliveryApi';
import DoctorNotAdminApi from '@/api/DoctorNotAdmin';
import ReasonApi from '@/api/Reason';
import ClinicPatternApi from '@/api/ClinicPatternApi';
import ResvClinicPatternApi from '@/api/ResvClinicPatternApi';
import AuraApi from '@/api/AuraApi';
import DoctorMouthApi from '@/api/DoctorMouthApi';
import MemberApi from '@/api/MemberApi';
import ManagerApi from '@/api/ManagerApi';
import DoctorShiftApi from '@/api/DoctorShiftApi';
import PriorityApi from '@/api/PriorityApi';
import ReservationDoctorAPI from '@/api/ReservationDoctorApi';
import WebhookLineApi from '@/api/WebhookLineApi';
import OrderTrackingHistoryApi from '@/api/OrderTrackingHistoryApi';
import RefundApi from '@/api/RefundApi';
import OrderChangeApi from '@/api/OrderChangeApi';
import PatientV2Api from '@/api/v2/PatientApi';
import ChannelV2Api from '@/api/v2/ChannelApi';
import SalesManagementApi from '@/api/SalesManagementApi';
import MedicalMenuV2Api from '@/api/v2/MedicalMenuApi';
import ClinicV2Api from '@/api/v2/ClinicApi';
import PlanApiV2Api from '@/api/v2/PlanApi';
import UserTreatmentV2Api from '@/api/v2/UserTreatment';
import PrecautionApi from '@/api/PrecautionApi';
import DoctorFeedbackApi from '@/api/v2/DoctorFeedbackApi';
import StockApi from '@/api/v2/StockApi';

const requestMap: { [key: string]: any } = {
  TranslateApi,
  UserApi,
  ClinicApi,
  DoctorApi,
  DoctorAdminApi,
  DoctorDetailApi,
  PrefectureApi,
  PatientApi,
  MessageApi,
  ChannelApi,
  ResvApi,
  ShiftApi,
  MedicineApi,
  ZipcodeApi,
  MedicalMenuApi,
  BankApi,
  PaymentApi,
  MedicalPrescriptionApi,
  NotificationApi,
  AppVersionApi,
  DashboardApi,
  FAQApi,
  ImportUserApi,
  ClinicUserApi,
  NewsApi,
  PharmacyApi,
  PharmacistApi,
  MonthlyReportApi,
  RevenueApi,
  ShiftPharmacistApi,
  PharmacyMenuApi,
  PharmacistReservationApi,
  PharmacistPatientApi,
  PharmacyQuestionApi,
  QuestionApi,
  PlanApi,
  OrderApi,
  OrderTrackingApi,
  DeliveryApi,
  AssignDoctorApi,
  CourseApi,
  DoctorNotAdminApi,
  ReasonApi,
  ClinicPatternApi,
  ResvClinicPatternApi,
  AuraApi,
  DoctorMouthApi,
  MemberApi,
  ManagerApi,
  DoctorShiftApi,
  PriorityApi,
  ReservationDoctorAPI,
  WebhookLineApi,
  OrderTrackingHistoryApi,
  RefundApi,
  OrderChangeApi,
  PatientV2Api,
  ChannelV2Api,
  SalesManagementApi,
  MedicalMenuV2Api,
  PlanApiV2Api,
  UserTreatmentV2Api,
  PrecautionApi,
  DoctorFeedbackApi,
  ClinicV2Api,
  StockApi,
};

class ApiHelpers {
  public static getApi(className: string) {
    const RequestClass: any = requestMap[className];

    if (!RequestClass) {
      throw new Error('Invalid request class name: ' + className);
    }

    return new RequestClass();
  }
}
export default ApiHelpers;

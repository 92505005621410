import { render, staticRenderFns } from "./OrderTrackingTable.vue?vue&type=template&id=d47ceb74&scoped=true&"
import script from "./OrderTrackingTable.vue?vue&type=script&lang=ts&"
export * from "./OrderTrackingTable.vue?vue&type=script&lang=ts&"
import style0 from "./OrderTrackingTable.vue?vue&type=style&index=0&id=d47ceb74&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d47ceb74",
  null
  
)

export default component.exports
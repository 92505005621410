import { config } from '@/common/app.config';
import axios from 'axios';
import router from '@/router';

abstract class BaseApi {
  constructor() {
    //
  }

  public async getList(params?: any, cancelToken?: any) {
    return await this.get('/', params, cancelToken);
  }

  public async getOne(id: number, params?: any) {
    return await this.get(`/${id}`, params);
  }

  public async getInfo() {
    return await this.get(`/managers/info`);
  }

  public async create(params: any) {
    return await this.post('/', params);
  }

  public async update(id: number | string, params: any) {
    return await this.put(`/${id}`, params);
  }

  public async get(url: string, params = {}, cancelToken?: any) {
    try {
      params = this.appendLocale(params);
      const response = await axios.get(`${this.getApiPrefix()}${url}`, {
        cancelToken,
        params,
        headers: { timezone: 'UTC+09:00' },
      });
      return this._responseHandler(response);
    } catch (error) {
      this._errorHandler(error);
    }
  }

  public async put(url: string, data?: any) {
    try {
      data = this.appendLocale(data);
      const response = await axios.put(`${this.getApiPrefix()}${url}`, data, {
        headers: { timezone: 'UTC+09:00' },
      });
      return this._responseHandler(response);
    } catch (error) {
      this._errorHandler(error);
    }
  }

  public async patch(url: string, data?: any) {
    try {
      data = this.appendLocale(data);
      const response = await axios.patch(`${this.getApiPrefix()}${url}`, data, {
        headers: { timezone: 'UTC+09:00' },
      });
      return this._responseHandler(response);
    } catch (error) {
      this._errorHandler(error);
    }
  }

  public async post(url: string, data?: any) {
    try {
      if (data) {
        data = this.appendLocale(data);
      }
      const response = await axios.post(`${this.getApiPrefix()}${url}`, data, {
        headers: { timezone: 'UTC+09:00' },
      });
      return this._responseHandler(response);
    } catch (error) {
      this._errorHandler(error);
    }
  }

  public async del(url: string, data?: any) {
    try {
      if (data) {
        data = this.appendLocale(data);
      }
      const response = await axios.delete(`${this.getApiPrefix()}${url}`, {
        data,
        headers: { timezone: 'UTC+09:00' },
      });
      return this._responseHandler(response);
    } catch (error) {
      this._errorHandler(error);
    }
  }

  public destroy(id: number, params: any) {
    return this.del(`/${id}`, params);
  }

  public async postWithFile(url: string, data: FormData) {
    const headers = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'timezone': 'UTC+09:00',
      },
    };
    try {
      data = this.appendLocale(data);
      const response = await axios.post(
        `${this.getApiPrefix()}${url}`,
        data,
        headers,
      );
      return this._responseHandler(response);
    } catch (error) {
      this._errorHandler(error);
    }
  }

  protected abstract getSpecificApiPrefix(): string;

  private _responseHandler(response: any) {
    const data = response.data;
    return data;
  }

  private _errorHandler(error: any) {
    if (error.response && error.response.status === 401) {
      // Unauthorized (session timeout)
      (window.app as any).$broadcast('UserSessionNotFound');
    }

    if (error.response && error.response.status === 404) {
      router.push({ name: 'PageNotFound' });
    }

    if (error.response && error.response.status === 503) {
      // maintenance
      window.location.reload();
    }
    throw error;
  }
  private getApiPrefix(): string {
    return `${config.apiUrl}/api/${this.getSpecificApiPrefix()}`;
  }

  private getCurrentLocale() {
    if (window.i18n) {
      return window.i18n.locale;
    }
  }

  private appendLocale(data: any) {
    const lang = this.getCurrentLocale();
    return Object.assign(data, { lang });
  }
}

export default BaseApi;






























import { Vue, Prop, Component } from 'vue-property-decorator';
import IconCopyLinkSvg from '@components/iconSVG/CopyLink.vue';
import IconCheckSvg from '@components/iconSVG/Check.vue';

@Component({
  components: {
    IconCopyLinkSvg,
    IconCheckSvg,
  },
})
export default class GenerateLinkModal extends Vue {
  @Prop() generatedLink!: string;
  @Prop({ default: false }) isVisible!: boolean;
  copied: boolean = false;
  isCopying: boolean = false;

  copyLink() {
    this.isCopying = true;
    navigator.clipboard
      .writeText(this.generatedLink)
      .then(() => {
        this.copied = true;
        setTimeout(() => {
          this.copied = false;
          this.isCopying = false;
        }, 3000);
      })
      .catch(() => {
        this.isCopying = false;
      });
  }

  handleClose() {
    this.$emit('closeModal');
  }
}




import { Vue, Component } from 'vue-property-decorator';

import ApiHelper from 'api-helper';
import { State } from 'vuex-class';
import Toast from '@/common/helpers/toast';
import PopupConfirm from '@/components/common/PopupConfirm.vue';
import PageLoader from '@components/common/PageLoader.vue';
import DragDropFileUpload from '@/components/common/DragDropFileUpload.vue';
import { Medicine } from '@/models/medicine';
import imageDefault from '@/assets/img/icon-no-image.svg';
import medicine from '@/routers/medicine';
import isEqual from 'lodash/isEqual';

@Component({
  components: {
    PageLoader,
    DragDropFileUpload,
    PopupConfirm,
  },
})
export default class CreateEdit extends Vue {
  @State((state) => state.medicine.medicine) medicine: Medicine;
  medicineDefault: Medicine;
  isLoading: boolean = false;
  isEdit: boolean = false;

  get src() {
    return this.medicine.image_url || imageDefault;
  }

  get isChangeData() {
    return isEqual(this.medicine, this.medicineDefault);
  }

  mounted() {
    if (this.$route.params.hasOwnProperty('id')) {
      this.isEdit = true;
      this.getMedicineDetail(this.$route.params.id);
    }

    if (!this.$route.params.isConfirm) {
      this.resetForm();
    }
  }

  getMedicineDetail(id?: string) {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    ApiHelper.getApi('MedicineApi')
      .getOne(id, { with: 'withTrashed' })
      .then((res: any) => {
        if (!this.medicine.created_at) {
          this.medicineDefault = new Medicine().deserialize(res);
        }
        this.$store.state.medicine.medicine = new Medicine().deserialize(res);
      })
      .catch((error: any) => {
        if (error.response) {
          Toast.error(error.response.data.message);
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  openPopupConfirmUpdate() {
    this.$modal.show('confirmUpdateDoctor', { medicine: this.medicine });
  }

  cancelPopupConfirm() {
    this.$modal.hide('confirmUpdateDoctor');
  }

  updateMedicine() {
    if (this.isLoading) {
      return;
    }

    let formData = this.medicine.formJSONData(this.isEdit);
    if (this.medicine.image && (typeof this.medicine.image === 'object')) {
      formData = this.medicine.formJSONDataHaveImage(this.isEdit);
    }
    this.isLoading = true;

    ApiHelper.getApi('MedicineApi')
      .update(this.medicine.id, formData)
      .then((res: any) => {
        Toast.success('更新しました');
        this.$router.push({ name: 'medicine.detail', params: { id: `${this.medicine.id}` } });
      })
      .catch((error: any) => {
        if (error.response) {
          Toast.error(error.response.data.message);
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  updateForm(field: string, value: any) {
    this.handleFile(value);
    this.$set(this.$store.state.medicine.medicine, field, value);
  }

  handleFile(val) {
    if (typeof val === 'object') {
      const file = val;
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.$set(this.medicine, 'image_url', event.target.result);
      };
      reader.readAsDataURL(file);
    }
  }

  actionCancel() {
    if (this.isEdit) {
      this.$router.push({ name: 'medicine.detail', params: { id: `${this.medicine.id}` } });
      return;
    }
    this.resetForm();
    this.$router.push({ name: 'medicine' });
  }

  actionSubmit() {
    if (this.isEdit) {
      this.openPopupConfirmUpdate();
      return;
    }

    this.$store.state.medicine.medicine = this.medicine;
    this.$router.push({ name: 'medicine.confirm' });
  }

  resetForm() {
    this.$store.state.medicine.medicine = new Medicine();
  }
}





































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
// Utils
import ApiHelper from 'api-helper';
import Toast from '@common/helpers/toast';
// Components
import BaseDatePicker from '@/components/common/BaseDatePicker.vue';
import PopupConfirmDeletion from '@/components/common/PopupConfirmDeletion.vue';
import FormAction from '@/components/common/FormAction.vue';
// Constants
import { formatTime } from '@common/app.config';

@Component({
  components: {
    BaseDatePicker,
    PopupConfirmDeletion,
    FormAction,
  },
})
export default class Calendar extends Vue {
  @Prop(String) readonly modalName: string;
  @Prop(Object) readonly reservation!: any;

  formatTime: string = formatTime;
  isChanged: boolean = false;
  isSubmiting: boolean = false;
  isSubmitDelete: boolean = false;
  dataForm = {
    date: null,
    time: null,
  };

  get computedForm() {
    return this.$refs[this.modalName] as any;
  }

  gotoDetail(reservation) {
    this.$router.push({
      name: 'PatientV2',
      query: { search: reservation.user.email},
    });
  }

  hiddenUpdateReservation() {
    this.$modal.hide(this.modalName);
  }

  async updateReservation() {
    const isValid = await this.computedForm.validate();
    if (!isValid) {
      return;
    }
    try {
      const data = {
        reservation_date: `${this.dataForm.date} ${this.dataForm.time}:00`,
      };
      this.isSubmiting = true;
      await ApiHelper.getApi('ManagerApi').updateReservationDate(
        this.reservation.id,
        data,
      );
      this.isSubmiting = false;
      Toast.success('ビデオ診療の日時を更新しました');
      this.hiddenUpdateReservation();
      this.$emit('onUpdateSuccess');
    } catch (error) {
      this.isSubmiting = false;
      const data = error?.response?.data;
      const status = error?.response?.status;
      Toast.error(data.message);
      if (status === 422) {
        this.computedForm.setErrors(data?.errors);
      }
    }
  }

  async deleteReservation() {
    try {
      this.isSubmitDelete = true;
      const data = {
        reservation_date: `${this.dataForm.date} ${this.dataForm.time}:00`,
      };
      await ApiHelper.getApi('ManagerApi').deleteReservationDate(this.reservation.id, data);
      this.isSubmitDelete = false;
      this.$modal.hide('popupDeleteReservation');
      this.hiddenUpdateReservation();
      this.$emit('onDeleteSuccess');
      Toast.info('削除しました');
    } catch (error) {
      this.isSubmitDelete = false;
      const data = error?.response?.data;
      Toast.error(data?.message);
    }
  }

  @Watch('dataForm')
  private watchFormUpdate() {
    this.isChanged = true;
  }

  @Watch('reservation')
  private watchReservationDate() {
    const { date, time } = this.reservation;
    this.dataForm = {
      date,
      time,
    };
  }
}





import { Vue, Component } from 'vue-property-decorator';
import Create from '@components/Pharmacist/PharmacistCreate.vue';

@Component({
  head: {
    title: {
      inner: '薬剤師アカウント情報',
    },
  },
  components: {
    Create,
  },
})

export default class CreatePharmacist extends Vue {}

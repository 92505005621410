








































import ApiHelper from 'api-helper';
import { State } from 'vuex-class';
import { Plan } from '@/models/plan';
import { Patient } from '@/models/patient';
import Toast from '@common/helpers/toast';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import QuestionList from '@components/messages/partials/QuestionList.vue';

@Component({
  components: {
    QuestionList,
  },
})
export default class PopupConfirm extends Vue {
  @Prop(String) readonly name!: string;
  @Prop(Boolean) readonly isShow!: boolean;

  @State((state) => state.patient_detail.data) currentPatient!: Patient;

  paginate = {
    page: 1,
    per_page: 20,
    last_page: 1,
  };

  filterParams = { with: 'medicines', select_fields: 'id,name,price_first', clinic_id: null, karte_status: 1 };

  get queryParams() {
    return {
      ...this.filterParams,
      ...this.paginate,
    };
  }

  dataPlan: Plan[] = [];
  isTableLoading = false;
  columns = [
    {
      title: 'プラン名',
      dataIndex: 'name',
      width: 200,
    },
    {
      title: '商品セット',
      dataIndex: 'medicines',
      width: 250,
      scopedSlots: { customRender: 'list_medicine' },
    },
    {
      title: '表示価格（税込）',
      dataIndex: 'price_first',
      scopedSlots: { customRender: 'price_show' },
    },
  ];

  planListTableEl: Element | null = null;
  scrollY = window.innerHeight - 100;

  getPlanList() {
    if (this.isTableLoading) {
      return;
    }

    if (this.paginate.page > this.paginate.last_page && this.paginate.last_page > 1) {
      this.isTableLoading = false;
      return;
    }

    this.isTableLoading = true;

    if (this.currentPatient && this.currentPatient.clinics.length > 0) {
      this.filterParams = {
        ...this.filterParams,
        clinic_id: this.currentPatient.clinics[0].id,
      };
    }

    ApiHelper.getApi('PlanApi')
      .fetchPlanList(this.queryParams)
      .then((response: any) => {
        const lastPage = response ? response.last_page : 1;
        const plans = response.data.map((item) =>
          new Plan().deserialize(item),
        );

        this.dataPlan = [...this.dataPlan, ...plans];

        this.paginate = {
          ...this.paginate,
          page: lastPage >= this.paginate.page ? this.paginate.page + 1 : this.paginate.page,
          last_page: lastPage,
        };
      })
      .catch((error: any) => {
        Toast.error(error.response.data.message);
      })
      .finally(() => {
        this.isTableLoading = false;
      });
  }

  mounted() {
    this.getPlanList();
    window.addEventListener('resize', () => {
      this.scrollY = window.innerHeight - 100;
    });
  }

  destroyed() {
    window.removeEventListener('resize', () => {
      this.scrollY = 0;
    });
  }

  handleScroll(event) {
    const container = event.target;
    const { scrollTop, scrollHeight, clientHeight } = container;

    if (scrollTop + clientHeight >= scrollHeight - 10 && !this.isTableLoading) {
      this.getPlanList();
    }
  }

  @Watch('isShow')
  private isShowChanged() {
    if (!this.planListTableEl) {
      this.planListTableEl = document.querySelector('#plan-list-table-id > div > div > div > div > div > div.ant-table-body');

      this.planListTableEl?.addEventListener('scroll', this.handleScroll);
    }
  }

  private closePlanListModal() {
    this.$modal.hide(this.name);
  }

  private convertMedicineArrToString(record) {
    if (record && record.medicines && record.medicines.length > 0) {
      return record.medicines.map((medicine) => medicine.name).join(', ');
    }

    return '';
  }
}



























































































import { Vue, Component, Watch } from 'vue-property-decorator';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import BasicSelect from '@components/common/BasicSelect.vue';
import PageLoader from '@components/common/PageLoader.vue';
import InfiniteLoading from 'vue-infinite-loading';
import { State } from 'vuex-class';
import { USER_STATUS, USER_STATUS_NAME, NEW_USER_STATE, NEW_USER_STATE_NAME } from '@/common/constants/auth';
import lodash from 'lodash';
import Multiselect from 'vue-multiselect';
import PatientItem from '@components/v2/patient/PatientItem.vue';
import ApiHelper from '@/api/ApiHelper';
import Toast from '@common/helpers/toast';

@Component({
  components: {
    PageLoader,
    PatientItem,
    VuePerfectScrollbar,
    InfiniteLoading,
    BasicSelect,
    Multiselect,
  },
})
export default class Channel extends Vue {

  @State((state) => state.patient_list.data) patientList!: any;
  @State((state) => state.auth.currentUser) currentUser!: any;
  private query = '';
  private delayId: any = null;
  private selectedStates: any = [];
  private stateOptions: any[] = [];
  private selectedSort: string = '';
  private sortOptions: any[] = [
    { name: '並び替え', value: '' },
    { name: '名前 昇順', value: 'family_name_kana' },
    { name: '名前 降順', value: '-family_name_kana' },
    { name: '登録日 昇順', value: 'registered_at' },
    { name: '登録日 降順', value: '-registered_at' },
    { name: '更新日 昇順', value: 'updated_at' },
    { name: '更新日 降順', value: '-updated_at' },
    { name: '返信状態 昇順', value: 'send_message_at' },
    { name: '返信状態 降順', value: '-send_message_at' },
  ];
  private pathName = this.$route.matched[1].path;
  private activeTab: any = '';
  private showCheckbox: boolean = false;
  private listSkipMess: any = [];

  created() {
    this.stateOptions.push({ name: 'クリア', value: 'clear_all' }, ...this.makeStateOption());
    this.setInputValueFromQueryData();
    this.setSearchDefaultState();
    this.setUrlWithQuery();
    this.setStateOptionsForOrtherRoleDoctor();

    if (this.$refs.InfiniteLoading) {
      (this.$refs.InfiniteLoading as any).stateChanger.reset();
    }
    this.$store.commit('patient_list/resetPatientListState');
  }

  beforeDestroy() {
    this.$store.commit('patient_list/resetPatientListState');
  }

  showAndHide() {
    this.showCheckbox = !this.showCheckbox;
    this.listSkipMess = [];
  }

  showButtonCheckbox() {
    if ( !(this.currentUser.isDoctorRole && !!this.selectedStates.find((item: any) => item.value === NEW_USER_STATE.DOCTOR_CHAT_UNREPLY)) ) {
      this.showCheckbox = false;
    }
    return this.currentUser.isDoctorRole && !!this.selectedStates.find((item: any) => item.value === NEW_USER_STATE.DOCTOR_CHAT_UNREPLY);
  }

  async submitCheckbox() {
    await ApiHelper.getApi('PatientV2Api')
      .skipMessages({user_ids: this.listSkipMess})
      .then((res: any) => {
        this.showAndHide();
        this.debouncedSearch(this.selectedStates);
        Toast.success('対応済みに変更しました。');
      })
      .catch((err: any) => {
        Toast.error(err.message || '対応済みが変更できません。');
      });
  }

  checkbox(status, patient) {
    this.listSkipMess = status
      ? [...this.listSkipMess, patient?.id]
      : this.listSkipMess.filter((item: any) => item !== patient?.id);
  }

  isDisableSubmitCheckbox() {
    if (this.showCheckbox && this.listSkipMess.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  debouncedSearch(selected) {
    this.listSkipMess = [];
    if (lodash.isArray(selected) && selected.findIndex((option) => option.value === 'clear_all') !== -1) {
      this.selectedStates = [];
    }
    if (lodash.get(this.selectedStates, '0.value', '') === '') {
      this.selectedStates = [];
    }
    clearTimeout(this.delayId);
    this.$nextTick(() => {
      this.delayId = setTimeout(this.onSearch, 2000);
    });
  }

  onSearch($state) {
    this.setUrlWithQuery(this.getCurrentPage());
    (this.$refs.infiniteLoading as any).stateChanger.reset();
    this.$store.commit('patient_list/resetPatientListState');
  }

  private isNotLastItem(index) {
    return (index + 1) != this.patientList.length;
  }


  private setUrlWithQuery(path: string|null = null) {
    this.$router.push({
      path: path ? path : this.$route.path,
      query: {...this.$route.query, ...this.makeSearchParams()},
    });
  }

  private makeSearchParams() {
    return {
        state: this.selectedStates.map((item) =>  item.value),
        search: this.query,
        order: this.selectedSort,
        time: lodash.now().toString(),
        tab: this.activeTab || '',
      };
  }

  private makeQueryParams() {
    return location.search;
  }

  private setSearchDefaultState() {
    if (this.$route.query.state || this.$route.query.search) {
      return;
    }

    // if (this.currentUser.isSuperAdminRole) {
    //   return this.selectedStates = [];
    // }

    if (this.currentUser.isCSRole) {
      return this.selectedStates = [
        { name: NEW_USER_STATE_NAME.IDENTITY_CHECK, value: NEW_USER_STATE.IDENTITY_CHECK },
      ];
    }

    if (!this.currentUser.isCSRole) {
      this.selectedStates = [
        { name: NEW_USER_STATE_NAME.PATIENT_PROVISIONALLY_REGISTERED, value: NEW_USER_STATE.PATIENT_PROVISIONALLY_REGISTERED },
        { name: NEW_USER_STATE_NAME.IDENTITY_CHECK, value: NEW_USER_STATE.IDENTITY_CHECK },
        { name: NEW_USER_STATE_NAME.IDENTITY_VERIFICATION_NOT_REGISTERED, value: NEW_USER_STATE.IDENTITY_VERIFICATION_NOT_REGISTERED },
        { name: NEW_USER_STATE_NAME.REQUESTING_SMS_VERIFICATION, value: NEW_USER_STATE.REQUESTING_SMS_VERIFICATION },
        { name: NEW_USER_STATE_NAME.IDENTIFICATION_NG, value: NEW_USER_STATE.IDENTIFICATION_NG },
        { name: NEW_USER_STATE_NAME.IDENTIFICATION_OK, value: NEW_USER_STATE.IDENTIFICATION_OK },
        { name: NEW_USER_STATE_NAME.DOCTOR_CHAT_UNREPLY, value: NEW_USER_STATE.DOCTOR_CHAT_UNREPLY },
        { name: NEW_USER_STATE_NAME.DOCTOR_RESERVE_PRESCRIPTION, value: NEW_USER_STATE.DOCTOR_RESERVE_PRESCRIPTION },
        { name: NEW_USER_STATE_NAME.DOCTOR_CHAT_REPLIED, value: NEW_USER_STATE.DOCTOR_CHAT_REPLIED },
      ];
    }
  }

  private setStateOptionsForOrtherRoleDoctor() {
    if (!this.currentUser?.isDoctorRole) {
      const namesToRemove = [
        NEW_USER_STATE_NAME.DOCTOR_CHAT_UNREPLY,
        NEW_USER_STATE_NAME.DOCTOR_RESERVE_PRESCRIPTION,
        NEW_USER_STATE_NAME.DOCTOR_CHAT_REPLIED,
      ];
      this.stateOptions = this.stateOptions.filter(
        (state) => !namesToRemove.includes(state.name),
      );
      this.selectedStates = this.selectedStates.filter(
        (state) => !namesToRemove.includes(state.name),
      );
    }
  }

  private setInputValueFromQueryData() {
    const query = this.$route.query;
    let stateList: any = [];

    if (lodash.isString(query.state)) {
      stateList.push(query.state);
    }
    if ( lodash.isArray(query.state)) {
      stateList = query.state;
    }

    this.selectedStates = this.getStateObjectList(stateList);
    this.query = query.search ? query.search.toString() : '';
    this.selectedSort = query.order ? query.order.toString() : '';
    this.activeTab = query?.tab ? query?.tab : '';
  }

  private getStateObjectList(stateList) {
    return lodash.compact(
      stateList.map(
        (state) => {
          return { name: NEW_USER_STATE_NAME[state?.toUpperCase()], value: state};
        },
      ),
    );
  }

  private getCurrentPage() {
    return location.pathname;
  }

  private async loadData($state) {
    const payload = {
      manager_query: this.query,
      new_state: this.getStatusSearch(),
      order: this.selectedSort,
    };
    await this.$store.dispatch('patient_list/getPatientList', { payload, $state});
  }

  private getStatusSearch() {
    let statesName: any = [];

    if (this.selectedStates && this.selectedStates.length > 0) {
        this.selectedStates.length > 0 && this.selectedStates.map((data) => {
          statesName = [...statesName, data.value];
        });

        return statesName.filter((data) => data.value !== '');
      }

    return statesName;
  }

  private makeStateOption = () => {
    const data: any = [];
    Object.keys(NEW_USER_STATE).map((name) => {
      data.push({ name: NEW_USER_STATE_NAME[name], value: NEW_USER_STATE[name]});
    });

    return data;
  }

  private reloadPatientList() {
    // reload patient list for patient component
    (this.$refs.infiniteLoading as any).stateChanger.reset();
  }
}

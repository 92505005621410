import BaseApi from '../BaseApi';

class ClinicV2Api extends BaseApi {

  public generatePreviewUrl(params: FormData) {
    return this.postWithFile('v2/managers/preview/generate', params);
  }
  protected getSpecificApiPrefix() {
    return '';
  }
}

export default ClinicV2Api;































































// libs
import { Vue, Component } from 'vue-property-decorator';
import { State } from 'vuex-class';

// interfaces
import { Patient } from '@/models/patient';
import { User } from '@/models/user';

// component
import ImageZoom from '@/components/v2/patient/about/patient_info/ImageZoom.vue';
import PopupConfirm from '@/components/common/PopupConfirm.vue';

// constants
import { USER_STATUS, USER_TREATMENT_STATE } from '@/common/constants/auth';

// images
import imageIdentityDefault from '@/assets/img/identity.svg';
import { UserTreatment } from '@/models/user-treatment';

@Component({
  components: {
    ImageZoom,
    PopupConfirm,
  },
})
export default class PatientControlIdentification extends Vue {
  @State((state) => state.patient_detail.data) patient!: Patient;
  @State((state) => state.patient_detail.isLoading) isLoading!: boolean;
  @State((state) => state.auth.currentUser) currentUser!: User;
  @State((state) => state.treatment_detail.data) activeTreatment: UserTreatment;

  isChangeState = false;
  imageIdentityDefault = imageIdentityDefault;
  USER_STATUS = USER_STATUS;
  newStateUser: string = '';
  messageConfirmDel: string = '';
  textActionPopup: string = '';
  classBtnAction: string = '';

  get canVerify() {
    return (
      this.currentUser.isCSRole &&
      (this.patient.new_state === USER_STATUS.IDENTITY_CHECK ||
        this.patient.new_state === USER_STATUS.IDENTIFICATION_OK ||
        this.patient.new_state === USER_STATUS.IDENTIFICATION_NG) &&
      this.patient.front_side_card
    );
  }

  async onChangePatientIdentify() {
    if (!this.newStateUser) {
      return;
    }
    const payloadIdentification = {
      id: this.patient.id,
      data: {
        state: this.newStateUser,
        with: 'latestReservation',
        user_treatment_id: this.activeTreatment.id,
      },
    };
    await this.$store.dispatch(
      'patient_detail/updateStateIdentification',
      payloadIdentification,
    );

    const payloadUpdateState = {
      id: this.patient.id,
      state: this.patient.state,
    };
    this.$store.commit('patient_list/setPatientState', payloadUpdateState);

    this.$modal.hide('updateStateUser');
  }

  showConfirmStateUser(status) {
    const userName = `${this.patient.family_name} ${this.patient.given_name}`;
    const isIdentificationOk = status === USER_STATUS.IDENTIFICATION_OK;
    this.newStateUser = status;
    this.textActionPopup = isIdentificationOk ? 'OK' : 'NG';
    this.classBtnAction = isIdentificationOk ? 'btn-info' : 'btn-danger';
    this.messageConfirmDel = `<p>本当に${this.textActionPopup}しますか？</p> <p class="mb-5">${userName}</p>`;
    this.$modal.show('updateStateUser');
  }
}

import ListStock from '@/views/stocks/ListStock.vue';
import NewStock from '@/views/stocks/NewStock.vue';
import StockDetail from '@/views/stocks/StockDetail.vue';
import { Roles } from '@common/app.config';

const authAdminCustomer = {
    auth: true,
    authorize: [Roles.superAdmin, Roles.customerService, Roles.delivery, Roles.pharmacist],
  };

export default [
    {
        path: '/stocks',
        name: 'stocks',
        component: ListStock,
        meta: authAdminCustomer,
    },
    {
      path: '/stocks/new',
      name: 'stocks.new',
      component: NewStock,
      meta: {
        auth: true,
        authorize: [Roles.superAdmin, Roles.delivery, Roles.pharmacist],
      },
    },
    {
      path: '/stocks/detail/:id',
      name: 'stocks.detail',
      component: StockDetail,
      meta: {
        auth: true,
        authorize: [Roles.superAdmin, Roles.delivery, Roles.pharmacist],
      },
    },
];


import { Reservation } from '@/models/reservation';
import { Treatment } from '@/models/treatment';
import { Deserializable } from '@common/interfaces/deserializable';
import { Menu } from '@/models/menu';

export interface UserTreatmentInput {
  id: number;
  menu_id: number;
}

export class UserTreatment implements Deserializable<UserTreatment>, UserTreatmentInput {
  id!: number;
  menu_id: number;
  feedback_type: string;
  state: string;
  menu?: Treatment;
  reservations?: Reservation[];

  get onlyOneReservation() {
    return this.reservations ? this.reservations[0] : null;
  }

  deserialize(input: Partial<UserTreatmentInput>): UserTreatment {
    Object.assign(this, input);
    return this;
  }
}













































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import BasicSelect from '@components/common/BasicSelect.vue';
import { mapState, mapGetters } from 'vuex';
import PageLoader from '@components/common/PageLoader.vue';
import ChannelItem from './ChannelItem.vue';
import { ChannelModule } from '@/store';
import ApiHelper from 'api-helper';
import debounce from 'lodash/debounce';
import InfiniteLoading from 'vue-infinite-loading';
import axios from 'axios';
import { State } from 'vuex-class';
import { Roles } from '../../common/app.config';
import { CHAT_STATUS, MESSAGE_TYPE } from '@/common/enums/message';
// import CreateChatRoom from './CreateChatRoom.vue';
import sortBy from 'lodash/sortBy';
import { USER_STATUS, USER_STATUS_NAME } from '@/common/constants/auth';
import lodash from 'lodash';
import Multiselect from 'vue-multiselect';
import _ from 'lodash';

@Component({
  components: {
    PageLoader,
    ChannelItem,
    VuePerfectScrollbar,
    InfiniteLoading,
    // CreateChatRoom,
    BasicSelect,
    Multiselect,
  },
  computed: {
    ...mapGetters('channel', [
      'currentChannel', 'isChangeChannels',
    ]),
    ...mapState('auth', ['currentUser']),
  },
})
export default class Channel extends Vue {

  get channelList() {
    return this.$refs.channelList as any;
  }

  // private get isClinic() {
  //   return this.currentUser && this.currentUser.role === Roles.clinic;
  // }

  // private get isPharmacy() {
  //   return this.currentUser && this.currentUser.role === Roles.pharmacy;
  // }

  private get isActiveChat(): boolean {
    return this.currentChannel && this.currentChannel.status === CHAT_STATUS.ACTIVE;
  }

  @State((state) => state.auth.currentUser) currentUser!: any;
  @State((state) => state.channel.channels) currentChannels!: any;
  @State((state) => state.channel.isChangeChannels) isChangeChannels!: any;

  private channels: any[] = [];
  private isLoading: boolean = false;
  private totalPage: number = 1;
  private page: number = 1;
  private query = '';
  private delayId: any = null;
  private request: any = null;
  private currentChannel!: any;
  private isShowFilter: boolean = false;
  private searchOption: any = [];
  private sortOption: string = '';
  private sortOptions: any[] = [
    { name: '並び替え', value: '' },
    { name: '名前 昇順', value: 'user_family_name_kana' },
    { name: '名前 降順', value: '-user_family_name_kana' },
    { name: '登録日 昇順', value: 'user_registered_at' },
    { name: '登録日 降順', value: '-user_registered_at' },
    { name: '更新日 昇順', value: 'user_updated_at' },
    { name: '更新日 降順', value: '-user_updated_at' },
  ];

  private searchOptions: any[] = [];

  mounted() {
    this.searchOptions = [
      // { name: 'ステータス選択', value: '' },
      { name: 'クリア', value: 'clear_all' },
      ...this.getOptions(),
    ];
  }

  created() {
    this.$on('UpdateNewChannel', this.handleNewChannel);
    this.$on(`UpdateUnreadCount`, (data) => {
      const targetChannel = this.channels.find((c) => c.id === data.channelId);
      if (targetChannel) {
        targetChannel.message_unread_count = data.unreadMessageCount;
        targetChannel.status = data.channelStatus;
      }
    });
    if (this.currentUser && this.currentUser.role === Roles.pharmacy) {
      this.$on('UpdateMessageChannel', this.updateLastestMessage);
    }

    this.$on('UpdateUserState', (data) => {
      const targetChannel = this.channels.find((c) => _.get(c, 'users[0].id') === data.user_id);
      if (targetChannel) {
        targetChannel.users[0].state = data.user_state;
      }
    });
    this.setInputValueFromQueryData();
    this.setSearchDefaultState();
    this.setUrlWithQuery();

    const { query } = this.$route;
    if ('search_option' in query && !query.search_option) {
      this.searchOption = [];
    }
    if (this.$refs.InfiniteLoading) {
      (this.$refs.InfiniteLoading as any).stateChanger.reset();
    }
  }

  @Watch('isChangeChannels', { deep: true })
  channelChange(oldValue) {
    if (oldValue === true) {
      this.resetData();
      this.$store.dispatch('channel/setChangeChannels', false);
    }
  }

  isChannelUserHasState(channel) {
    return lodash.get(channel, 'users.0.state') !== null;
  }

  updateLastestMessage(message) {
    const channelId = message.channel_id;
    let lastestContent = message.message_content;
    if (message.type_message === MESSAGE_TYPE.IMAGE) {
      if (message.sender_id === this.currentUser.id) {
        lastestContent = 'あなたが写真を送信しました';
      } else {
        lastestContent = `${message.senderable.name}さんが写真を送信しました`;
      }
    }
    const targetChannel = this.channels.find((item) => item.id === channelId);
    targetChannel.latest_message.message_content = lastestContent;
    targetChannel.latest_message_at = message.created_at;
  }


  setSortOption(value) {
    this.sortOptions = value;
    this.resetData();
  }

  hideFilter() {
    this.isShowFilter = false;
  }

  onUnreadChange(data) {
    const targetChannel = this.channels.find((c) => c.id === data.channelId);
    if (targetChannel) {
      targetChannel.message_unread_count = data.unreadMessageCount;
      targetChannel.status = data.channelStatus;
    }
    // this.$store.dispatch('channel/setActiveChannel', targetChannel);
  }

  beforeDestroy() {
    this.channels = [];
    this.$off('UpdateNewChannel');
    this.$off('UpdateUnreadCount');
    this.$off('UpdateMessageChannel');
  }

  // @Watch('searchOption', { deep: true })
  // onSearchOption(newVal, oldVal) {
  //   this.channels = [];
  //   if (newVal !== oldVal) {
  //     (this.$refs.infiniteLoading as any).stateChanger.reset();
  //   }
  // }
  // @Watch('sortOption', { deep: true })
  // onSortOption(newVal, oldVal) {
  //   this.channels = [];
  //   if (newVal !== oldVal) {
  //     (this.$refs.infiniteLoading as any).stateChanger.reset();
  //   }
  // }

  arrayMove(arr, oldIndex, newIndex) {
    while (oldIndex < 0) {
        oldIndex += arr.length;
    }
    while (newIndex < 0) {
        newIndex += arr.length;
    }
    if (newIndex >= arr.length) {
        let k = newIndex - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  }

  handleNewChannel(channel) {
    if (this.query) { return; }
    if (!channel.latest_message) {
      channel = {
        ...channel,
        latest_message: {
          message_content: '',
        },
      };
    }
    const index = this.channels.findIndex((item) => item.id === channel.id);
    if (index > -1 ) {
      this.arrayMove(this.channels, index, 0);
      return;
    }
    if (!channel.message_unread_count) { channel.message_unread_count = 0; }
    if (this.searchOption === 'is_high_priority') {
      if (channel.is_high_priority) {
        this.channels.unshift(channel);
        return;
      }
      return;
    }
    this.channels.unshift(channel);
  }


  debouncedSearch(selected) {
    if (lodash.isArray(selected) && selected.findIndex((option) => option.value === 'clear_all') !== -1) {
      this.searchOption = [];
    }

    if (lodash.get(this.searchOption, '0.value', '') === '') {
      this.searchOption = [];
    }
    // this.request.cancel('cancel');
    this.isLoading = true;
    clearTimeout(this.delayId);
    this.channels = [];
    this.page = 1;
    this.$nextTick(() => {
      this.delayId = setTimeout(this.onSearch, 2000);
    });
  }

  onChange() {
    this.$emit('changedChannel');
  }

  onSearch($state) {
    this.channels = [];
    // this.currentChannels = [];
    this.page = 1;
    this.$store.commit('channel/updateChannels', []);
    this.setUrlWithQuery('/messages');
    (this.$refs.infiniteLoading as any).stateChanger.reset();
  }

  scrollToTop(duration: number = 0) {
    setTimeout(() => {
      if (this.channelList) {
        const container = this.channelList.$el;
        container.scrollTop = 0;
      }
    }, duration);
  }

  updatePriority(channel) {
    if (channel) {
      const targetChannel = this.channels.find((item) => item.id === channel.id);
      if (targetChannel) {
        targetChannel.is_high_priority = channel.is_high_priority;
      }
    }
  }

  @Watch('currentChannel', { deep: true })
  onChangeCurrentChannel(oldValue, newValue) {
    if (oldValue && oldValue.id && newValue && newValue.state && this.isChangeChannels === true) {
      this.resetData();
      this.$store.dispatch('channel/setChangeChannels', false);
    }

    // this.updatePriority(value);
    // switch (this.searchOption) {
    //   case 'unread_count':
    //     if (value.message_unread_count === 0) {
    //       this.removeFromListChannel(value);
    //     } else {
    //       this.addToListChannel(value);
    //     }
    //     break;

    //   case 'is_high_priority':
    //     if (value.is_high_priority) {
    //       this.addToListChannel(value);
    //     } else {
    //       this.removeFromListChannel(value);
    //     }
    //     break;
    // }
  }

  @Watch('channels', { deep: true })
  onChangeChannels(data) {
    this.channels = data;
    this.$nextTick( async () => {
      const channelId = this.$route.params.channelId;
      if (channelId && !this.query) {
        const { commit } = this.$store;
        const channel = this.channels.find((item) => item.id === Number(channelId));
        if (channel) {
          commit('channel/updateActiveChannel', channel);
        } else {
          commit('channel/updateActiveChannel', null);
        }
      }
      if (this.page === 1) {
        this.scrollToTop(200);
      }
    });
  }

  private setUrlWithQuery(path: string|null = null) {
    this.$router.push({
      path: path ? path : this.$route.path,
      query: this.makeSearchParams(),
    });
  }

  private makeSearchParams() {
    return {
        state: this.searchOption.map((item) =>  item.value),
        search: this.query,
        order: this.sortOption,
        time: lodash.now().toString(),
      };
  }

  private makeQueryParams() {
    return location.search;
  }

  private setSearchDefaultState() {
    if (this.$route.query.state || this.$route.query.search || this.$route.query.state) {
      return;
    }

    if (this.currentUser.isSuperAdminRole || this.currentUser.isDoctorRole) {
      return this.searchOption = [];
    }

    if (this.currentUser.isCSRole) {
      return this.searchOption = [
        { name: USER_STATUS_NAME.IDENTITY_CHECK, value: USER_STATUS.IDENTITY_CHECK },
      ];
    }
  }

  private setInputValueFromQueryData() {
    const query = this.$route.query;
    let stateList: any = [];

    if (lodash.isString(query.state)) {
      stateList.push(query.state);
    }
    if ( lodash.isArray(query.state)) {
      stateList = query.state;
    }

    this.searchOption = this.getStateObjectList(stateList);
    this.query = query.search ? query.search.toString() : '';
    this.sortOption = query.order ? query.order.toString() : '';
  }

  private getStateObjectList(stateList) {
    return lodash.compact(
      stateList.map(
        (state) => {
          return { name: USER_STATUS_NAME[state?.toUpperCase()], value: state};
        },
      ),
    );
  }

  private getOptions = () => {
    let data: any = [];
    Object.keys(USER_STATUS).map((name) => {
      data = [...data, { name: USER_STATUS_NAME[name], value: USER_STATUS[name]}];
    });

    return data;
  }

  private isActiveOption(option) {
    return this.searchOption === option ? 'active' : '';
  }

  private addToListChannel(channel) {
    const index = this.channels.findIndex((item) => item.id === channel.id);
    if (index === -1) {
      this.channels.unshift(channel);
    }
  }

  private removeFromListChannel(channel) {
    const index = this.channels.findIndex((item) => item.id === channel.id);
    if (index > -1) {
      this.channels.splice(index, 1);
    }
  }

  private resetData() {
    this.channels = [];
    this.page = 1;
    (this.$refs.infiniteLoading as any).stateChanger.reset();
  }

  private setActiveChannel(channels, hasChannel =  true) {
    const channelId = this.$route.params.channelId;

    if (hasChannel) {
      if (channelId && Number(channelId) === this.currentChannel.id) {
        const channel = channels.find((c) => c.id === Number(channelId));
        if (!channel) { return; }
        this.$store.commit('channel/updateActiveChannel', channel);
      }
    } else {
      this.$store.commit('channel/updateActiveChannel', null);
    }
  }

  private async getListChannel($state) {
    try {
      const CancelToken = axios.CancelToken;
      this.request = CancelToken.source();
      this.isLoading = true;
      let params: any = {
        per_page: 20,
        page: this.page,
        with: 'users,message_unread_count,latest_message,suggest_plan',
        order: '-latest_message_at',
      };
      if (this.query) {
        params = {
          ...params,
          manager_query: this.query,
        };
      }
      if (this.searchOption && this.searchOption.length > 0) {
        let statesName: any = [];
        this.searchOption.length > 0 && this.searchOption.map((data) => {
          statesName = [...statesName, data.value];
        });
        statesName.filter((data) => data.value !== '');

        if (statesName.length > 0) {
          params = {
            ...params,
            state: statesName.length > 0 ? statesName : [],
          };
        }
      }
      if (this.sortOption) {
        const sortData = this.sortOption;
        params = {
          ...params,
          order: sortData,
        };
      }

      const response = await ApiHelper.getApi('ChannelApi').getList(params, this.request.token);
      this.isLoading = false;
      this.totalPage = response.last_page;
      if (response.data.length) {
        // this.setActiveChannel(response.data, false);
        this.channels.push(...response.data);
        this.page === this.totalPage ? $state && $state.complete() : this.page += 1;
        this.$store.commit('channel/updateChannels', this.channels);
        this.$store.dispatch('channel/setActiveChannel', false);
        const channelId = this.$route.params.channelId;
        const findChannel =  response.data.find((data) => data.id === channelId);
        if (findChannel) {
          this.setActiveChannel(response.data, true);
        } else {
          this.setActiveChannel(response.data, false);
        }
        $state && $state.loaded();
      } else {
        $state && $state.complete();
      }

      if (this.isChangeChannels) {
        this.$store.dispatch('channel/setChangeChannels', false);
      }
    } catch (error) {
      if (error.message === 'cancel') {
        return;
      }
      this.isLoading = false;
      $state.complete();
      this.$store.dispatch('channel/setChangeChannels', false);
    }
  }
}

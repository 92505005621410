


















































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Order } from '@/models/order';
import ApiHelper from 'api-helper';
import Toast from '@common/helpers/toast';
import PageLoader from '@/components/common/PageLoader.vue';

@Component({
  components: {
    PageLoader,
  },
  data() {
    return {};
  },
  filters: {
    formatNum: (value) => {
      let formatedString = '0';
      if (value) {
        formatedString = value
          .toString()
          .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
      }

      return `${formatedString}円`;
    },
  },
})
export default class ModalReopen extends Vue {
  @Prop(Object) readonly order: Order;
  private isLoading: boolean = false;

  private showReopen() {
    this.$modal.show('modalReopen');
  }

  private hiddenReopen() {
    this.$modal.hide('modalReopen');
  }

  private reopenOrder() {
    this.isLoading = true;
    const orderId = this.order.id;
    ApiHelper.getApi('DoctorMouthApi')
      .reopenOrder(orderId)
      .then(() => {
        Toast.success('再開をしました。');
        this.hiddenReopen();
        this.$emit('fetchOrder');
        this.isLoading = false;
      })
      .catch((error) => {
        this.isLoading = false;
        Toast.error(error.message);
      });
  }
}























// Icons
import Listicon from '@/assets/img/inventory-control-list.svg';
// Utils
import Toast from '@common/helpers/toast';
// Components
import ContentLayout from '@components/layouts/Content.vue';
import ApiHelper from 'api-helper';
import { Component, Vue, Watch } from 'vue-property-decorator';
import StockForm from './components/StockForm.vue';
import moment from 'moment';

@Component({
  components: {
    ContentLayout,
    StockForm,
  },
})
export default class NewStock extends Vue {
  iconHeader: any = Listicon;
  isSubmitting = false;
  isLoading: boolean = false;
  selectedMonth: string =
    (this.$route.query.month as string) || moment().format('YYYY-MM');

  dataForm = {
    medicine_id: '',
    unit: '',
    stock_dailies: [],
  };

  get computedForm() {
    return this.$refs.dataForm as any;
  }

  handleChange(name, value) {
    this.$set(this.dataForm, name, value);
  }

  handleChangeSelectedMonth(value) {
    this.selectedMonth = value;
    this.$router.replace({
      path: this.$route.path,
      query: { month: this.selectedMonth },
    });
  }

  handleCancel() {
    return this.$router.push({
      name: 'stocks',
      query: { month: this.selectedMonth },
    });
  }

  async handleSubmit() {
    const valid = await this.computedForm.validate();
    if (valid) {
      this.dataForm.stock_dailies = this.dataForm.stock_dailies.filter(
        (item: any) => item.medicine_received || item.medicine_shiped,
      );

      try {
        this.isSubmitting = true;
        await ApiHelper.getApi('StockApi').create(this.dataForm);
        Toast.success('登録しました');
        this.handleCancel();
      } catch (error) {
        const data = error.response.data;
        Toast.error(data.message);
        if (error.response.status === 422) {
          this.computedForm.setErrors(error.response.data.errors);
          return;
        }
      } finally {
        this.isSubmitting = false;
      }
    }
  }
}

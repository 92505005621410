





























































import { Component, Vue } from 'vue-property-decorator';
import ApiHelper from 'api-helper';
import Toast from '@common/helpers/toast';
import { State } from 'vuex-class';
import moment from 'moment';

import { formatDateJp } from '@common/app.config';
import { User } from '@/models/user';

@Component({})
export default class DeliveryMedicines extends Vue {
[x: string]: any;

  @State((state) => state.auth.currentUser) currentUser!: User;
  public isLoading: boolean = true;
  public deliveryDetail: any = {};


  public formatDateJapan = formatDateJp;

  get queryParams() {
    return {
      with: 'order,user,order.plan,order.plan.medicines,image',
    };
  }

  mounted() {
    this.getDeliveryDetail();
  }

  getDeliveryDetail() {
    this.isLoading = true;

    ApiHelper.getApi('DeliveryApi')
      .getDeliveryDetail(this.$route.params.id, this.queryParams)
      .then((res: any) => {
        this.deliveryDetail = res;
      })
      .catch((error: any) => {
        Toast.error(error.response.data.message);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}

import { getFormData } from '@/common/utils';
import { Deserializable } from '@common/interfaces/deserializable';

export interface Doctor {
  id: number | null;
  name: string;
  name_kana: string;
}

export interface QueryDoctor {
  page?: number;
  per_page?: number;
  order?: string;
  with?: string;
  doctor_id?: string;
  doctor_name?: string;
  clinic_id?: number | string;
  clinic_name?: string;
  reservation_type?: number | null;
  shift_date?: string;
  start_time?: string;
  end_time?: string;
  current_page?: string;
}

export interface Clinic {
  id: number | null;
  name: string;
  name_kana: string;
}

export interface DataForm {
  id?: number | null;
  doctor_name: string;
  doctor_name_kana: string;
  doctor: Doctor;
  clinic: any;
  doctor_phone: string;
  doctor_email: string;
  password?: string | undefined;
  password_confirmation?: string | undefined;
  avatar: string;
  created_at?: string;
  updated_at?: string;
  license_code?: string;
  avatar_url?: string;
  priority?: number | undefined;
}

export interface ModelDoctor {
  id: number;
  doxle_id: number | null;
  name: string;
  name_kana: string;
  phone: string;
  email: string;
  avatar: string;
  created_at: string;
  updated_at: string;
}

export class DoctorModel implements Deserializable<DoctorModel>, ModelDoctor {
  id!: number;
  doxle_id!: number | null;
  name!: string;
  name_kana!: string;
  phone!: string;
  email!: string;
  avatar!: string;
  created_at!: string;
  updated_at!: string;

  deserialize(input: Partial<ModelDoctor>): DoctorModel {
    Object.assign(this, input);

    return this;
  }

  formJSONDataHaveImage(data) {
    if (data.avatar) {
      data.image = data.avatar;
    }

    const formData = getFormData(data);

    return formData;
  }
}

export interface ModelDoctorDetail {
  id: number | null;
  uuid: string;
  doxle_id: number | null;
  name: string;
  name_kana: string;
  clinics: Clinic[];
  phone: string;
  email: string;
  avatar: string;
  avatar_url: string;
  created_at: string;
  updated_at: string;
  license?: {
    license_code: string;
    license_url: string;
    license_file: string;
  };
}

export const defaultDoctorDetail: ModelDoctorDetail = {
  id: null,
  uuid: '',
  doxle_id: null,
  name: '',
  name_kana: '',
  clinics: [],
  phone: '',
  email: '',
  avatar: '',
  avatar_url: '',
  created_at: '',
  updated_at: '',
  license: {
    license_code: '',
    license_url: '',
    license_file: '',
  },
};

export const defaultDataForm: DataForm = {
  doctor_name: '',
  doctor_name_kana: '',
  doctor: {
    id: null,
    name: '',
    name_kana: '',
  },
  clinic: [],
  doctor_phone: '',
  doctor_email: '',
  password: '',
  password_confirmation: '',
  avatar: '',
  license_code: '',
};

export const defaultDataFormEdit: DataForm = {
  id: null,
  doctor_name: '',
  doctor_name_kana: '',
  doctor: {
    id: null,
    name: '',
    name_kana: '',
  },
  clinic: [],
  doctor_phone: '',
  doctor_email: '',
  avatar: '',
  created_at: '',
  updated_at: '',
  license_code: '',
};


















import { Vue, Component } from 'vue-property-decorator';
import { State } from 'vuex-class';
import PatientList from '@components/v2/patient/PatientList.vue';

@Component({
  components: {
    PatientList,
  },
  computed: {},
})
export default class Message extends Vue {
  @State((state) => state.patient_list.data) patientList!: any;
  private isHiddenSidebarPatientList = false;

  created() {
    this.listenEventSocket();
  }

  beforeDestroy() {
    this.$off('UpdateUnreadCount');
    this.$off('UpdateLatestMessage');
    this.$off('UpdateUserState');
  }

  private listenEventSocket() {
    // listen event when user send new message.
    this.$on(`UpdateUnreadCount`, (data) => {
      this.$store.commit('patient_list/setUnreadCountByChannelId', data);
    });

    // listen event when user send new message.
    this.$on(`UpdateLatestMessage`, (data) => {
      this.$store.commit('patient_list/setLatestMessageByChannelId', data);
    });

    // listen event when user state is changed.
    this.$on('UpdateUserState', (data) => {
      const payload = { id: data.user_id, state: data.user_state };
      this.$store.commit('patient_list/setPatientState', payload);
    });
  }

  private hiddenSidebarPatientList(isHidden: boolean = true) {
    this.isHiddenSidebarPatientList = isHidden;
    // if hidden is false and patientList is null then reload patient list
    if (!isHidden && this.patientList.length == 0) {
      (this.$refs.patientListRef as any)?.reloadPatientList();
    }
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ContentLayout',{attrs:{"icon":_vm.iconHeader,"name":"クリニック詳細"}},[_c('div',{staticClass:"container-fluid wrap-create"},[_c('div',{staticClass:"mx-2"},[_c('ValidationObserver',{ref:"createClinicForm",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"group-info-clinic"},[_c('table',{staticClass:"table table-bordered table-striped"},[_c('tr',[_c('th',[_vm._v("UUID")]),_c('td',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"uuid","rules":"required|clinic_uuid|max:32","tag":"div","customMessages":_vm.clinicNameErrorMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.createClinicForm.uuid),expression:"createClinicForm.uuid"}],staticClass:"form-control",class:{'is-invalid': !!errors.length},attrs:{"type":"text","placeholder":"入力してください"},domProps:{"value":(_vm.createClinicForm.uuid)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.createClinicForm, "uuid", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('tr',[_c('th',[_vm._v("クリニック/サロン名 ")]),_c('td',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"name","rules":"required|max:30","tag":"div","customMessages":_vm.clinicNameErrorMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.createClinicForm.name),expression:"createClinicForm.name"}],staticClass:"form-control",class:{'is-invalid': !!errors.length},attrs:{"type":"text","placeholder":"入力してください"},domProps:{"value":(_vm.createClinicForm.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.createClinicForm, "name", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('tr',[_c('th',[_vm._v("クリニック/サロン名（カナ）")]),_c('td',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"name_kana","rules":"required|kana|max:100","tag":"div","customMessages":_vm.clinicNameKanaErrorMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.createClinicForm.name_kana),expression:"createClinicForm.name_kana"}],staticClass:"form-control",class:{'is-invalid': !!errors.length},attrs:{"type":"text","placeholder":"入力してください"},domProps:{"value":(_vm.createClinicForm.name_kana)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.createClinicForm, "name_kana", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('tr',[_c('th',[_vm._v("電話番号")]),_c('td',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"phone","rules":{ numeric: true, required: true, min: 10, max: 11, phone: true },"tag":"div","customMessages":_vm.phoneErrorMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.createClinicForm.phone),expression:"createClinicForm.phone"}],staticClass:"form-control",class:{'is-invalid': !!errors.length},attrs:{"type":"text","placeholder":"入力してください"},domProps:{"value":(_vm.createClinicForm.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.createClinicForm, "phone", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('tr',[_c('th',[_vm._v("郵便番号 ")]),_c('td',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"mode":_vm.input,"name":"postal_code","rules":{ required:  true, regex: /^\d{3}\d{4}$/, postal_code: true },"tag":"div","customMessages":_vm.postalCodeErrorMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.createClinicForm.postal_code),expression:"createClinicForm.postal_code"}],class:("form-control " + (!!errors.length ? 'is-invalid' : '')),attrs:{"type":"text","placeholder":"入力してください","maxLength":"7"},domProps:{"value":(_vm.createClinicForm.postal_code)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.createClinicForm, "postal_code", $event.target.value)},function($event){_vm.typeValidate = 'input'}]}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('tr',[_c('th',[_vm._v("都道府県 ")]),_c('td',{staticClass:"form-group"},[_c('ValidationProvider',{staticClass:"w-50",attrs:{"name":"prefecture_id","rules":"required|max:30","tag":"div","customMessages":_vm.prefectureErrorMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"prefecture-select",class:{'is-invalid': !!errors.length},attrs:{"label":"title","placeholder":"選択してください","options":_vm.listPrefectureOption,"reduce":function (option) { return ("" + (option.id)); }},model:{value:(_vm.createClinicForm.prefecture_id),callback:function ($$v) {_vm.$set(_vm.createClinicForm, "prefecture_id", $$v)},expression:"createClinicForm.prefecture_id"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("一致する項目がありません")])]),(errors.length)?_c('div',{staticClass:"invalid-feedback",staticStyle:{"display":"block"}},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)]),_c('tr',[_c('th',[_vm._v("市区町村")]),_c('td',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"city_id","rules":"required|max:30","tag":"div","customMessages":_vm.cityErrorMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.createClinicForm.city_id),expression:"createClinicForm.city_id"}],staticClass:"form-control",class:{'is-invalid': !!errors.length},attrs:{"type":"text","placeholder":"入力してください"},domProps:{"value":(_vm.createClinicForm.city_id)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.createClinicForm, "city_id", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('tr',[_c('th',[_vm._v("番地 ")]),_c('td',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"address","rules":"required|max:30","tag":"div","customMessages":_vm.addressErrorMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.createClinicForm.address),expression:"createClinicForm.address"}],staticClass:"form-control",class:{'is-invalid': !!errors.length},attrs:{"type":"text","placeholder":"入力してください"},domProps:{"value":(_vm.createClinicForm.address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.createClinicForm, "address", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('tr',[_c('th',[_vm._v("建物名")]),_c('td',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"building_name","rules":"max:30","tag":"div","customMessages":_vm.buildingNameErrorMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.createClinicForm.building_name),expression:"createClinicForm.building_name"}],staticClass:"form-control",class:{'is-invalid': !!errors.length},attrs:{"type":"text","placeholder":"入力してください"},domProps:{"value":(_vm.createClinicForm.building_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.createClinicForm, "building_name", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('tr',[_c('th',[_vm._v("区分")]),_c('td',{staticClass:"form-group"},[_c('ValidationProvider',{staticClass:"w-50",attrs:{"name":"type","rules":"required|max:30","tag":"div","customMessages":_vm.typeErrorMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"type-select",class:{'is-invalid': !!errors.length},attrs:{"value":"id","label":"title","placeholder":"選択してください","options":_vm.listTypeOption,"reduce":function (option) { return ("" + (option.id)); }},model:{value:(_vm.createClinicForm.type),callback:function ($$v) {_vm.$set(_vm.createClinicForm, "type", $$v)},expression:"createClinicForm.type"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("一致する項目がありません")])]),(errors.length)?_c('div',{staticClass:"invalid-feedback",staticStyle:{"display":"block"}},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)]),_c('tr',[_c('th',[_vm._v("GTM ID")]),_c('td',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"gtm_id","rules":"max:255","tag":"div","customMessages":_vm.gtmIdErrorMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.createClinicForm.gtm_id),expression:"createClinicForm.gtm_id",modifiers:{"trim":true}}],staticClass:"form-control",class:{'is-invalid': !!errors.length},attrs:{"type":"text","placeholder":"入力してください"},domProps:{"value":(_vm.createClinicForm.gtm_id)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.createClinicForm, "gtm_id", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])])]),_c('div',{staticClass:"form-group d-flex justify-content-center button-bottom"},[_c('button',{staticClass:"btn btn-cancel",attrs:{"type":"button"},on:{"click":_vm.openCancelCreateClinic}},[_vm._v("戻る")]),_c('button',{staticClass:"btn btn-submit",attrs:{"type":"button","disabled":invalid || _vm.isSubmiting},on:{"click":_vm.createClinic}},[_vm._v("保存")])])]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }
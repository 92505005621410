



































































































































































































import _ from 'lodash';
import { Vue, Component } from 'vue-property-decorator';
import ApiHelper from 'api-helper';
import moment from 'moment';
import { State } from 'vuex-class';

import { Patient } from '@/models/patient';
import Toast from '@common/helpers/toast';
import { USER_TREATMENT_STATE, USER_TREATMENT_STATE_CAN_RESERVE } from '@/common/constants/auth';
import PageLoader from '@components/common/PageLoader.vue';
import BasicModal from '@components/common/BasicModal.vue';
import { formatDateJp } from '@/common/app.config';
import PrescriptionPopup from '@/components/v2/patient/message/partials/PrescriptionPopup.vue';
import DateTimePickerPopup from '@/components/v2/patient/message/partials/DateTimePickerPopup.vue';
import GenerateLinkModal from '@/components/v2/patient/message/partials/GenerateLinkModal.vue';
import { UserTreatment } from '@/models/user-treatment';
import { User } from '@/models/user';
import { DOCTOR_REQUEST_TYPE } from '@/common/constants/reservation';

@Component({
  components: {
    PageLoader,
    BasicModal,
    PrescriptionPopup,
    DateTimePickerPopup,
    GenerateLinkModal,
  },
  computed: {},
  filters: {
    formatTime(value) {
      return (value ? moment(value) : moment()).format('YYYY年 MM月DD日 HH:mm');
    },
    formatDate(value) {
      return (value ? moment(value) : moment()).format('YYYY年MM月DD日');
    },
  },
})
export default class QuestionInfoChannel extends Vue {
  @State((state) => state.auth.currentUser) currentUser!: User;
  @State((state) => state.patient_detail.data) currentPatient!: Patient;
  @State((state) => state.treatment_detail.data) activeTreatment: UserTreatment;
  private generatedLink: string = '';
  private isModalVisible: boolean = false;
  private isDateTimePickerVisible: boolean = false;
  private reservationDate: string = '';
  // ********************* getters ********************* //
  get treatmentName() {
    return this.activeTreatment.menu?.name;
  }

  get interviewDate() {
    return this.activeTreatment.onlyOneReservation?.latest_answer?.created_at;
  }

  private get stateNotice() {
    if (
      this.activeTreatment.state ===
      USER_TREATMENT_STATE.CHOICE_INTERVIEW_METHOD
    ) {
      return '診療方法を選択してください';
    }
    if (
      this.activeTreatment.state ===
      USER_TREATMENT_STATE.CHAT_INTERVIEW_SCHEDULED
    ) {
      return 'チャット診療中';
    }
    if (
      this.activeTreatment.state ===
      USER_TREATMENT_STATE.VIDEO_INTERVIEW_SCHEDULED
    ) {
      return 'ビデオ診療';
    }
    if (
      this.activeTreatment.state ===
      USER_TREATMENT_STATE.CONFIRMING_PRESCRIPTION
    ) {
      return '診療完了';
    }

    return '診療方法を選択してください';
  }

  private get checkButtonReserve() {
    return this.activeTreatment.feedback_type === USER_TREATMENT_STATE.DOCTOR_RESERVE_PRESCRIPTION;
  }

  private get checkDoctorReserve() {
    return !USER_TREATMENT_STATE_CAN_RESERVE.includes(this.activeTreatment.state);
  }

  private get isDisableInterview() {
    return (
      this.activeTreatment.state !==
      USER_TREATMENT_STATE.CHOICE_INTERVIEW_METHOD
    );
  }
  private formatDateJp = formatDateJp;

  private USER_TREATMENT_STATE = USER_TREATMENT_STATE;
  private isChangeState: boolean = false;
  private patientAnswer: any[] = [];

  get isDateTimePickerDisabled() {
    const reservation = this.activeTreatment.reservations?.[0];

    return (
      [
        USER_TREATMENT_STATE.CANCELED,
        USER_TREATMENT_STATE.DOCTOR_CANCEL_PRESCRIPTION,
        USER_TREATMENT_STATE.CHOICE_INTERVIEW_METHOD,
        USER_TREATMENT_STATE.VIDEO_INTERVIEW_SCHEDULED,
      ].includes(this.activeTreatment.state) ||
      !reservation ||
      reservation?.doctor_request_type === DOCTOR_REQUEST_TYPE.DOCTOR_DEFAULT ||
      (reservation?.have_doctor_request === true &&
        reservation?.doctor_request_type === DOCTOR_REQUEST_TYPE.DOCTOR_LINK)
    );
  }

  get isDateTimePickerWhenUserAcceptDisabled() {
    const reservation = this.activeTreatment.reservations?.[0];

    return (
      (reservation?.have_doctor_request === true &&
        reservation?.doctor_request_type === DOCTOR_REQUEST_TYPE.DOCTOR_SELECT_SCHEDULE &&
        !reservation?.doctor_request_date)
    );
  }

  get isGenerateLinkDisabled() {
    const reservation = this.activeTreatment.reservations?.[0];
    return (
      [
        USER_TREATMENT_STATE.CANCELED,
        USER_TREATMENT_STATE.DOCTOR_CANCEL_PRESCRIPTION,
        USER_TREATMENT_STATE.CHOICE_INTERVIEW_METHOD,
        USER_TREATMENT_STATE.VIDEO_INTERVIEW_SCHEDULED,
      ].includes(this.activeTreatment.state) ||
      !reservation ||
      reservation?.doctor_request_type === DOCTOR_REQUEST_TYPE.DOCTOR_DEFAULT ||
      reservation?.doctor_request_type === DOCTOR_REQUEST_TYPE.DOCTOR_SELECT_SCHEDULE
    );
  }

  get isDisableVideoCall() {
    const reservation = this.activeTreatment.reservations?.[0];
    return this.activeTreatment.state !==
        USER_TREATMENT_STATE.CHOICE_INTERVIEW_METHOD && reservation?.have_doctor_request === true;
  }

  async created() {
    await this.getAnswerData();
  }

  async getAnswerData() {
    this.$store.commit('treatment_detail/setLoadingChild', true);
    await ApiHelper.getApi('PatientV2Api')
      .getReservation(this.currentPatient.id, {
        user_treatment_id: this.activeTreatment.id,
        with: 'answers,userTreatment.menu',
      })
      .then((res: any) => {
        this.patientAnswer = res.data.answers;
      })
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {
        this.$store.commit('treatment_detail/setLoadingChild', false);
      });
  }

  onChangeUserStatus(status) {
    this.isChangeState = true;
    const payload = {
      state: status,
      user_treatment_id: this.activeTreatment.id,
    };
    ApiHelper.getApi('PatientV2Api')
      .changeUserTreatmentState(this.currentPatient.id, payload)
      .then((res: any) => {
        this.updateUserTreatmentState(res.data);
        switch (status) {
          case USER_TREATMENT_STATE.DOCTOR_RESERVE_PRESCRIPTION:
            Toast.success('処方を保留しました。');
            break;
          case USER_TREATMENT_STATE.DOCTOR_CANCEL_PRESCRIPTION:
            Toast.success('処方をキャンセルしました');
            break;
          default:
            Toast.success('患者に通知しました。');
            break;
        }
      })
      .catch((error) => {
        Toast.error(error.response.data.message);
      })
      .finally(() => {
        this.isChangeState = false;
      });
  }

  openForm() {
    this.$modal.show('prescriptionForm');
  }

  isDisabledAfterChoose(status) {
    switch (status) {
      case USER_TREATMENT_STATE.DOCTOR_RESERVE_PRESCRIPTION:
        return this.activeTreatment.feedback_type !== status &&
          this.activeTreatment.state !== USER_TREATMENT_STATE.CHOICE_INTERVIEW_METHOD;
        break;
      case USER_TREATMENT_STATE.DOCTOR_CANCEL_PRESCRIPTION:
        return this.activeTreatment.state !== status &&
          this.activeTreatment.state !== USER_TREATMENT_STATE.CHOICE_INTERVIEW_METHOD;
        break;
      default:
        return this.activeTreatment.state !== status &&
          this.activeTreatment.state !== USER_TREATMENT_STATE.CHOICE_INTERVIEW_METHOD;
        break;
    }
  }

  handleDateTimeSelection(selection: { date: string; time: string }) {
    const reservationDate = moment(
      `${selection.date} ${selection.time}`,
    ).format('YYYY-MM-DD HH:mm:ss');

    const body = {
      user_id: this.currentPatient.id,
      user_treatment_id: this.activeTreatment.id,
      reservation_date: reservationDate,
    };

    this.suggestReservationDate(body);
  }

  async suggestReservationDate(body: {
    user_id: number | null;
    user_treatment_id: number;
    reservation_date: string;
  }) {
    try {
      await ApiHelper.getApi('DoctorFeedbackApi').doctorSuggestReservationDate(
        body,
      );
      this.isDateTimePickerVisible = false;
      const { patientId } = this.$route.params;
      await this.$store.dispatch('patient_detail/getPatient', { id: patientId });

      this.setRouteAfterSuggest();
      Toast.success('ビデオ診察の日程を設定しました。');
    } catch (error) {
      const message = _.get(error, 'response.data.message', 'まだ保存できません。');
      Toast.error(message);
    }
  }

  async generateLink() {
    this.isModalVisible = true;
    const body = {
      user_id: this.currentPatient.id,
      user_treatment_id: this.activeTreatment.id,
    };

    try {
      const response = await ApiHelper.getApi(
        'DoctorFeedbackApi',
      ).doctorGenerateLink(body);
      this.generatedLink = response.data.link;
    } catch (error) {
      console.log(error);
    }
  }

  private updateUserTreatmentState(data) {
    this.activeTreatment.feedback_type = data.feedback_type;
    this.activeTreatment.state = data.state;
    this.$store.commit('user_treatment_list/setUserTreatmentState', data);
  }

  private handleWhenCreateFeedback(doctorFeedback: any) {
    this.updateUserTreatmentState(doctorFeedback.user_treatment);
  }

  // ********************* methods ********************* //
  private changeState() {
    this.isChangeState = true;
  }

  private async closeModal() {
    this.isModalVisible = false;
    const { patientId } = this.$route.params;
    await this.$store.dispatch('patient_detail/getPatient', { id: patientId });

    this.setRouteAfterSuggest();
  }

  private openDateTimePicker() {
    const reservation = this.activeTreatment.reservations?.[0];
    this.reservationDate = reservation?.doctor_request_date ?? '';
    this.isDateTimePickerVisible = true;
  }

  private closeDateTimePicker() {
    this.reservationDate = '';
    this.isDateTimePickerVisible = false;
  }

  private setRouteAfterSuggest() {
    const query = {
      ...this.$route.query,
      user_treatment_id: `${this.activeTreatment.id}`,
    };

    this.$router.push({
      path: this.$route.path,
      query,
    });
  }
}



















































































































































import { Vue, Component } from 'vue-property-decorator';
import Toast from '@common/helpers/toast';
import ApiHelper from 'api-helper';
import Pagination from '@components/common/Pagination.vue';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faPlus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import PageLoader from '@components/common/PageLoader.vue';
import ContentLayout from '@components/layouts/Content.vue';
import SalesManagement from '@/assets/img/sales-management-icon.svg';
import { config } from '@/common/app.config';
import DatePicker from 'vue2-datepicker';
import moment from 'moment';

@Component({
  components: {
    Pagination,
    PageLoader,
    ContentLayout,
    DatePicker,
  },
  data() {
    return {
      clinic_id: this.$route.params.clinic_id,
      plan_id: this.$route.params.plan_id,
      clinic: {},
      plan: {},
      revenueDetail: {},
      commonData: {},
      iconHeader: SalesManagement,
      appConfig: config,
    };
  },
  filters: {
    formatNum: (value) => {
      let formatedString = '0';
      if (value) {
        formatedString = value
          .toString()
          .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
      }

      return `${formatedString}`;
    },
  },
})
export default class Index extends Vue {
  public clinic!: any;
  public plan!: any;
  public revenueDetail!: any;
  public commonData!: any;
  public isLoading: boolean = false;
  public date = {
    start_date: this.$route.query.start_date || '',
    end_date: this.$route.query.end_date || '',
  };

  mounted() {
    library.add(faPlus);
    library.add(faPlusCircle);
    dom.watch();
  }

  created() {
    this.detail();
  }

  private async detail() {
    this.isLoading = true;
    await ApiHelper.getApi('SalesManagementApi')
      .showByPlan(
        this.$route.params.clinic_id,
        this.$route.params.plan_id,
        this.date,
      )
      .then((res: any) => {
        this.clinic = res.data.clinic;
        this.plan = res.data.plan;
        this.revenueDetail = res.data.revenues;
        this.commonData = res.data;
      })
      .catch((error: any) => {
        this.toast(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  private notAfterDate(date) {
    if (this.date.end_date) {
      return (
        moment(date).format('YYYY-MM-DD') >
        moment(this.date.end_date.toString()).format('YYYY-MM-DD')
      );
    }
  }

  private notBeforeDate(date) {
    if (this.date.start_date) {
      return (
        moment(date).format('YYYY-MM-DD') <
        moment(this.date.start_date.toString()).format('YYYY-MM-DD')
      );
    }
  }

  private toast(error: any) {
    const {
      errors: { id: idError },
    } = error.response.data;
    if (idError) {
      return Toast.error('英数字で入力してください。');
    }
    Toast.error(error.response.data.message);
  }

  private clearDate(name) {
    if (this.date[name]) {
      this.date[name] = '';
    }
  }
}








































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { State } from 'vuex-class';
import 'vue2-datepicker/index.css';
import DatePicker from 'vue2-datepicker';
import ApiHelper from 'api-helper';
import Toast from '@common/helpers/toast';
import moment from 'moment';
import { PLAN_PRODUCT_UNITS } from '@/common/constants/plan';
import PageLoader from '@components/common/PageLoader.vue';
import _ from 'lodash';

@Component({
  components: {
    DatePicker,
    PageLoader,
  },
})
export default class ListStock extends Vue {
  @State((state) => state.auth.currentUser) currentUser!: any;
  isLoading = false;

  filter = {
    with: 'medicine,stockDailyRecords',
    id: this.$route.query.id || null,
    medicine_name: this.$route.query.medicine_name || null,
    date: '',
  };

  stocks: any = [];
  selectedDate: string = '';
  daysInMonth: number[] = [];

  selectDateTimeout: any = null;

  get queryParams() {
    if (!this.filter.id) {
      this.filter.id = null;
    }

    return {
      ...this.filter,
    };
  }

  get isEmptyStock(): boolean {
    return this.stocks && !this.stocks.length;
  }

  getStockList() {
    this.filter.date = this.selectedDate;

    if (this.isLoading) {
      return;
    }

    this.stocks = [];
    this.isLoading = true;

    this.setUrlWithQuery();

    ApiHelper.getApi('StockApi')
      .getStockList(this.queryParams)
      .then((response: any) => {
        this.stocks = response.data;
      })
      .catch((error: any) => {
        Toast.error(error.response.data.message);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  mounted() {
    this.getStockList();
  }

  created() {
    this.setDefaultDate();
  }

  setDefaultDate() {
    const currentDate = this.$route.query.month ? moment((this.$route.query.month as string)) : moment();
    this.selectedDate = currentDate.format('YYYY-MM');

    const lastDayOnMonth = currentDate.endOf('month').date();

    for (let i: number = 1; i <= lastDayOnMonth; i++) {
      this.daysInMonth.push(i);
    }
  }

  getUnitName(value: string) {
    return PLAN_PRODUCT_UNITS.find((unit) => unit.value === value)?.name;
  }

  getMedicineReceived(stock, day) {
    const stockDaily = stock?.stock_daily_records?.find(
      (s) => s.date == this.formatDateByDay(day),
    );
    return stockDaily?.medicine_received || 0;
  }

  getMedicineShiped(stock, day) {
    const stockDaily = stock?.stock_daily_records?.find(
      (s) => s.date == this.formatDateByDay(day),
    );
    return stockDaily?.medicine_shiped || 0;
  }

  getMedicineOnHand(stock, day) {
    const stockDaily = stock?.stock_daily_records?.find(
      (s) => s.date == this.formatDateByDay(day),
    );
    return stockDaily?.medicine_on_hand || 0;
  }

  formatDateByDay(day) {
    day = day < 10 ? `0${day}` : day;
    return `${this.selectedDate}-${day}`;
  }

  @Watch('selectedDate', { deep: true })
  watchSelectedDate(_, oldValue) {
    if (!oldValue) {
      return;
    }

    if (this.selectDateTimeout) {
      clearTimeout(this.selectDateTimeout);
    }

    this.selectDateTimeout = setTimeout(() => {
      this.getStockList();
      const lastDayOnMonth = moment(this.selectedDate).endOf('month').date();
      this.daysInMonth = [];
      for (let i: number = 1; i <= lastDayOnMonth; i++) {
        this.daysInMonth.push(i);
      }
    }, 500);
  }

  goToDetail(id) {
    const queryParams = _.pickBy(
      {
        ...this.$route.query,
        id: this.queryParams.id,
        medicine_name: this.queryParams.medicine_name,
        month: this.selectedDate,
      },
      _.identity,
    );

    this.$router.push({
      name: 'stocks.detail',
      params: { id },
      query: queryParams,
    });
  }

  private setUrlWithQuery() {
    const queryParams = _.pickBy(
      {
        ...this.$route.query,
        id: this.queryParams.id,
        medicine_name: this.queryParams.medicine_name,
        date: this.queryParams.date,
      },
      _.identity,
    );

    this.$router.push({
      path: this.$route.path,
      query: queryParams,
    });
  }
}














































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faCameraRetro } from '@fortawesome/free-solid-svg-icons';
import DragDropFileUpload from '@/components/common/DragDropFileUpload.vue';
import FormAction from '@/components/common/FormAction.vue';
import BaseInput from '@/components/common/BaseInput.vue';
import BaseTextarea from '@/components/common/BaseTextarea.vue';
// Constants
import { Roles } from '@/common/app.config';
import MedicineFormItem from './MedicineFormItem.vue';

@Component({
  components: {
    DragDropFileUpload,
    FormAction,
    BaseInput,
    BaseTextarea,
    MedicineFormItem,
  },
})
export default class MedicineForm extends Vue {
  @Prop([String, Number]) medicineId: string | number;
  @Prop(Object) dataForm!: any;
  @Prop(Boolean) disabledBtnCancel!: boolean;
  @Prop(Boolean) disabledBtnSubmit!: boolean;

  @State((state) => state.auth.currentUser) currentUser!: any;

  isSubmiting = false;
  isLoading: boolean = false;
  isInvalidDosage: boolean = false;

  async created() {
    library.add(faCameraRetro);
    dom.watch();
  }

  get isSuperAdminRole() {
    return this.currentUser.isSuperAdminRole;
  }

  get displayOrderErrorMessages() {
    return {
      between: '1~3桁の正の数字を入力してください',
      positiveInterger: '1~3桁の正の数字を入力してください',
    };
  }

  changeIsInvalidDosage(value) {
    this.isInvalidDosage = value;
  }
}

import { Deserializable } from '@common/interfaces/deserializable';
import moment from 'moment';
import { ModelDoctor, DoctorModel } from '@/models/doctors';
import { formatDateTime } from '@/common/app.config';
import { Answer } from './answer';
import { Menu } from './menu';
import { User } from './user';

export interface ReservationInput {
  channel_token: string;
  channel_uuid: string;
  fee: string;
  id: number;
  status: string;
  created_at: string;
  doctor: ModelDoctor;
  answers: Answer[];
  menu: Menu;
  user: User;
}

export class Reservation implements Deserializable<Reservation>, ReservationInput {
  channel_token!: string;
  channel_uuid!: string;
  fee!: string;
  id!: number;
  status!: string;
  created_at: string;
  doctor: DoctorModel = new DoctorModel();
  answers: Answer[] = [];
  latest_answer?: Answer;
  doctor_request_type?: string;
  have_doctor_request?: boolean;
  doctor_request_date?: string;

  get createdAtString() {
    return this.created_at ? moment(this.created_at).format('YYYY年MM月DD日') + 'に提出' : '';
  }
  menu: Menu = new Menu();
  user: User = new User();

  get createAtFormat() {
    return moment(this.created_at).format(formatDateTime);
  }

  deserialize(input: Partial<ReservationInput>): Reservation {
    Object.assign(this, input);

    if (input.doctor) {
      this.doctor = new DoctorModel().deserialize(input.doctor);
    }

    if (input.menu) {
      this.menu = new Menu().deserialize(input.menu);
    }

    if (input.user) {
      this.user = new User().deserialize(input.user);
    }

    if (input.answers && input.answers.length) {
      this.answers = input.answers.map((item: Answer) => new Answer().deserialize(item));
    }

    return this;
  }
}

import BaseApi from './BaseApi';

class MedicineApi extends BaseApi {
  public async create(params: any) {
    return await this.post('/medicines', params);
  }

  public fetchMedicineList(params: any) {
    return this.get('/medicines', params);
  }

  public update(medicineId: number, params: any) {
    return this.post(`/medicines/${medicineId}`, params);
  }

  public async getOne(id: number, params?: any) {
    return await this.get(`/medicines/${id}`, params);
  }

  public fetchMedicinesNotInStock(params: any) {
    return this.get('/medicines-not-in-stock', params);
  }

  protected getSpecificApiPrefix(): string {
    return 'managers';
  }
}

export default MedicineApi;

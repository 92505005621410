var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"medicine-form"},[_c('div',{staticClass:"medicine-form__table"},[_c('table',{staticClass:"table table-bordered table-striped"},[(_vm.medicineId)?_c('tr',[_c('th',[_vm._v("ID")]),_c('td',[_vm._v(_vm._s(_vm.medicineId))])]):_vm._e(),_c('tr',[_vm._m(0),_c('td',[_c('ValidationProvider',{attrs:{"name":"name","rules":"required|max:255","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInput',{attrs:{"value":_vm.dataForm.name,"readOnly":!_vm.isSuperAdminRole,"errorText":errors[0]},on:{"input":function($event){return _vm.$emit('onChange', 'name', $event)}}})]}}])})],1)]),_c('tr',[_c('th',[_vm._v("在庫ID")]),_c('td',[_c('ValidationProvider',{attrs:{"name":"stock_id","tag":"div"}},[_c('BaseInput',{attrs:{"value":_vm.dataForm.stock_id,"disabled":true}})],1)],1)]),_c('tr',[_vm._m(1),_c('td',[_c('MedicineFormItem',{attrs:{"medicineId":_vm.medicineId,"dataForm":_vm.dataForm},on:{"onChange":function (name, value){ return _vm.$emit('onChange', name, value); },"changeIsInvalidDosage":_vm.changeIsInvalidDosage}})],1)]),_c('tr',[_vm._m(2),_c('td',[_c('ValidationProvider',{attrs:{"name":"usage","rules":"required|max:255","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseTextarea',{attrs:{"value":_vm.dataForm.usage,"rows":"3","readOnly":!_vm.isSuperAdminRole,"errorText":errors[0]},on:{"input":function($event){return _vm.$emit('onChange', 'usage', $event.target.value)}}})]}}])})],1)]),_c('tr',[_vm._m(3),_c('td',[_c('ValidationProvider',{attrs:{"name":"about_medicine","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseTextarea',{attrs:{"value":_vm.dataForm.about_medicine,"readOnly":!_vm.isSuperAdminRole,"errorText":errors[0]},on:{"input":function($event){return _vm.$emit('onChange', 'about_medicine', $event.target.value)}}})]}}])})],1)]),_c('tr',[_c('th',[_vm._v("詳細")]),_c('td',[_c('BaseTextarea',{attrs:{"value":_vm.dataForm.detail,"readOnly":!_vm.isSuperAdminRole},on:{"input":function($event){return _vm.$emit('onChange', 'detail', $event.target.value)}}})],1)]),_c('tr',[_c('th',[_vm._v("使用上の注意")]),_c('td',[_c('BaseTextarea',{attrs:{"value":_vm.dataForm.precautions_for_use,"readOnly":!_vm.isSuperAdminRole},on:{"input":function($event){return _vm.$emit('onChange', 'precautions_for_use', $event.target.value)}}})],1)]),_c('tr',[_c('th',[_vm._v("副作用")]),_c('td',[_c('BaseTextarea',{attrs:{"value":_vm.dataForm.side_effects,"readOnly":!_vm.isSuperAdminRole},on:{"input":function($event){return _vm.$emit('onChange', 'side_effects', $event.target.value)}}})],1)]),_c('tr',[_c('th',[_vm._v("併用禁止")]),_c('td',[_c('BaseTextarea',{attrs:{"value":_vm.dataForm.prohibition_of_concomitant_use,"readOnly":!_vm.isSuperAdminRole},on:{"input":function($event){return _vm.$emit('onChange', 'prohibition_of_concomitant_use', $event.target.value)}}})],1)]),_c('tr',[_vm._m(4),_c('td',[_c('ValidationProvider',{attrs:{"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DragDropFileUpload',{staticClass:"img-upload",attrs:{"name":"image","value":_vm.dataForm.image_url,"maxSize":5,"viewMode":!_vm.isSuperAdminRole},on:{"onDelete":function($event){return _vm.$emit('deleteFile', 'image', $event)},"onChange":function($event){return _vm.$emit('uploadFile', 'image', $event)}}}),(errors.length > 0)?_c('div',{staticClass:"invalid-feedback invalid-feedback--custom"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)]),_c('tr',[_vm._m(5),_c('td',[_c('ValidationProvider',{attrs:{"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DragDropFileUpload',{staticClass:"img-upload medicine-form__logo-image",attrs:{"name":"logoImage","value":_vm.dataForm.logo_image_url,"maxSize":5,"viewMode":!_vm.isSuperAdminRole},on:{"onDelete":function($event){return _vm.$emit('deleteFile', 'logo_image', $event)},"onChange":function($event){return _vm.$emit('uploadFile', 'logo_image', $event)}}}),(errors.length > 0)?_c('div',{staticClass:"invalid-feedback invalid-feedback--custom"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)]),_c('tr',[_vm._m(6),_c('td',[_c('ValidationProvider',{attrs:{"name":"display_order","rules":"required|between:1,999|positiveInterger","tag":"div","customMessages":_vm.displayOrderErrorMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInput',{attrs:{"type":"number","value":_vm.dataForm.display_order,"readOnly":!_vm.isSuperAdminRole,"errorText":errors[0]},on:{"input":function($event){return _vm.$emit('onChange', 'display_order', $event)}}})]}}])})],1)])])]),_c('FormAction',{staticClass:"medicine-form__actions",attrs:{"disabledCancel":_vm.disabledBtnCancel,"disabledSubmit":_vm.disabledBtnSubmit || _vm.isInvalidDosage,"hiddenBtnSubmit":!_vm.isSuperAdminRole,"btnCancelText":"戻る","btnSubmitText":"保存"},on:{"onSubmit":function($event){return _vm.$emit('onSubmit')},"onCancel":function($event){return _vm.$emit('onCancel')}}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('th',[_vm._v("商品名"),_c('span',{staticClass:"text-red float-right font-weight-normal"},[_vm._v("※必須")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('th',{staticClass:"align-items-center"},[_c('div',{staticClass:"d-flex align-center"},[_vm._v(" 服用タイミングと服用数"),_c('span',{staticClass:"text-red float-right font-weight-normal"},[_vm._v("※必須")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('th',[_vm._v("用法・容量"),_c('span',{staticClass:"text-red float-right font-weight-normal"},[_vm._v("※必須")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('th',[_vm._v("説明"),_c('span',{staticClass:"text-red float-right font-weight-normal"},[_vm._v("※必須")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('th',[_vm._v("商品画像"),_c('span',{staticClass:"text-red float-right font-weight-normal"},[_vm._v("※必須")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('th',[_vm._v("商品ロゴ画像"),_c('span',{staticClass:"text-red float-right font-weight-normal"},[_vm._v("※必須")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('th',[_vm._v("表示順"),_c('span',{staticClass:"text-red float-right font-weight-normal"},[_vm._v("※必須")])])}]

export { render, staticRenderFns }
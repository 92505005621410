import { authAdminDoctor } from '@/common/constants/auth';
import ShiftSetting from '@/views/doctor/ShiftSetting.vue';

export default [
  {
    path: 'doctor-shifts',
    name: 'doctor-shift',
    component: ShiftSetting,
    meta: authAdminDoctor,
  },
];

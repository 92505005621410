












































































































































































































































































































































































// libs
import DatePicker from 'vue2-datepicker';
import lodash from 'lodash';
import { Vue, Component } from 'vue-property-decorator';
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { State } from 'vuex-class';
import 'vue2-datepicker/index.css';

// components
import ImagePrivate from '@/components/common/ImagePrivate.vue';
import UploadImageIdentification from '@/components/common/UploadImageIndentification.vue';
import LoaderComponent from '@components/common/LoaderComponent.vue';

// helpers
import Toast from '@common/helpers/toast';
import ApiHelpers from '@/api/ApiHelper';

// interfaces
import { Patient } from '@/models/patient';

// images
import imageIdentityDefault from '@/assets/img/identity.svg';

@Component({
  components: {
    DatePicker,
    ImagePrivate,
    UploadImageIdentification,
    LoaderComponent,
  },
})
export default class UpdatePatientInfoModal extends Vue {
  @State((state) => state.patient_detail.data) patient!: Patient;
  @State((state) => state.patient_detail.isLoading) isLoading!: boolean;

  private imageIdentityDefault = imageIdentityDefault;

  private formData: any = {};
  private frontImage: any = '';
  private backImage: any = '';

  mounted() {
    library.add(faCalendarDay);
    dom.watch();

    this.formData = lodash.cloneDeep(this.patient);
  }

  async show() {
    this.formData = lodash.cloneDeep(this.patient);
    this.frontImage = this.patient.front_side_card_url;
    this.backImage = this.patient.back_side_card_url;
    this.$modal.show('edit-patient');
  }

  getRef(name) {
    return this.$refs[name] as any;
  }

  async savePatientInformation(invalid) {
    if (invalid) {
      (this.$refs.editInfoForm as any).validate();
      return;
    }
    const data = {
      family_name: this.formData.family_name,
      given_name: this.formData.given_name,
      family_name_kana: this.formData.family_name_kana,
      given_name_kana: this.formData.given_name_kana,
      date_of_birth: this.formData.date_of_birth,
      line_id: this.formData.line_id,
      line_name: this.formData.line_name,
      email: this.formData.email,
      phone: this.formData.phone,
      postal_number: this.formData.postal_number,
      prefecture_id: this.formData.prefecture_id,
      address: this.formData.address,
      city_id: this.formData.city_id,
      building_name: this.formData.building_name,
      reservation_date: null,
      with: 'latestReservation,addresses',
    };

    if (this.formData.latestReservation) {
      data.reservation_date =
        this.patient.latestReservation.reservation_date !=
        this.formData.latestReservation.reservation_date
          ? this.formData.latestReservation.reservation_date
          : null;
    }

    const formData = new FormData();
    for (const key in data) {
      if (data[key] !== null) {
        formData.append(key, data[key]);
      }
    }

    if (this.frontImage && this.frontImage instanceof File) {
      formData.append('front_side_card', this.frontImage);
    }
    if (this.backImage && this.backImage instanceof File) {
      formData.append('back_side_card', this.backImage);
    }
    const payload = { id: this.formData.id, data: formData };
    await this.$store.dispatch('patient_detail/updatePatient', payload);
    await this.$store.commit('patient_list/setNamePatient', this.patient);
    this.$modal.hide('edit-patient');
  }

  disabledBirthdayCalendar(date) {
    const ToDate = new Date();
    ToDate.setFullYear(ToDate.getFullYear() - 18);
    return date.getTime() > ToDate.getTime();
  }

  changeImage(event, type) {
    if (type === 'front') {
      this.frontImage = event;
    }
    if (type === 'back') {
      this.backImage = event;
    }
  }

  searchZipcode() {
    const params = {
      postal_code: this.formData.postal_number,
    };

    ApiHelpers.getApi('ZipcodeApi')
      .getList(params)
      .then((res) => {
        this.formData.prefecture_id = res.address1;
        this.formData.city_id = res.address2;
        this.formData.address = res.address3;
      })
      .catch((error) => {
        Toast.error(error.response.data.message);
      });
  }
}

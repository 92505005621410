




































































































































// libs
import { Patient } from '@/models/patient';
import { Vue, Component } from 'vue-property-decorator';
import { State } from 'vuex-class';
import isArray from 'lodash/isArray';
import date from 'lodash/date';
import each from 'lodash/each';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';

// helpers
import { getNewUserStateName, TREATMENT_STATE_OF_CS, TREATMENT_STATE_OF_DOCTOR, USER_TREATMENT_NAME, USER_TREATMENT_STATE } from '@/common/constants/auth';
import ApiHelper from '@/api/ApiHelper';

// components
import Avatar from '@components/v2/Avatar.vue';
import Multiselect from 'vue-multiselect';
import BasicSelect from '@/components/common/BasicSelect.vue';
import UserTreatmentItem from '@/components/v2/patient/about/side_bar/UserTreatmentItem.vue';
import InfiniteLoading from 'vue-infinite-loading';

// interfaces
import { User } from '@/models/user';
// import { UserTreatment } from '@/models/user-treatment';

@Component({
  components: {
    Avatar,
    Multiselect,
    BasicSelect,
    UserTreatmentItem,
    InfiniteLoading,
  },
})

export default class SidebarPatient extends Vue {


// ********************* getters ********************* //
  get getNewUserState() {
    return getNewUserStateName(this.patient.new_state);
  }
  @State((state) => state.patient_detail.data) patient: Patient;
  @State((state) => state.auth.currentUser) currentUser!: User;
  @State((state) => state.user_treatment_list.data) userTreatmentList!: User;

  private treatmentStateOptions: Array<{ name: string, value: string }> = [];
  private selectedTreatmentStates: Array<{ name: string, value: string }> = [];

  private treatmentOrderOptions: Array<{ name: string, value: string }> = [
    { name: '昇順', value: 'updated_at'},
    { name: '降順', value: '-updated_at' },
  ];
  private selectedTreatmentOrder: { name: string, value: string } | null = null;

  private treatmentTypeOptions: Array<{ name: string, value: string }> = [
    {name: '全て', value: ''},
    {name: '診療', value: 'treatment'},
    {name: '定期購入', value: 'subscription'},
  ];

  private selectedTreatmentType: { name: string, value: string } | null = null;

  private keyword: string = '';
  private idCallback: number | undefined;

// ********************* life cycle ********************* //
  created() {
    this.initData();
    this.setInputValueFromQueryData();
    this.defaultSearchParam();
    if (this.$refs.InfiniteLoading) {
      (this.$refs.InfiniteLoading as any).stateChanger.reset();
    }
    this.$store.commit('user_treatment_list/resetUserTreatmentListState');
  }

  async loadData($state) {
    const payload = {
      user_id: this.patient.id,
      with: 'reservations.latestAnswer,reservations.doctor,orders.plan,menu',
      ... this.makePayloadFromState(true),
    };
    await this.$store.dispatch('user_treatment_list/getUserTreatmentList', { payload, $state});
  }

// ********************* setters ********************* //


// ********************* methods ********************* //

  private initData() {
    this.treatmentStateOptions = this.makeTreatmentStateOptions();
  }

  // get data from url and bind to input, select box.
  private setInputValueFromQueryData() {
    const query = this.$route.query;

    // set treatment state
    if (isString(query.patient_treatment_state)) {
      const state = this.treatmentStateOptions.filter((state) => state.value === query.patient_treatment_state);
      this.selectedTreatmentStates = state;
    }
    if (isArray(query.patient_treatment_state)) {
      const state = this.treatmentStateOptions.filter((state) => query.patient_treatment_state.includes(state.value));
      this.selectedTreatmentStates = state;
    }

    // set patient treatment orders.
    if (query.patient_treatment_order) {
      const type = this.treatmentOrderOptions.find((type) => type.value === query.patient_treatment_order);
      type && (this.selectedTreatmentOrder = type);
    }

    // set patient treatment type.
    if (query.patient_treatment_type) {
      const type = this.treatmentTypeOptions.find((type) => type.value === query.patient_treatment_type);
      type && (this.selectedTreatmentType = type);
    }

    // set patient treatment keyword.
    if (query.patient_treatment_keyword) {
      this.keyword = query.patient_treatment_keyword.toString() ?? '';
    }
  }

  private makeTreatmentStateOptions() {
    return Object.keys(USER_TREATMENT_NAME).map(
                (key) => ({ name: USER_TREATMENT_NAME[key], value: key.toLocaleLowerCase() }),
            );
  }

  // set default search by role.
  private defaultSearchParam() {
    // if some one variable has value then return because user is searching.
    if (
        this.$route.query.patient_treatment_state ||
        this.$route.query.patient_treatment_order ||
        this.$route.query.patient_treatment_type ||
        this.$route.query.patient_treatment_keyword
      ) {
        return;
      }

    // default sort
    const objectSort = this.treatmentOrderOptions.find((type) => type.value === '-updated_at');
    objectSort && (this.selectedTreatmentOrder = objectSort);

    this.setUrlWithQueryParams();
  }

  private makeBreadcrumbsLink() {
    const params = {};
    const searchParamKeys = [
      'patient_treatment_state',
      'patient_treatment_order',
      'patient_treatment_type',
      'patient_treatment_keyword',
    ];
    each(this.$route.query, ( value, key ) => {
      if (!searchParamKeys.includes(key)) {
        params[key] = value;
      }
    });

    return {
        path: this.$route.matched[1].path,
        query: params,
    };
  }

  private goToPatientListWithoutData() {
    this.$store.commit('patient_list/resetPatientListState');
    this.$router.push(this.makeBreadcrumbsLink());
  }

  private debouncedSearch(selected) {
      if (isArray(selected) && selected.findIndex((option) => option.value === 'clear_all') !== -1) {
        this.selectedTreatmentStates = [];
      }

      clearTimeout(this.idCallback);
      this.$nextTick(() => {
        this.idCallback = setTimeout(this.onSearch, 1500);
      });
  }

  private onSearch() {
    this.$store.commit('user_treatment_list/resetUserTreatmentListState');
    this.$store.commit('treatment_detail/setTreatment', {});
    this.setUrlWithQueryParams();

    (this.$refs.infiniteTreatmentListHandler as any).stateChanger.reset();
  }

  private setUrlWithQueryParams() {
     const params = {
      ...this.$route.query,
      time: date.now().toString(),
      ...this.makePayloadFromState(),
    };
     this.$router.push({
      path: this.$route.path,
      query: params,
    });
  }

  private makePayloadFromState(forRequest: boolean = false) {
    const result = {};
    result[forRequest ? 'state' : 'patient_treatment_state'] = this.selectedTreatmentStates.map((item) => item.value);
    result[forRequest ? 'order' : 'patient_treatment_order'] = this.selectedTreatmentOrder?.value;
    result[forRequest ? 'type' : 'patient_treatment_type'] = this.selectedTreatmentType?.value;
    result[forRequest ? 'keyword' : 'patient_treatment_keyword'] = this.keyword;

    return result;
  }
}
















import { Vue, Component } from 'vue-property-decorator';
import moment from 'moment';

// Components
import ContentLayout from '@components/layouts/Content.vue';
import Calendar from '@components/PharmacistReservation/Calendar.vue';
// Utils
import Toast from '@common/helpers/toast';
import ApiHelper from 'api-helper';
// Constants
import { formatDate } from '@common/app.config';
// Icons
import ReservationIcon from '@/assets/img/reservation.svg';

@Component({
  components: {
    Calendar,
    ContentLayout,
  },
})
export default class ShiftSetting extends Vue {
  iconHeader = ReservationIcon;
  reservations = [];
  channels = [];
  searchOptions = {
    search_by: 'month',
    date: moment().format(formatDate),
  };

  mounted() {
    this.fetchDataReservation();
    this.fetchDataChannels();
  }

  setSearchOptions(search) {
    this.searchOptions = search;
  }

  async fetchDataReservation() {
    try {
      this.reservations = [];
      const result = (
        await ApiHelper.getApi('ReservationDoctorAPI').list({
          ...this.searchOptions,
          with: 'user',
          not_status: 'canceled',
        })
      )?.data;
      this.reservations = result;
    } catch (e) {
      Toast.error(e?.response?.data?.message);
    }
  }

  async fetchDataChannels() {
    try {
      const result = await ApiHelper.getApi('ChannelApi').getList({
        with: 'users',
      });
      this.channels = result.data;
      return result;
    } catch (e) {
      Toast.error(e?.response?.data?.message);
    }
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ContentLayout',{attrs:{"icon":_vm.iconHeader,"name":"ユーザー登録"}},[_c('div',{},[_c('div',{},[_c('ValidationObserver',{ref:"createMemberForm",staticClass:"create-member-form",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"create-member-form-row"},[_c('div',{staticClass:"create-member-form-row-label"},[_c('label',[_vm._v("名前")])]),_c('ValidationProvider',{staticClass:"create-member-form-row-input",attrs:{"rules":"required|max:30","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.name),expression:"formData.name"}],attrs:{"disabled":_vm.submitting,"placeholder":"入力してください","type":"text"},domProps:{"value":(_vm.formData.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "name", $event.target.value)}}}),(errors.length)?_c('p',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"create-member-form-row"},[_c('div',{staticClass:"create-member-form-row-label"},[_c('label',[_vm._v("メールアドレス")])]),_c('div',{staticClass:"create-member-form-row-input"},[_c('p',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.formData.email))])])]),_c('div',{staticClass:"create-member-form-row"},[_c('div',{staticClass:"create-member-form-row-label"},[_c('label',[_vm._v("権限")])]),_c('div',{staticClass:"create-member-form-row-input role-value"},[_c('p',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.getRoleText(_vm.formData.role)))])])]),_c('div',{staticClass:"create-member-form-row"},[_c('div',{staticClass:"create-member-form-row-label"},[_c('label',[_vm._v("クリニック・サロン")])]),_c('div',{staticClass:"create-member-form-row-input role-value"},[_c('p',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.clinic))])])]),_c('div',{staticClass:"create-member-form-row"},[_c('div',{staticClass:"create-member-form-row-label"},[_c('label',[_vm._v("パスワード")])]),_c('ValidationProvider',{staticClass:"create-member-form-row-input",attrs:{"rules":"required|min:8|wak_password","tag":"div","name":"password","customMessages":_vm.passwordErrorMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.password),expression:"formData.password"}],attrs:{"disabled":_vm.submitting,"autoComplete":"new-password","vid":"password","placeholder":"入力してください","type":"password"},domProps:{"value":(_vm.formData.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "password", $event.target.value)}}}),(errors.length)?_c('p',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"create-member-form-row"},[_c('div',{staticClass:"create-member-form-row-label"},[_c('label',[_vm._v("パスワード（確認用）")])]),_c('ValidationProvider',{staticClass:"create-member-form-row-input",attrs:{"rules":"required|min:8|confirmed:password","tag":"div","name":"password_confirmed","customMessages":_vm.confirmationErrorMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.password_confirmed),expression:"formData.password_confirmed"}],attrs:{"disabled":_vm.submitting,"autoComplete":"new-password","placeholder":"入力してください","type":"password"},domProps:{"value":(_vm.formData.password_confirmed)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "password_confirmed", $event.target.value)}}}),(errors.length)?_c('p',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"form-group text-center mt-4"},[_c('button',{staticClass:"mr-5 create-member-form-btn create-member-form-btn-cancel",attrs:{"type":"button","disabled":_vm.submitting},on:{"click":function($event){return _vm.$router.push('/members')}}},[_vm._v(" 戻る ")]),_c('button',{staticClass:"create-member-form-btn create-member-form-btn-confirm",attrs:{"type":"button","disabled":_vm.submitting},on:{"click":function($event){return _vm.submit(invalid)}}},[_vm._v(" 保存 ")])])]}}])})],1),_c('modal',{staticClass:"basic-modal done-create-member-modal",attrs:{"name":"doneCreateMemberModal","height":280,"clickToClose":false,"width":480,"scrollable":false}},[_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"text-center pt-5"},[_c('p',{staticClass:"mb-0"},[_vm._v("ユーザー登録を完了しました。")]),_c('p',{staticClass:"mt-0"},[_vm._v("ログインしてください。")]),_c('button',{staticClass:"mt-5 done-create-member-modal-done-btn",attrs:{"type":"button"},on:{"click":function($event){return _vm.gotoLogin()}}},[_vm._v(" ログインページへ ")])])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
import { Stock, StockQueryParam } from '@/models/stock';
import BaseApi from '../BaseApi';

class StockApi extends BaseApi {
  public getStockList(params: StockQueryParam) {
    return this.get('/', params);
  }

  public async getStockDetail(id: number, date?: string): Promise<Stock> {
    const res = await this.get(`/${id}`, {
      month: date, // YYYY-MM
    });
    return new Stock().deserialize(res.data);
  }

  public async create(body: any): Promise<Stock> {
    return this.post('/', body);
  }

  public update(stockId: number, params: any) {
    return this.put(`/${stockId}`, params);
  }

  public delete(stockId: number) {
    return this.del(`/${stockId}`);
  }

  protected getSpecificApiPrefix(): string {
    return 'v2/managers/stocks';
  }
}

export default StockApi;

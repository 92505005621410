

























import { Vue, Component, Watch } from 'vue-property-decorator';
import ApiHelper from 'api-helper';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faCameraRetro } from '@fortawesome/free-solid-svg-icons';

// Utils
import Toast from '@common/helpers/toast';
// Components
import ContentLayout from '@components/layouts/Content.vue';
import DragDropFileUpload from '@/components/common/DragDropFileUpload.vue';
import DiagnosisForm from './components/DiagnosisForm.vue';
// Icons
import ListIcon from '@/assets/img/icon-diagnosis.svg';
import { Menu } from '@/models/menu';
import PageLoader from '@/components/common/PageLoader.vue';

@Component({
  components: {
    ContentLayout,
    DragDropFileUpload,
    DiagnosisForm,
    PageLoader,
  },
})
export default class Detail extends Vue {
  iconHeader: any = ListIcon;
  isSubmiting = false;
  isLoading: boolean = false;
  isChanged: boolean = false;
  diagnosis: Menu = new Menu();

  get computedForm() {
    return this.$refs.dataForm as any;
  }

  get diagnosisId() {
    return this.$route.params?.id;
  }

  async created() {
    this.getDiagnosisDetail();
    library.add(faCameraRetro);
    dom.watch();
  }

  @Watch('diagnosis', { deep: true })
  watchDiagnosis(_, oldValue) {
    if (this.isChanged || !oldValue?.id) {
      return;
    }
    this.updateIsChanged(true);
  }

  async getDiagnosisDetail() {
    try {
      this.isLoading = true;
      const params = {
        with: 'clinics,doctors',
      };

      const res = await ApiHelper
        .getApi('MedicalMenuApi')
        .getOne(this.diagnosisId,  params);
      this.diagnosis = new Menu().deserialize(res);
      this.diagnosis.timeFrameOption();

      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      if (error.response) {
        Toast.error(error.response.data.message);
      }
    }
  }

  updateIsSubmiting(isSubmiting: boolean) {
    this.isSubmiting = isSubmiting;
  }

  updateIsChanged(status: boolean) {
    this.isChanged = status;
  }

  handleChange(name, value) {
    this.$set(this.diagnosis, name, value);
  }

  handleCancel() {
    return this.$router.push({
      name: 'diagnosis',
      query: {
        name: this.$route.query.name || '',
        clinic_name: this.$route.query.clinic_name || '',
        menu_status: this.$route.query.menu_status,
      },
    });
  }

  async handleSubmit() {
    const valid = await this.computedForm.validate();
    if (valid) {
      try {
        this.updateIsSubmiting(true);
        await ApiHelper
          .getApi('MedicalMenuApi')
          .update(this.diagnosisId, this.diagnosis.formJSONData());
        this.updateIsSubmiting(false);
        this.updateIsChanged(false);
        Toast.success('変更しました');
        this.handleCancel();
      } catch (error) {
        this.updateIsSubmiting(false);
        this.updateIsChanged(false);
        const data = error.response.data;
        Toast.error(data.message);
        if (error.response.status === 422) {
          this.computedForm.setErrors(error.response.data.errors);
          return;
        }
      }
    }
  }
}





















































































































































































































































































































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { Roles } from '@common/app.config';
import ApiHelper from 'api-helper';
import Toast from '@/common/helpers/toast';
import PageLoader from '@components/common/PageLoader.vue';
import PopupConfirm from '@/components/common/PopupConfirm.vue';
import PopupDelete from '@/components/common/PopupCourseDelete.vue';
import UploadImage from '@/components/common/UploadImage.vue';
import datetime from 'vuejs-datetimepicker';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import DatePicker from 'vue2-datepicker';
import moment from 'moment';

@Component({
  components: {
    PageLoader,
    UploadImage,
    datetime,
    PopupConfirm,
    PopupDelete,
    DatePicker,
  },
})
export default class PlanDetail extends Vue {
  showTimePanel = false;

  private get courseForm() {
    return this.$refs.courseForm as any;
  }

  get isAdmin() {
    return this.currentUser.role === Roles.superAdmin;
  }

  get isCreate() {
    return this.$route.name === 'courses.create';
  }

  @State((state) => state.auth.currentUser) currentUser!: any;
  course: any = {};
  isLoading: boolean = false;
  image: any = null;
  dateRange: Date[] = [];
  isSubmit: boolean = false;
  isSubmitDelete: boolean = false;
  defaultData: any = {
    name: '',
    description: '',
    payment_type: '',
    cycle: '',
    is_show_max_value: false,
    price_first: '',
    price_after: '',
    price_show: '0',
    price_show_with_tax: '0',
    price_base_first: '',
    price_base_after: '',
    ext_payment_id: '',
    status: '',
    start: '',
    end: '',
    detail: '',
    image: '',
  };

  mounted() {
    this.getCourseDetail();
    library.add(faTrashAlt);
    dom.watch();
  }

  updated() {
    console.log();
  }

  toggleTimePanel() {
    this.showTimePanel = !this.showTimePanel;
  }
  handleOpenChange() {
    this.showTimePanel = false;
  }

  getCourseDetail() {
    if (this.isLoading) {
      return;
    }

    if (this.isCreate) {
      this.course = this.defaultData;
    } else {
      this.isLoading = true;
      ApiHelper.getApi('CourseApi')
        .getCourseDetails(this.$route.params.id)
        .then((res: any) => {
          this.course = res;
          this.image = this.course.image_url;
          this.isLoading = false;
        })
        .catch((error: any) => {
          this.isLoading = false;
          if (error.response) {
            Toast.error(error.response.data.message);
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }

  backToList() {
    this.$router.go(-1);
  }

  changeAvatar(event) {
    this.image = event;
  }

  async showPopupConfirmSubmitCourse() {
    const isValid = await this.courseForm.validate();
    if (!isValid) {
      return;
    }
    this.$modal.show('comfirmSubmitCourse');
  }

  async showPopupConfirmDeleteCourse() {
    this.$modal.show('comfirmDeleteCourse');
  }

  cancelPopupConfirmDelete() {
    this.$modal.hide('comfirmDeleteCourse');
  }

  deleteCourse() {
    this.isSubmitDelete = true;
    ApiHelper.getApi('CourseApi')
      .deleteCourse(this.$route.params.id)
      .then((res: any) => {
        this.$router.push({ name: 'courses' });
      })
      .catch((error: any) => {
        this.isLoading = false;
        if (error.response) {
          Toast.error(error.response.data.message);
        }
      })
      .finally(() => {
        this.isSubmitDelete = false;
      });
  }

  cancelPopupConfirmSubmit() {
    this.$modal.hide('comfirmSubmitCourse');
  }

  submitCourse() {
    this.isLoading = true;
    this.isSubmit = true;
    const data = new FormData();
    for (const key in this.course) {
      if (this.course[key] !== null && this.course[key] !== '') {
        data.append(key, this.course[key]);
      }
    }
    if (this.image && this.image instanceof File) {
      data.append('avatar', this.image);
    }
    data.append('start', moment(this.course.start).format('YYYY-MM-DD HH:mm'));
    data.append('end', moment(this.course.end).format('YYYY-MM-DD HH:mm'));
    if (this.isCreate) {
      ApiHelper.getApi('CourseApi')
        .createCourse(data, this.$route.params.id)
        .then((res: any) => {
          this.course = res;
          this.isLoading = false;
          this.$router.push({ name: 'courses' });
        })
        .catch((error: any) => {
          this.isLoading = false;
          if (error.response) {
            Toast.error(error.response.data.message);
          }
        })
        .finally(() => {
          this.isLoading = false;
          this.isSubmit = false;
        });
    } else {
      ApiHelper.getApi('CourseApi')
        .updateCourse(data, this.$route.params.id)
        .then((res: any) => {
          this.course = res;
          this.isLoading = false;
          this.$router.push({ name: 'courses' });
        })
        .catch((error: any) => {
          this.isLoading = false;
          if (error.response) {
            Toast.error(error.response.data.message);
          }
        })
        .finally(() => {
          this.isLoading = false;
          this.isSubmit = false;
        });
    }
  }
}

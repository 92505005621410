import Vue from 'vue';
import moment from 'moment';
import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate';
import ja from 'vee-validate/dist/locale/ja.json';
import * as rules from 'vee-validate/dist/rules';

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

localize({
  ja,
});

const configValidate = () => {
  // Importing all rules
  for (const rule of Object.keys(rules)) {
    extend(rule, (rules as any)[rule]);
  }

  // Extends rules
  extend('required', {
    validate: (value: any) => {
      if (typeof value === 'string') {
        return !!value.trim();
      }

      if (value && (typeof value === 'object') && value.length === 0) {
        return false;
      }

      if (value === 0) {
        return true;
      }

      return !!value;
    },
    message: '※必須項目が入力されていません',
  });
  // Extends rules
  extend('phone', {
    validate: (value: string) => {
      // const regexNoneNumber = /^[0-9]+$/;
      // if (regexNoneNumber.test(value)) {
      //   return '半角数字で入力してください';
      // }
      const regex = /^[0-9]{10,11}$/;
      return regex.test(value);
    },
    message: '電話番号は半角数字10〜11桁で入力してください',
  });
  extend('postal_code', {
    validate: (value) => {
      const regex = /^\d{3}\d{4}$/;
      return regex.test(value);
    },
    message: '郵便番号は半角数字7桁で入力してください',
  });
  extend('wak_password', {
    validate: (value: string) => {
      const regex = /^(?=.*[0-9])(?=.*[a-zA-Z])(.+){8,100}$/;
      return regex.test(value);
    },
    message: '半角英数字を含める8文字以上で設定してください',
  });
  extend('kana', {
    validate: (value) => {
      const regex = /^[ァ-・ヽヾ゛゜ー]+$/;
      return regex.test(value);
    },
    message: 'カナで入力してください',
  });
  extend('fax', {
    validate: (value: string) => {
      const regex = /^(0)/;
      return regex.test(value);
    },
    message: 'FAX番号を正しく入力してください。',
  });
  extend('onlyText', {
    validate: (value: string) => {
      const regex = /^[ー一-龯\\sぁ-んァ-ンＡ-ｚA-Za-z]+$/;
      return regex.test(value);
    },
    message: 'Please enter the word',
  });
  extend('onlyNumber', {
    validate: (value: string) => {
      const regex = /^([0-9])+$/;
      return regex.test(value);
    },
    message: '数字で入力してください',
  });
  extend('onlyNumberWithCommas', {
    validate: (value: string) => {
      const regex = /^([0-9]|\,)+$/;
      return regex.test(value);
    },
    message: '数字で入力してください',
  });
  extend('email', {
    validate: (value: string) => {
      const regex = /^[a-z0-9_\-\.\+]+@([a-z0-9_-]+\.)+[a-z0-9_-]{2,4}$/;
      return regex.test(value);
    },
    message: '有効なメールアドレスを入力してください。',
  });
  extend('isAfterTime', {
    validate: (value: any, vm: any) => {
      const timeStart = moment(vm[0], 'HH:mm').diff(moment().startOf('day'), 'seconds');
      const timeEnd = moment(value, 'HH:mm').diff(moment().startOf('day'), 'seconds');
      return timeEnd > timeStart;
    },
    message: '終了時間は開始時間以降の時間を選択してください。',
  });
  extend('isAfterNow', {
    validate: (value: string) => {
      return moment(value, 'HH:mm') > moment();
    },
    message: '未来の時間を選択してください。',
  });
  extend('positiveInterger', {
    validate: (value: string) => {
      const regex = /^[1-9]\d*$/;
      return regex.test(value);
    },
  });
  extend('clinic_uuid', {
    validate: (value: string) => {
      const regex = /^[a-z0-9_]+$/;
      return regex.test(value);
    },
    message: 'クリニックのUUIDは小文字か数字で入力してください。',
  });
  extend('same_price', {
    params: ['target'],
    validate(value, params: any) {
      return value === params.target;
    },
    message: '決済方法が一回の場合は一回目と二回目以降の金額を同一にしてください',
  });

  extend('notSpecialChar', (text) => {
    if (text) {
      return text.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '').length == text.length
        ? true
        : '絵文字または特殊記号を入力しないでください。';
    }
    return true;
  });
  // Extends rules min for course cycle
  extend('min_cycle', {
    params: ['target'],
    validate: (value, params: any) => {
      return value >= Number(params.target);
    },
    message: '14から180までの数字をご入力ください',
  });
  // Extends rules max for course cycle
  extend('max_cycle', {
    params: ['target'],
    validate: (value, params: any) => {
      return value <= Number(params.target);
    },
    message: '14から180までの数字をご入力ください',
  });
  extend('smaller_number', {
    params: ['number'],
    validate: (value: number, params: any) => {
      return Number(value) < Number(params.number);
    },
    message: '問診NG(手動診察)カウント数より問診NG(診察不要定期便)カウント数が大きくなるように設定してください',
  });

  extend('bigger_number', {
    params: ['number'],
    validate: (value: number, params: any) => {
      return Number(value) > Number(params.number);
    },
    message: '問診NG(手動診察)カウント数より問診NG(診察不要定期便)カウント数が大きくなるように設定してください',
  });
  extend('numeric', {
    message: '正の整数を入力してください',
  });
};

configValidate();

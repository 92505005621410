




































































































































































import ApiHelper from 'api-helper';
import { Vue, Component } from 'vue-property-decorator';
import { Plan } from '@/models/plan';
import { Roles } from '@/common/app.config';
import { PAGE_OPTIONS } from '@/common/constants/pagination' ;
import PopupDelete from '@/components/common/PopupDelete.vue';
import PopupConfirm from '@/components/common/PopupConfirm.vue';
import Pagination from '@components/common/Pagination.vue';
import Toast from '@common/helpers/toast';
import { State } from 'vuex-class';
import PageLoader from '@components/common/PageLoader.vue';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import {  faClipboardList } from '@fortawesome/free-solid-svg-icons';
import PlanSearch from '@/components/Plan/PlanSearch.vue';
import { PLAN_PAYMENT_TYPE_NAME } from '@/common/constants/plan';
import { pickBy, identity } from 'lodash';

@Component({
  components: {
    PlanSearch,
    Pagination,
    PopupDelete,
    PageLoader,
    PopupConfirm,
  },
})
export default class HomePlan extends Vue {
  @State((state) => state.auth.currentUser) currentUser!: any;
  idPlan: number = 0;
  statusPlan: number;
  dataPlan: Plan[] = [];
  isLoading = false;
  totalPage = 0;
  pageOptions = PAGE_OPTIONS;

  paginate = {
    page: parseInt(this.$route.query.page as string, 10) || 1,
    per_page: 20,
  };

  frFilter = { with: 'clinics,menus', id: this.$route.query.id || null, name: this.$route.query.name || null, clinic_name: this.$route.query.clinic_name || null, menu_name: this.$route.query.menu_name || null};

  get queryParams() {
    if (!this.frFilter.id) {
      this.frFilter.id = null;
    }
    return {
      ...this.paginate,
      ...this.frFilter,
    };
  }

  get isEmptyDataPlan(): boolean {
    return this.dataPlan && !this.dataPlan.length;
  }

  get isAdmin() {
    return this.currentUser && this.currentUser.role === Roles.superAdmin;
  }

  paymentType(plan) {
    return PLAN_PAYMENT_TYPE_NAME[plan.payment_type];
  }

  onChangePerPage(perPage: number) {
    this.paginate.per_page = perPage;
    this.paginate.page = 1;
    this.queryParams.per_page = perPage;
    this.queryParams.page = 1;
    this.dataPlan = [];
    this.getPlanList();
  }

  isActivePerPage(perPage: number) {
    return this.paginate.per_page === perPage;
  }

  // Methods

  closePopupDelete() {
    this.$modal.hide('delPlan');
  }

  openPopupDelete(id: number) {
    this.idPlan = id;
    this.$modal.show('delPlan');
  }

  closePopupUpdateStatus() {
    this.$modal.hide('updateStatus');
    this.getPlanList();
  }

  openPopupUpdateStatus(id: number, status: number) {
    this.idPlan = id;
    this.statusPlan = status === 1 ? 0 : 1;
    if (status === 1) {
      this.$modal.show('updateStatus');
    } else {
      this.updateStatus();
    }
  }

  onChangeQuery(filter) {
    this.frFilter = filter;
    this.queryParams.page = 1;
    this.getPlanList();
  }

  onPageChanged(page: number) {
    this.paginate = {
      ...this.paginate,
      page,
    };
    this.getPlanList();
  }

  getPlanList() {
    if (this.isLoading) {
      return;
    }

    this.dataPlan = [];
    this.totalPage = 0;
    this.isLoading = true;

    this.setUrlWithQuery();

    ApiHelper.getApi('PlanApi')
      .fetchPlanList(this.queryParams)
      .then((response: any) => {
        this.dataPlan = response.data.map((item) => new Plan().deserialize(item));
        if (this.$route.query.page && response.last_page < this.$route.query.page) {
          this.paginate.page = response.last_page;
          this.setUrlWithQuery();
          return this.$router.go(0);
        }
        this.totalPage = response.last_page;
      })
      .catch((error: any) => {
        Toast.error(error.response.data.message);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  deletePlan() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    ApiHelper.getApi('PlanApi')
      .destroy(this.idPlan, {})
      .then((response: any) => {
        Toast.success('削除しました');
      })
      .catch((error: any) => {
        Toast.error(error.response.data.message);
      })
      .finally(() => {
        this.closePopupDelete();
        this.isLoading = false;
        this.getPlanList();
      });
  }

  updateStatus() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    const data = {
      status: this.statusPlan,
    };
    ApiHelper.getApi('PlanApi')
      .updateStatus(this.idPlan, data)
      .then((response: any) => {
        this.closePopupUpdateStatus();
        Toast.success('更新しました');
      })
      .catch((error: any) => {
        Toast.error(error.response.data.message);
      })
      .finally(() => {
        this.isLoading = false;
        this.getPlanList();
      });
  }

  mounted() {
    this.getPlanList();
  }

  created() {
    // library.add(faTrashAlt);
    // library.add(faClipboardList);
    library.add(faClipboardList);
    dom.watch();
  }

  private goDetail(id) {
    const queryParams = pickBy({
      id: this.queryParams.id || '',
      name: this.queryParams.name || '',
      clinic_name: this.queryParams.clinic_name || '',
      menu_name: this.queryParams.menu_name || '',
      page: this.queryParams.page.toString() || '1',
    }, identity);

    this.$router.push({
      name: 'plans.detail',
      params: { id },
      query: queryParams,
    });
  }

  private setUrlWithQuery() {
    const queryParams =  pickBy({
      id: this.queryParams.id || '',
      name: this.queryParams.name || '',
      clinic_name: this.queryParams.clinic_name || '',
      menu_name: this.queryParams.menu_name || '',
      page: this.queryParams.page.toString() || '1',
    }, identity);

    this.$router.replace({
      path: this.$route.path,
      query: queryParams,
    });
  }
}

import BaseApi from './BaseApi';

class CouseApi extends BaseApi {
  public fetchCourseList(params: any) {
      return this.get('/courses/', params);
  }

  public getCourseDetails(id: any) {
      return this.get(`/courses/${id}`);
  }

  public updateCourse(data: FormData, id: any) {
    data.append('_method', 'put');
    return this.post(`/courses/${id}`, data);
  }

  public createCourse(data: FormData) {
    return this.post(`/courses`, data);
  }

  public deleteCourse(id: any) {
    return this.del(`/courses/${id}`);
  }

  public getCourseDetailsCheckDelete(id: any) {
    return this.get(`/detail-course-suggest/${id}`);
  }

  public getSuggestCourses(params: any) {
      return this.get(`/suggest-course`, params);
  }

  public getTreatmentCourses(ids: string, channelId: number) {
      return this.get(`/get-course-treatment-plan?course_id=${ids}&channel_id=${channelId}`);
  }

  public addTreatmentCourses(params: any) {
    return this.post('/update-treatment-plan', params);
  }

  public suggestTreatmentCourses(params: any) {
    return this.post('/suggest-course-treatment-plan', params);
  }

  public resetSelectCourse(id) {
    return this.post('/doctor-cancel-confirm/' + id);
  }

  public getUserUsingCourse(id: any) {
    return this.get(`/courses/${id}/users`);
  }

  protected getSpecificApiPrefix(): string {
      return 'managers';
  }
}

export default CouseApi;

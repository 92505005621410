






















































// libs
import { Vue, Component } from 'vue-property-decorator';
import { State } from 'vuex-class';
import LodashGet from 'lodash/get';

// interface
import { Patient } from '@/models/patient';

// component
import Avatar from '@components/v2/Avatar.vue';
import { UserTreatment } from '@/models/user-treatment';
import PopupConfirmDoctor from '@/components/common/PopupConfirmDoctor.vue';
import { Roles } from '@/common/app.config';
import Toast from '@common/helpers/toast';
import ApiHelper from '@/api/ApiHelper';

@Component({
  components: {
    Avatar,
    PopupConfirmDoctor,
  },
})
export default class PatientControlAvatar extends Vue {
  @State((state) => state.patient_detail.data) patient!: Patient;
  @State((state) => state.treatment_detail.data) activeTreatment: UserTreatment;
  @State((state) => state.auth.currentUser) currentUser!: any;
  isSubmitting: boolean = false;
  isLoading: boolean = false;

  get doctorName() {
    return LodashGet(this.activeTreatment, 'reservations[0].doctor.name', '-');
  }

  get isAdmin() {
    return this.currentUser && this.currentUser.role !== Roles.pharmacist;
  }

  get clinicName() {
    return LodashGet(this.patient, 'clinics[0].name', '-');
  }

  closeModal(): void {
    this.$modal.hide('popup-confirm-doctor');
  }

  onShowDetail(): void {
    this.$modal.show('popup-confirm-doctor');
  }

  async confirmDoctor(data) {
    this.isSubmitting = true;

    if (this.activeTreatment.onlyOneReservation) {
      this.isLoading = true;
      await ApiHelper.getApi('PatientV2Api')
        .updateDoctorReservation(
          this.patient.id,
          this.activeTreatment.onlyOneReservation?.id,
          { doctor_id: data.doctor_id },
        )
        .then(() => {
          this.$store.commit('treatment_detail/setLoading');
          this.$store.commit('treatment_detail/setTreatment', this.activeTreatment);
          setTimeout(() => {
            this.$store.commit('treatment_detail/setLoading', false);
          }, 100);

          Toast.success('担当医師の変更が完了しました。');
        })
        .catch((error: any) => {
          Toast.error(error.response.data.message);
        })
        .finally(() => {
          this.isSubmitting = false;
          this.closeModal();
          this.isLoading = false;
        });
    }
  }
}



















































































































































































import ApiHelper from 'api-helper';
import {Component, Prop, Vue} from 'vue-property-decorator';
import Toast from '@common/helpers/toast';
import PageLoader from '@components/common/PageLoader.vue';
import {PLAN_PAYMENT_TYPE} from '@/common/constants/plan';
import moment from 'moment';
import { extend } from 'vee-validate';
import { USER_STATE, USER_TREATMENT_STATE_CAN_ADD_KARTE, NEW_USER_STATE, USER_TREATMENT_STATE_CAN_RESERVE } from '@/common/constants/auth';
import PopupConfirm from '@/components/common/PopupConfirm.vue';
import { State } from 'vuex-class';
import { UserTreatment } from '@/models/user-treatment';
import { Patient } from '@/models/patient';

extend('notSpecialChar', (text) => {
  const textCompare = text;
  if (text) {
    return textCompare.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '').length == text.length
      ? true
      : '絵文字または特殊記号を入力しないでください。';
  }
  return true;
});

@Component({
  components: {
    PageLoader,
    PopupConfirm,
  },
  filters: {
    formatTime(value) {
      return (value ? moment(value) : moment()).format('YYYY年 MM月DD日 HH:mm');
    },
    formatDate(value) {
      return (value ? moment(value) : moment()).format('YYYY年MM月DD日');
    },
  },
})
export default class PrescriptionForm extends Vue {
  @Prop() changeState: any;

  paginate = {
    page: 1,
    per_page: 20,
    last_page: 1,
  };

  filterParams = { with: 'medicines', select_fields: 'id,name,treatment_policy', clinic_id: null, karte_status: 1 };

  get queryParams() {
    return {
      ...this.filterParams,
      ...this.paginate,
    };
  }

  private get prescriptionFormRef() {
    return this.$refs.prescriptionForm as any;
  }
  @Prop() currentChannelUser!: any;
  @Prop() listFeedback: any;
  @State((state) => state.treatment_detail.data) activeTreatment: UserTreatment;
  @State((state) => state.patient_detail.data) currentPatient!: Patient;

  protected PRESCRIPTION_TYPE = {
    PRESCRIPTION: 'prescription',
    NG: 'ng',
    CANCEL_PRESCRIPTION: 'cancel_prescription',
    RESERVE_PRESCRIPTION: 'reserve_prescription',
  };
  private isSubmitting: boolean = false;
  private isLoading: boolean = false;
  private listPlan: any[] = [];
  private listPlanWithMenu: any[] = [];
  private listPlanIdWithMenu: any[] = [];

  private PLAN_PAYMENT_TYPE: any = PLAN_PAYMENT_TYPE;
  private prescriptionForm: any = {
    content: null,
    type: null,
    plans: [],
  };
  private isPrescription: boolean = false;
  private isNG: boolean = false;
  private isCancel: boolean = false;
  private isReverse: boolean = false;
  private prescriptionType: string = '';
  private planSuggestListEl: Element|null;

  mounted() {
    this.getListPlansWithMenu();
  }

  private get isDisableButtonSave() {
    if ( this.isPrescription || this.isNG || this.isCancel || this.isReverse ) {
      return false;
    } else {
      return true;
    }
  }

  private get isDisableCreateFeedback() {
    return !USER_TREATMENT_STATE_CAN_ADD_KARTE.includes(this.activeTreatment.state);
  }

  private get checkDoctorReserve() {
    return !USER_TREATMENT_STATE_CAN_RESERVE.includes(this.activeTreatment.state);
  }

  private get checkButtonReserve() {
    return this.activeTreatment.feedback_type === NEW_USER_STATE.DOCTOR_RESERVE_PRESCRIPTION;
  }

  hideModal() {
    if (!this.isSubmitting) {
      this.$modal.hide('prescriptionForm');
      this.$modal.hide('chat-channel-popup'); // hide modal chat question in chat channel
    }
  }

  hidenModal() {
    this.$modal.hide('prescriptionForm');
    this.$modal.hide('chat-channel-popup');
  }

  show(currentPrescription) {
    this.prescriptionForm = currentPrescription;
    this.prescriptionType = currentPrescription.type;
    this.$modal.show('prescriptionForm');
  }

  getListPlansWithMenu() {
    this.isLoading = true;
    const self = this;
    const payload = { user_treatment_id: this.activeTreatment.id };
    ApiHelper.getApi('PatientV2Api')
      .getPlansByPatientId(this.currentChannelUser.id, payload)
      .then(function(res) {
        self.listPlanWithMenu = res.data;
        self.listPlanIdWithMenu = res.data?.map((plan) => plan.id) || [];
        self.getListPlans();
      })
      .catch((error: any) => {
        Toast.error(error.response.data.message);
      })
      .finally(function() {
        self.isLoading = false;
      });
  }

  getListPlans() {
    this.isLoading = true;
    const self = this;
    if (this.paginate.page > this.paginate.last_page && this.paginate.last_page > 1) {
      this.isLoading = false;
      return;
    }

    if (this.currentPatient && this.currentPatient.clinics.length > 0) {
      this.filterParams = {
        ...this.filterParams,
        clinic_id: this.currentPatient.clinics[0].id,
      };
    }

    ApiHelper.getApi('PlanApi')
      .fetchPlanList(this.queryParams)
      .then(function(res, error) {
        const lastPage = res ? res.last_page : 1;

        if (self.paginate.page * self.paginate.per_page > self.listPlan.length + res.data.length - 1) {
          let plans = res.data || [];
          if (self.listPlanIdWithMenu.length > 0) {
            plans = plans.filter((plan) => !self.listPlanIdWithMenu.includes(plan.id));
          }
          self.listPlan = [...self.listPlan, ...plans];
        }

        self.paginate = {
          ...self.paginate,
          page: lastPage >= self.paginate.page ? self.paginate.page + 1 : self.paginate.page,
          last_page: lastPage,
        };
      })
      .catch((error: any) => {
        Toast.error(error.response.data.message);
      })
      .finally(function() {
        self.isLoading = false;
      });
  }

  handleScroll(event) {
    const container = event.target;
    const { scrollTop, scrollHeight, clientHeight } = container;
    if (scrollTop + clientHeight >= scrollHeight - 1 && !this.isLoading) {
      this.getListPlans();
    }
  }

  prescriptionHandle() {
    this.isPrescription = true;
    this.prescriptionType = this.PRESCRIPTION_TYPE.PRESCRIPTION;
    this.prescriptionForm.type = this.PRESCRIPTION_TYPE.PRESCRIPTION;
    const self = this;
    setTimeout(function() {
      self.planSuggestListEl = document.querySelector('div.plan-suggest-list');
      self.planSuggestListEl?.addEventListener('scroll', self.handleScroll);
    }, 100);
  }

  ngHandle() {
    this.isNG = true;
    this.prescriptionType = this.PRESCRIPTION_TYPE.NG;
    this.prescriptionForm.type = this.PRESCRIPTION_TYPE.NG;
  }

  cancelPrescription() {
    this.isCancel = true;
    this.prescriptionType = this.PRESCRIPTION_TYPE.CANCEL_PRESCRIPTION;
    this.prescriptionForm.type = this.PRESCRIPTION_TYPE.CANCEL_PRESCRIPTION;
  }

  reservePrescription() {
    this.isReverse = true;
    this.prescriptionType = this.PRESCRIPTION_TYPE.RESERVE_PRESCRIPTION;
    this.prescriptionForm.type = this.PRESCRIPTION_TYPE.RESERVE_PRESCRIPTION;
  }

  async savePrescriptionForm() {
    const isValid = await this.prescriptionFormRef.validate();
    if (!isValid) {
      return;
    }
    if (this.listFeedback.length > 0) {
      this.$modal.show('confirmCreateKarte');
      return true;
    }
    this.createKarte();
  }

  async createKarte() {
    const data = {
      user_id: this.currentChannelUser.id,
      content: this.prescriptionForm.content,
      plans: this.prescriptionForm.type === this.PRESCRIPTION_TYPE.PRESCRIPTION ? this.prescriptionForm.plans : null,
      type: this.prescriptionForm.type,
      user_treatment_id: this.activeTreatment.id,
    };
    this.isSubmitting = true;
    const self = this;
    let feedback = null;

    if (this.prescriptionForm.id) {
      await ApiHelper.getApi('DoctorMouthApi').updateFeedback(this.prescriptionForm.id, data)
        .then((res) => {
          feedback = res.data;
          Toast.success('カルテ作成が完了しました。');
        })
        .catch((error: any) => {
          error.response && Toast.error(error.response.data.message);
        })
        .finally(function() {
          self.isSubmitting = false;
          self.$modal.hide('prescriptionForm');
        });
    } else {
      await ApiHelper
        .getApi('PatientV2Api')
        .createFeedback(this.currentPatient.id, data)
        .then((res, error) => {
          feedback = res.data;
          switch (this.prescriptionType) {
            case this.PRESCRIPTION_TYPE.RESERVE_PRESCRIPTION:
              Toast.success('処方保留が完了しました。');
              break;
            case this.PRESCRIPTION_TYPE.CANCEL_PRESCRIPTION:
              Toast.success('処方をキャンセルしました。');
              this.hidenModal();
              break;
            default:
              Toast.success('カルテ作成が完了しました。');
              break;
          }
        })
        .catch((error: any) => {
          const { response: { data: { code, message } }} = error;
          if (code && code === 'errors.exists_doctor_feedback_for_user') {
            return Toast.error('カルテが既に作成されています。');
          }
          message && Toast.error(message);
        })
        .finally(function() {
          self.isSubmitting = false;
          self.$modal.hide('prescriptionForm');
          self.$modal.hide('confirmCreateKarte');
        });
    }
    this.isPrescription = this.isNG = this.isCancel = this.isReverse = false;
    this.prescriptionType = '';
    self.$emit('savedFeedback', feedback);
  }

  cancelPopupConfirm() {
    this.$modal.hide('confirmCreateKarte');
  }
}

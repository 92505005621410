import Toast from '@common/helpers/toast';
import { UserTreatment } from '@/models/user-treatment';
import ApiHelper from '@/api/ApiHelper';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

// interfaces

const DEFAULT_PAGE = 1;
const DEFAULT_PER_PAGE = 20;
const DEFAULT_LAST_PAGE = 1;

@Module({ name: 'user_treatment_list', namespaced: true })
export default class Index extends VuexModule<any> {
  private data: UserTreatment[] | [] = [];

  private isLoading: boolean = false;
  private perPage: number = DEFAULT_PER_PAGE;
  private page: number = DEFAULT_PAGE;
  private lastPage: number = DEFAULT_LAST_PAGE;

  // ********* Mutations ********* //
  @Mutation
  public setUserTreatmentList(userTreatmentList: UserTreatment[] | []) {
    this.data = userTreatmentList;
  }

  @Mutation
  public setUserTreatmentState(data) {
    this.data.every((userTreatment) => {
      if (userTreatment.id === data.id) {
        userTreatment.state = data.state;
        userTreatment.feedback_type = data.feedback_type;
        return false;
      }
      return true;
    });
  }

  @Mutation
  public setDataUserTreatmentListPaginate(response: any) {
    // this.data.push(...response.data);
    this.perPage = response.per_page;
    this.page = response.current_page;
    this.lastPage = response.last_page;
  }

  @Mutation
  public resetUserTreatmentListState() {
    this.data = [];
    this.isLoading = false;
    this.perPage = DEFAULT_PER_PAGE;
    this.page = DEFAULT_PAGE;
    this.lastPage = DEFAULT_LAST_PAGE;
  }

  @Mutation
  public setUserTreatmentListLoading(isLoading = true) {
    this.isLoading = isLoading;
  }

  // ********* Actions ********* //
  @Action({ rawError: true })
  public async getUserTreatmentList(params) {
    const { commit, state } = this.context;
    try {
      commit('setUserTreatmentListLoading');
      const {payload, $state} = params;
      payload.per_page = state.perPage;
      payload.page = state.page;

      const response = await ApiHelper.getApi('UserTreatmentV2Api').index( payload);
      const { data } = response;
      this.setUserTreatmentList(data);

      response.current_page += 1;
      commit('setDataUserTreatmentListPaginate', response);

      // when page > last page -> stop load more.
      state.page <= response.last_page ? $state.loaded() : $state.complete();
    } catch (error) {
      const data = error.response.data;
      Toast.error(data.message);
    } finally {
      commit('setUserTreatmentListLoading', false);
    }
  }
}

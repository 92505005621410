






















import { Vue, Component, Watch } from 'vue-property-decorator';
import ApiHelper from 'api-helper';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faCameraRetro } from '@fortawesome/free-solid-svg-icons';
import cloneDeep from 'lodash/cloneDeep';

// Utils
import Toast from '@common/helpers/toast';
import { getFormData } from '@/common/utils';
// Components
import ContentLayout from '@components/layouts/Content.vue';
import DragDropFileUpload from '@/components/common/DragDropFileUpload.vue';
import MedicineForm from './components/MedicineForm.vue';
// Icons
import Listicon from '@/assets/img/icon-medicine.svg';
import error from '@/routers/error';

@Component({
  components: {
    ContentLayout,
    DragDropFileUpload,
    MedicineForm,
  },
})
export default class Detail extends Vue {
  iconHeader: any = Listicon;
  isSubmiting = false;
  medicine: any = {};
  isLoading: boolean = false;
  isChanged: boolean = false;

  dataForm: any = {
    id: '',
    name: '',
    usage: '',
    about_medicine: '',
    detail: '',
    precautions_for_use: '',
    side_effects: '',
    prohibition_of_concomitant_use: '',
    image: '',
    image_url: '',
    logo_image: '',
    logo_image_url: '',
    display_order: '',
    wakeup_dosage: '',
    morning_dosage: '',
    afternoon_dosage: '',
    evening_dosage : '',
    sleep_dosage: '',
    stock_id: '',
  };

  get computedForm() {
    return this.$refs.dataForm as any;
  }

  get medicineId() {
    return this.$route.params.id;
  }

  async created() {
    this.getMedicineDetail();
    library.add(faCameraRetro);
    dom.watch();
  }

  async getMedicineDetail() {
    try {
      this.isLoading = true;
      const res = await ApiHelper.getApi('MedicineApi').getOne(this.medicineId, { with: 'stock' });
      this.medicine = res;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      if (error.response) {
        Toast.error(error.response.data.message);
      }
    }
  }

  updateIsSubmiting(isSubmiting: boolean) {
    this.isSubmiting = isSubmiting;
  }

  updateIsChanged(status: boolean) {
    this.isChanged = status;
  }

  uploadFile(name, val) {
    const file = val;
    const reader = new FileReader();
    this.$set(this.dataForm, name, val);
    reader.onload = (event: any) => {
      this.$set(this.dataForm, `${name}_url`, event.target.result);
    };
    reader.readAsDataURL(file);
  }

  deleteFile(name, value) {
    this.$set(this.dataForm, name, value);
    this.$set(this.dataForm, `${name}_url`, value);
  }

  handleChange(name, value) {
    this.$set(this.dataForm, name, value);
  }

  handleCancel() {
    return this.$router.push({
      name: 'medicine',
      query: {
        name: this.$route.query.name || '',
      },
    });
  }

  async handleSubmit() {

    const valid = await this.computedForm.validate();

    if (valid) {
      try {
        this.updateIsSubmiting(true);
        const data = cloneDeep({
          ...this.dataForm,
          _method: 'put',
        });
        const formData = getFormData(data, { checkEmpty: false });
        await ApiHelper.getApi('MedicineApi').update(this.medicineId, formData);
        this.updateIsSubmiting(false);
        this.updateIsChanged(false);
        Toast.success('更新しました');
      } catch (error) {
        this.updateIsSubmiting(false);
        this.updateIsChanged(false);
        const data = error.response.data;
        Toast.error(data.message);
        if (error.response.status === 422) {
          this.computedForm.setErrors(error.response.data.errors);
          return;
        }
      }
  }
}

  @Watch('dataForm', { deep: true })
  private watcbDataForm(_, oldValue) {
    if (this.isChanged || !oldValue.id) {
      return;
    }
    this.updateIsChanged(true);
  }

  @Watch('medicine')
  private watchMedicine() {
    this.dataForm = {
      id: this.medicine.id,
      name: this.medicine.name,
      usage: this.medicine.usage,
      about_medicine: this.medicine.about_medicine,
      detail: this.medicine.detail || '',
      precautions_for_use: this.medicine.precautions_for_use || '',
      side_effects: this.medicine.side_effects || '',
      prohibition_of_concomitant_use:
        this.medicine.prohibition_of_concomitant_use || '',
      image_url: this.medicine.image_url,
      logo_image_url: this.medicine.logo_image_url,
      display_order: this.medicine.display_order,
      wakeup_dosage: this.medicine.wakeup_dosage || '',
      morning_dosage: this.medicine.morning_dosage || '',
      afternoon_dosage: this.medicine.afternoon_dosage || '',
      evening_dosage : this.medicine.evening_dosage || '',
      sleep_dosage: this.medicine.sleep_dosage || '',
      stock_id : this.medicine.stock?.id || '',
    };
  }
}

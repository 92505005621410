import Toast from '@common/helpers/toast';
import ApiHelper from '@/api/ApiHelper';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import lodashGet from 'lodash/get';

// interfaces
import { Patient } from '@/models/patient';

const DEFAULT_PAGE = 1;
const DEFAULT_PER_PAGE = 20;
const DEFAULT_LAST_PAGE = 1;

@Module({ name: 'channel', namespaced: true })
export default class Index extends VuexModule<any> {
  private data: any[] = [];

  private isLoading: boolean = false;
  private perPage: number = DEFAULT_PER_PAGE;
  private page: number = DEFAULT_PAGE;
  private lastPage: number = DEFAULT_LAST_PAGE;

  // ********* Mutations ********* //
  @Mutation
  public setPatientList(patientList) {
    this.data = patientList;
  }

  @Mutation
  public setUnreadCountByChannelId(data: any) {
    this.data.every((patient) => {
      if (
          lodashGet(patient, 'channels.id') === data.channelId &&
          lodashGet(patient, 'channels.message_recipients[0]')
          ) {
            patient.channels.message_recipients[0].unread_count = data.unreadMessageCount;
            return false;
        }
      return true;
    });
  }

  @Mutation
  public setLatestMessageByChannelId(data: any) {
    this.data.every((patient) => {
      if (
          lodashGet(patient, 'channels.id') === data.channel_id &&
          lodashGet(patient, 'channels.latest_message')
          ) {
            patient.channels.latest_message = data;
            return false;
      }
      return true;
    });
  }

  @Mutation
  public setPatientState(data) {
    this.data.every((patient) => {
      if (patient.id === data.id) {
        patient.state = data.state;
        return false;
      }
      return true;
    });
  }

  @Mutation
  public setNamePatient(newPatient: Patient) {
    this.data.every((patient) => {
      if (patient.id === newPatient.id) {
        patient.family_name = newPatient.family_name;
        patient.given_name = newPatient.given_name;
        return false;
      }

      return true;
    });
  }

  @Mutation
  public setDataPatientListPaginate(response) {
    this.data.push(...response.data);
    this.perPage = response.per_page;
    this.page = response.current_page;
    this.lastPage = response.last_page;
  }

  @Mutation
  public resetPatientListState() {
    this.data = [];
    this.isLoading = false;
    this.perPage = DEFAULT_PER_PAGE;
    this.page = DEFAULT_PAGE;
    this.lastPage = DEFAULT_LAST_PAGE;
  }

  @Mutation
  public setPatientListLoading(isLoading = true) {
    this.isLoading = isLoading;
  }

  // ********* Actions ********* //
  @Action({ rawError: true })
  public async getPatientList(params) {
    const { commit, state } = this.context;
    try {
      commit('setPatientListLoading');
      const {payload, $state} = params;
      payload.per_page = state.perPage;
      payload.page = state.page;

      const response = await ApiHelper.getApi('PatientV2Api').list(payload);

      response.current_page += 1;
      commit('setDataPatientListPaginate', response);

      // when page > last page -> stop load more.
      state.page <= response.last_page ? $state.loaded() : $state.complete();
    } catch (error) {
      const data = error.response.data;
      Toast.error(data.message);
    } finally {
      commit('setPatientListLoading', false);
    }
  }
}

import ListCourse from '@/views/courses/ListCourse.vue';
import DetailCourse from '@/views/courses/DetailCourse.vue';
import { authAdminDoctor } from '@/common/constants/auth';

export default [
    {
        path: '/courses',
        name: 'courses',
        component: ListCourse,
        meta: authAdminDoctor,
    },
    {
        path: '/courses/detail/:id',
        name: 'courses.detail',
        component: DetailCourse,
        meta: authAdminDoctor,
    },
    {
        path: '/courses/create',
        name: 'courses.create',
        component: DetailCourse,
        meta: authAdminDoctor,
    },
];






























































































































import { Vue, Component } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faPlus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';

// Utils
import Toast from '@common/helpers/toast';
import LocalStorageUtils from '@common/LocalStorageUtils';
import ApiHelper from 'api-helper';
// Components
import Pagination from '@components/common/Pagination.vue';
import PageLoader from '@components/common/PageLoader.vue';
import ContentLayout from '@components/layouts/Content.vue';
import BasicSelect from '@/components/common/BasicSelect.vue';
// Constants
import { MENU_STATUS } from '@/common/app.config';
// Icons
import Listicon from '@/assets/img/icon-diagnosis.svg';
import { SelectType } from '@/common/interfaces/common';
import lodash from 'lodash';

@Component({
  components: {
    Pagination,
    PageLoader,
    ContentLayout,
    BasicSelect,
  },
  data() {
    return {
      listDiagnosis: {},
      iconHeader: Listicon,
    };
  },
})
export default class List extends Vue {
  public iconHeader: any;
  public page = this.$route.query.page || 1;
  public perPage = 10;
  public pageCount = 1;
  public listDiagnosis!: any;
  public isLoading: boolean = false;

  get isSuperAdminRole() {
    return this.currentUser.isSuperAdminRole;
  }

  get menuStatus(): SelectType[] {
    return MENU_STATUS;
  }
  @State((state) => state.auth.currentUser) currentUser!: any;

  public searchForm = {
    name: this.$route.query.name || '',
    clinic_name: this.$route.query.clinic_name,
    order: '-created_at',
    per_page: this.perPage,
    page: this.page,
    with: 'clinics',
    menu_status: this.$route.query.menu_status === '0' ? 0 : parseInt(this.$route.query.menu_status as string) || null,
  };
  private selectMenuStatusId!: number;

  public searchDiagnosis() {
    this.searchForm.page = 1;
    LocalStorageUtils.saveItem('paramsSearch', JSON.stringify(this.searchForm));

    this.page = 1;
    this.getDiagnosisList(this.searchForm);
  }

  public pageChanged(page) {
    let paramsSearch: any;

    if (LocalStorageUtils.getItem('paramsSearch')) {
      paramsSearch = JSON.parse(LocalStorageUtils.getItem('paramsSearch'));
    } else {
      paramsSearch = Object.assign({}, this.searchForm);
      paramsSearch.name = '';
    }

    paramsSearch.page = page;
    LocalStorageUtils.saveItem('paramsSearch', JSON.stringify(paramsSearch));
    this.page = page;
    this.getDiagnosisList(paramsSearch);
  }

  debouncedSearch(status) {
    if (this.selectMenuStatusId) {
      clearTimeout(this.selectMenuStatusId);
    }
    this.$nextTick(() => {
      this.selectMenuStatusId = setTimeout(this.onSearch, 2000);
    });
  }

  onSearch($state) {
    this.getDiagnosisList(this.searchForm);
  }

  private async created() {
    if (LocalStorageUtils.getItem('paramsSearch')) {
      this.searchForm = {
        ...JSON.parse(LocalStorageUtils.getItem('paramsSearch')),
      };

      this.page = this.searchForm.page;
      LocalStorageUtils.removeItem('paramsSearch');
    }

    this.getDiagnosisList(this.searchForm);
  }

  private mounted() {
    library.add(faPlus);
    library.add(faPlusCircle);
    dom.watch();
  }

  private async getDiagnosisList(data) {
    try {
      this.isLoading = true;
      const res = await ApiHelper.getApi('MedicalMenuV2Api').getList(data);
      this.listDiagnosis = res.data;
      this.pageCount = res.last_page;
      this.isLoading = false;
    } catch (error) {
      this.toast(error);
      this.isLoading = false;
    }
  }

  private toast(error: any) {
    const {
      errors: { id: idError },
    } = error.response.data;
    if (idError) {
      return Toast.error('英数字で入力してください。');
    }
    Toast.error(error.response.data.message);
  }

  private makeClinicNames(clinicsName) {
    return clinicsName?.map((clinic) => clinic.name).join(', ');
  }

  private makeStatusMenu(status) {
    return MENU_STATUS.find((item) => item.value === status)?.name ?? '-';
  }
}

























































import { Vue, Prop, Component } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { Medicine } from '@/models/medicine';
import ApiHelper from 'api-helper';
import Toast from '@/common/helpers/toast';
import PageLoader from '@components/common/PageLoader.vue';
import imageDefault from '@/assets/img/icon-no-image.svg';

@Component({
  components: {
    PageLoader,
  },
})
export default class MedicineDetail extends Vue {
  @State((state) => state.auth.currentUser) currentUser!: any;
  @State((state) => state.medicine.medicine) medicine: Medicine;
  imagePreview: string | File = this.$store.state.medicine.medicine.image_url;
  isLoading: boolean = false;

  get src() {
    return this.imagePreview || imageDefault;
  }

  backToCreate() {
    this.$router.push({ name: 'medicine.create', params: { isConfirm: '1' } });
  }

  createMedicine() {
    if (this.isLoading) {
      return;
    }

    let formData = this.medicine.formJSONData(false);
    if (this.medicine.image) {
      formData = this.medicine.formJSONDataHaveImage(false);
    }

    this.isLoading = true;

    ApiHelper.getApi('MedicineApi')
      .create(formData)
      .then((response) => {
        Toast.success('更新しました');
        this.$router.push({ name: 'medicine' });
      })
      .catch((error) => {
        Toast.error('更新に失敗しました');
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  mounted() {
    if (!this.medicine.name) {
      this.$router.push({ name: 'medicine.create' });
    }
  }
}

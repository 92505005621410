























































// libs
import { Vue, Component, Prop } from 'vue-property-decorator';
import { State } from 'vuex-class';
import lodashGet from 'lodash/get';

import { UserTreatment } from '@/models/user-treatment';

// constants
import {
  makeUserTreatmentState,
  USER_TREATMENT_NAME,
  USER_TREATMENT_STATE,
} from '@common/constants/auth';
import { formatDateTimeJp } from '@common/app.config';
import { User } from '@/models/user';

@Component({
  components: {},
})
export default class UserTreatmentItem extends Vue {
  @State((state) => state.treatment_detail.data)
  activeUserTreatment: UserTreatment;
  @Prop({ required: true }) treatment: UserTreatment;
  @State((state) => state.auth.currentUser) currentUser!: User;

  private formatDateTimeJp = formatDateTimeJp;
  mounted() {
    if (this.$route.query.user_treatment_id && this.treatment.id == parseInt(this.$route.query.user_treatment_id as string)) {
      this.$store.commit('treatment_detail/setTreatment', this.treatment);
    } else if (!this.$route.query.user_treatment_id) {
      this.$store.commit('treatment_detail/setTreatment', this.treatment);
    }
  }

  // ********************* methods ********************* //
  private handelClickTreatment() {
    this.$store.commit('treatment_detail/setLoading');
    this.$store.commit('treatment_detail/setTreatment', this.treatment);
    setTimeout(() => {
      this.$store.commit('treatment_detail/setLoading', false);
    }, 100);
  }

  private getNameByKey = (key) => USER_TREATMENT_NAME[key.toLocaleUpperCase()];
  private makeUserTreatmentState(state) {
    return makeUserTreatmentState(state, this.currentUser);
  }

  private getMenuNameByUserTreatment = (treatment) =>
    treatment.menu?.name || '-'

  private getReservationDateUserTreatment = (treatment) =>
    lodashGet(treatment, 'reservations[0].latest_answer.created_at')
  private getFirstPaidAt = (treatment) => treatment.orders[0]?.paid_at;
  private getPlanName = (treatment) => treatment.orders[0]?.plan?.name;
  private checkReverse = (treatment) => {
    if (treatment.feedback_type === USER_TREATMENT_STATE.DOCTOR_RESERVE_PRESCRIPTION) {
      return true;
    } else {
      return false;
    }
  }
}

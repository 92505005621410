






















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class BaseInput extends Vue {
  @Prop([String, Number]) readonly value!: string | number;
  @Prop(String) readonly errorText!: string;
  @Prop({default: 'text'}) readonly type!: string;
  @Prop({default: '入力してください'}) readonly placeholder!: string;
  @Prop(Boolean) readonly readOnly!: boolean;
  @Prop(Boolean) readonly disabled!: boolean;
  @Prop(Boolean) readonly autoFocus!: boolean;
  @Prop(Boolean) readonly isInvalid!: boolean;
  @Prop([String, Number]) readonly maxlength!: string | number;

  mounted() {
    if (this.autoFocus) {
      (this.$refs.input as any).focus();
    }
  }

}

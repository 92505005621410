














































































import ApiHelper from 'api-helper';
import { Vue, Component } from 'vue-property-decorator';
import { Course } from '@/models/course';
import Toast from '@common/helpers/toast';
import { State } from 'vuex-class';
import PageLoader from '@components/common/PageLoader.vue';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faTrashAlt, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import Pagination from '@components/common/Pagination.vue';

@Component({
  components: {
    PageLoader,
    Pagination,
  },
  filters: {
    paymentType: (paymentType) => {
      return paymentType === 1 ? '定期' : '単品';
    },
    status: (isShow) => {
      return isShow ? '表示' : '表示しない';
    },
  },
})
export default class HomePlan extends Vue {
  get queryParams() {
    return {
      ...this.paginate,
    };
  }

  get isEmptyDataPlan(): boolean {
    return this.dataCourses && !this.dataCourses.length;
  }
  @State((state) => state.auth.currentUser) currentUser!: any;
  idPlan: number = 0;
  dataCourses: Course[] = [];
  isLoading = false;
  totalPage = 1;

  paginate = {
    page: 1,
    per_page: 10,
  };

  getPrice(price) {
    return '¥' + price.toLocaleString();
  }

  getCourseList() {
    if (this.isLoading) {
      return;
    }

    this.dataCourses = [];

    this.isLoading = true;

    ApiHelper.getApi('CourseApi')
      .fetchCourseList(this.queryParams)
      .then((response: any) => {
        this.totalPage = response.last_page;
        this.dataCourses = response.data;
      })
      .catch((error: any) => {
        Toast.error(error.response.data.message);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  mounted() {
    this.getCourseList();
  }

  created() {
    library.add(faTrashAlt);
    library.add(faPlusCircle);
    dom.watch();
  }

  private pageChanged(page) {
    this.paginate = {
      ...this.paginate,
      page,
    };
    this.getCourseList();
  }
}

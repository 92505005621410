
























































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import moment from 'moment';
import { DeliveryStateDisplay } from '@common/enums/order';
import ApiHelper from 'api-helper';
import Toast from '@common/helpers/toast';
import CustomErrorMessage from '@common/mixins/CustomErrorMessage';
import lodash from 'lodash';

@Component({
  components: {},
  mixins: [CustomErrorMessage],
  data() {
    return {
      moment,
      DeliveryStateDisplay,
    };
  },
  filters: {
    formatNum: (value) => {
      let formatedString = '0';
      if (value) {
        formatedString = value
          .toString()
          .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
      }

      return `${formatedString}円`;
    },
  },
})
export default class ModalRefund extends Vue {
  @Prop(Object) readonly deliveryItem: any;
  tableHead: string[] = [
    '配送予定日',
    '配送ステータス',
    '決済額',
    '返金済金額',
    '返金額',
  ];

  private formRefundAmount = {
    refund_amount: 0,
  };

  @Watch('deliveryItem')
  private updateRefundAmount(value) {
    this.deliveryItem.refund_amount = lodash.sumBy(
      value.orders_tracking_history_refunded,
      (item: any) => {
        return parseInt(item.amount);
      },
    );
    this.formRefundAmount.refund_amount =
      parseInt(value.amount) - this.deliveryItem.refund_amount;
    this.deliveryItem.accept_refund_amount =
      parseInt(value.amount) - this.deliveryItem.refund_amount;
  }

  private async editAmount() {
    const computedForm = this.$refs.formRefundAmount as any;
    const isValid = await computedForm.validate();
    if (!isValid) {
      return;
    }

    ApiHelper.getApi('RefundApi')
      .refundAmount(this.deliveryItem.id, this.formRefundAmount)
      .then((res) => {
        const deliveryAmount = parseFloat(this.deliveryItem.amount);
        const deliveryRefundAmount = parseFloat(this.deliveryItem.refund_amount);
        const refundAmount = this.formRefundAmount.refund_amount;
        if (deliveryAmount === deliveryRefundAmount + refundAmount) {
          Toast.success('全額返金済みです。');
        } else {
          Toast.success('返金が行われました。');
        }
        this.hiddenRefundAmount();
        this.$emit('fetchOrder');
      })
      .catch((error) => {
        Toast.error(error.response.data.message);
      });
  }

  private hiddenRefundAmount() {
    this.$modal.hide('modalRefundAmount');
  }
}
